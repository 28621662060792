import {ReferenceService} from './../../../eo-framework-core/references/reference.service';
import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, UntypedFormControl, NG_VALIDATORS} from '@angular/forms';
import {
  Clipboard,
  ClipboardService,
  DmsObject,
  ObjectType,
  SearchResult, SearchService,
  SystemService, Utils
} from '@eo-sdk/core';
import {debounceTime, map} from 'rxjs/operators';
import {AutoComplete} from '@yuuvis/components/autocomplete';

export interface DataToRenderItem {
  id: string;
  title?: string;
  typeName: string;
  description?: string;
  state?: string;
}

@Component({
  selector: 'eo-id-reference',
  templateUrl: './id-reference.component.html',
  styleUrls: ['./id-reference.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IdReferenceComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IdReferenceComponent),
      multi: true
    }
  ]
})
export class IdReferenceComponent implements ControlValueAccessor, OnInit, Validator {
  private value; // model value
  _innerValues: DataToRenderItem[] = []; // inner ng-model value
  clipboard: Clipboard;
  referenceType: ObjectType;
  subTypes: ObjectType[];
  tooltipTypeHint: string;
  visibleDialog = false;

  autocompleteRes;

  @Input() readonly: boolean;
  @Input() multiselect: boolean;
  @Input() situation: string;
  @Input() contextId: string;
  @Input() exceptionIDs: string[];
  @ViewChild('autocomplete') autoCompleteInput: AutoComplete;

  set innerValues(data: DataToRenderItem[]) {
    if (data) {
      this._innerValues = data;
    } else {
      this._innerValues = [];
    }
  }

  get innerValues() {
    return this._innerValues;
  }

  @Input()
  set dataToRender(data: DataToRenderItem[]) {
    if (data) {
      this.innerValues = data;
    } else {
      this.innerValues = [];
    }
  }

  @Input()
  set reference(ref: any) {
    this.referenceType = ref ? this.system.getObjectType(ref.type) : null;
    this.subTypes = this.getSubTypes(this.referenceType?.name, this.system.getObjectTypes());
    this.tooltipTypeHint = this.createTooltipTypeHint(this.referenceType, this.subTypes);
  }

  @Input() queryFilters: {
    [fieldQname: string]: {o: string, v1: any, v2: any}
  };

  @Input() objectTypesFilter: string[];

  private getSubTypes(typeName, types) {
    let subTypes = [];
    types.forEach(objectType => {
      if (!!objectType.supertypes.find(sT => sT === typeName)) {
        subTypes.push(objectType);
      }
    });
    return subTypes;
  }

  private createTooltipTypeHint(referenceType, subTypes = []) {
    return referenceType ? `(${[referenceType.label, ...subTypes.map(types => types.label)].join(', ')})` : '';
  }

  constructor(private clipboardService: ClipboardService, private system: SystemService,
    private referenceService: ReferenceService,
    private searchService: SearchService) {
    this.clipboardService.clipboard$.subscribe(clipboard => (this.clipboard = clipboard));
    this.clipboard = this.clipboardService.get();
  }

  showDialog() {
    this.visibleDialog = !this.visibleDialog;
  }

  get selectionDisabled() {
    return this.multiselect ? false : this.innerValues.length;
  }

  get isDisabled() {
    return (
      !this.referenceType ||
      this.readonly ||
      this.clipboard.action === 1 ||
      !this.clipboard.elements.length ||
      this.containsIncorrectTypes(
        this.clipboard.elements,
        this.referenceType,
        this.subTypes
      ) ||
      this.containsIncorrectContext(this.clipboard.elements, this.contextId)
    );
  }

  private containsIncorrectTypes(clipboardElements, refType, subTypes) {
    return !!clipboardElements.find(cbEl => !(cbEl.typeName === refType.name || subTypes.find(sT => sT.name === cbEl.typeName)));
  }

  private containsIncorrectContext(clipboardElements, contextId) {
    return !!clipboardElements.find(cbEl => {
      if (!contextId) {
        return false;
      }
      return !cbEl.contextFolder || cbEl.contextFolder.id !== contextId;
    });
  }

  paste(content?: DmsObject[]) {
    this.linkDmsObject(content);
    this.showDialog();
    this.propagateChange(this.value);
  }

  private linkDmsObject(content?: DmsObject[]) {
    (content || this.clipboard.elements).forEach((dmsObject: DmsObject) => {
      const {id, title, description, typeName} = dmsObject;
      this.innerValues.push({id, title, description, typeName});
    });
    this.innerValues = Utils.uniqBy(this.innerValues, 'id');
    if (this.multiselect) {
      this.value = this.innerValues.map(iv => iv.id);
    } else {
      this.value = this.innerValues[0].id;
    }
  }

  removeItem(item) {
    this.innerValues = this.innerValues.filter(iv => iv.id !== item.id);
    this.value = this.multiselect
      ? Utils.uniqBy(this.innerValues, 'id').map(iv => iv.id)
      : null;
    this.propagateChange(this.value);
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value;
      this.updateInnerValues(this.value);
    } else {
      this.value = null;
      this.innerValues = [];
    }
  }

  private updateInnerValues(value) {
    const values = Array.isArray(value) ? value : [value];
    this.innerValues = this.innerValues.filter(iV => {
      // remove innerValues, where the id was removed from value
      return values.find(v => v === iV.id);
    });
    const newValues = values.filter(v => {
      return !this.innerValues.find(iV => iV.id === v);
    });
    if (newValues.length) {
      this.referenceService.fetchIDReferenceMetaData(newValues).subscribe(newInnerValues => {
        this.innerValues = this.innerValues.concat(newInnerValues);
      });
    }
  }

  propagateChange = (value: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void { }

  public validate(c: UntypedFormControl) {
    return null;
  }

  // handler invoked when an entry was selected using the autocomplete input
  onAutoCompleteSelect(evt?) {
    if (!Array.isArray(this.innerValues)) {
      this.innerValues = [this.innerValues];
    }
    this.innerValues = Utils.uniqBy(this.innerValues, 'id');
    if (this.multiselect) {
      this.value = this.innerValues.map(iv => iv.id);
    } else {
      this.value = this.innerValues[0].id;
    }
    this.propagateChange(this.value);
  }

  autocompleteFn(term: string) {
    let q: any = {
      fields: ['title', 'description', 'type'],
      types: [this.referenceType.name],
      term: term,
      filters: this.queryFilters,
      options: {withcontext: true, highlighting: false}
    };

    if (this.objectTypesFilter) {
      q.types = this.objectTypesFilter;
    }

    if (this.contextId) {
      if (q.filters) {
        q.filters.contextfolderid = {v1: this.contextId, o: 'eq'};
      } else {
        q.filters = {contextfolderid: {v1: this.contextId, o: 'eq'}};
      }
    }

    this.searchService
      .executeQuery(q)
      .pipe(
        debounceTime(500),
        map(item => item ? this.searchService.createResultFromResponse(item) : [])
      ).subscribe((result: SearchResult) => {
        this.autocompleteRes = result.hits;
      });
  }

  onAutoCompleteBlur() {
    this.autoCompleteInput.clearInput();
  }

  ngOnInit() {
    if (this.situation === 'SEARCH') {
      this.multiselect = true;
    }
  }
}
