<yvc-master-details [layoutOptions]="{masterSize: 60, detailsSize: 40}" [layoutSettingsID]="'result.state--' + typeName"
  [detailsActive]="true">
  <!-- master -->
  <ng-template #yvcMasterPane>
    <eo-result-list [query]="query" [hasIcon]="true" [clearAfterExecute]="clearAfterExecute"
      (onResult)="onResult($event)" (onQueryInvalid)="onQueryInvalid()"></eo-result-list>
  </ng-template>

  <!-- details -->
  <ng-template #yvcDetailsPane>
    <eo-object-details [searchTerm]="searchTerm" [applySelection]="{}"
      [cacheLayout]="'result.state--' + typeName + '.object-details'">
      <eo-tab-plugin [type]="'object-details-tab.result'"></eo-tab-plugin>
    </eo-object-details>
  </ng-template>
</yvc-master-details>