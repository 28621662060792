import {NgModule, ANALYZE_FOR_ENTRY_COMPONENTS, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PluginService, ENTRY_COMPONENTS, ENTRY_LINKS} from './plugin.service';
import {PluginDirective} from './plugin.directive';
import {PluginComponent, TabPluginComponent, SidebarPluginComponent} from './plugin.component';
import {RouterModule} from '@angular/router';
import {EoSharedModule} from '@eo-sdk/core';

@NgModule({
  imports: [
    CommonModule,
    EoSharedModule,
    RouterModule
  ],
  declarations: [PluginDirective, PluginComponent, TabPluginComponent, SidebarPluginComponent],
  providers: [PluginService, {
    provide: ENTRY_COMPONENTS,
    useValue: []
  }, {
    provide: ENTRY_LINKS,
    useValue: []
  }],
  exports: [PluginComponent, TabPluginComponent, SidebarPluginComponent]
})
export class PluginsModule {
  static forRoot(components: any[] = [], links: any[] = []): ModuleWithProviders<PluginsModule> {
    return {
      ngModule: PluginsModule,
      providers: [{
        provide: ANALYZE_FOR_ENTRY_COMPONENTS,
        useValue: components,
        multi: true,
      }, {
        provide: ENTRY_COMPONENTS,
        useValue: components
      }, {
        provide: ENTRY_LINKS,
        useValue: links
      }]
    };
  }
}
