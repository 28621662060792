import {Component, forwardRef, Input} from '@angular/core';
import {
  UntypedFormControl,
  ControlValueAccessor,
  Validator,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';
import {BackendService, EnvironmentService, Utils, FormatedMailTo} from '@eo-sdk/core';


@Component({
  selector: 'eo-string',
  templateUrl: './string.component.html',
  styleUrls: ['./string.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StringComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => StringComponent),
      multi: true
    }
  ]
})
export class StringComponent implements ControlValueAccessor, Validator {

  private _autocomplete: boolean;
  @Input()
  set autocomplete(autoCom: boolean) {
    this._autocomplete = autoCom;
  }

  get autocomplete() {
    return this._autocomplete;
  }

  @Input() multiselect: boolean;
  @Input() multiline: boolean;
  @Input() readonly: boolean;
  @Input() autofocus: boolean;

  @Input() classification: string;
  @Input() situation: string;
  @Input() regex: string;
  @Input() qname: string;
  // could be small, medium, large
  @Input() size: string;
  @Input() minLength: number;
  @Input() maxLength: number;

  autocompleteRes: any[];
  formatedValue: FormatedMailTo;
  // model value
  value;
  valid: boolean;

  constructor(private backend: BackendService, private envService: EnvironmentService) {
  }

  propagateChange = (_: any) => {
  };

  onKeyUpEnter(event) {
    // applies to multiselect and autocomplete
    const input = event.target.value.trim();
    if (input) {
      this.value = this.value ? this.value : [];
      this.value = [...this.value, input];
      this.propagateChange(this.value);
      event.target.value = '';
    }
  }

  writeValue(value: any): void {
    this.formatedValue = Utils.formatMailTo(value, this.classification === 'email');
    this.value = value || null;
    this._validate();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onValueChange(evt) {
    this.formatedValue = Utils.formatMailTo(evt, this.classification === 'email');
    this.value = evt.length ? evt : null;
    this.propagateChange(this.value);
  }

  onBlur() {
    if (this.trimValue()) {
      this.propagateChange(this.value);
    }
  }

  /**
   * Trims the current value and returns wether or not it has been trimmed
   */
  private trimValue(): boolean {
    if (this.value) {
      if (this.multiselect) {
        const lengthBefore = this.value.join('').length;
        this.value = this.value.map(v => v.trim());
        return this.value.join('').length !== lengthBefore;
      } else {
        const lengthBefore = this.value.length;
        this.value = this.value.trim();
        return this.value.length !== lengthBefore;
      }
    }
    return false;
  }

  autocompleteFn(term: string) {
    const uri = `/autocomplete/element?prefix=${encodeURIComponent(term)}&qname=${this.qname}`;
    const base = this.backend.getSearchBase();
    this.backend
      .getJson(uri, base)
      .subscribe(res => {
        let r = [];
        res.forEach(i => r.push(i.value));
        this.autocompleteRes = r;
      });
  }

  private validateClassification(string): boolean {

    if (this.situation === 'SEARCH') {
      return true;
    } else {
      let pattern;
      if (this.classification === 'email') {
        pattern = /^(?:(.+?)\s*<(\w+)([\-+.][\w]+)*@(\w[\-\w]*\.){1,5}([A-Za-z]{2,6})>|["']{1}(.+?)["']{1}\s*<(\w+)([\-+.][\w]+)*@(\w[\-\w]*\.){1,5}([A-Za-z]{2,6})>|(\w+)([\-+.][\w]+)*@(\w[\-\w]*\.){1,5}([A-Za-z]{2,6}))$/;
      } else if (this.classification === 'url') {
        pattern = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
      }
      return pattern ? pattern.test(string) : true;
    }
  }

  private _validate() {
    let err;
    // validate regular expression
    if (this.value && this.regex) {
      if (this.multiselect) {
        if (this.value.length > 0 && !!this.value.find(v => !RegExp(this.regex).test(v))) {
          err = {};
          err['regex'] = {
            valid: false
          }
        }
      } else {
        if (!RegExp(this.regex).test(this.value)) {
          err = {};
          err['regex'] = {
            valid: false
          }
        }
      }
    }
    // validate classification settings
    if (this.value && this.classification) {
      if (this.multiselect) {
        for (let v of this.value) {
          if (!this.validateClassification(v)) {
            err = {};
            err['classification' + this.classification] = {
              valid: false
            };
          }
        }
      } else {
        if (!this.validateClassification(this.value)) {
          err = {};
          err['classification' + this.classification] = {
            valid: false
          };
        }
      }
    }
    // validate length here when multiselect
    if (this.value && this.value !== null) {
      if (this.multiselect) {
        if (this.value.length > 0 && !!this.value.find(v => v.length < this.minLength)) {
          err = {};
          err['minlength'] = {
            valid: false
          }
        }
        if (this.value.length > 0 && !!this.value.find(v => v.length > this.maxLength)) {
          err = {};
          err['maxlength'] = {
            valid: false
          }
        }
      } else {
        if (this.value.length > 0 && this.value.length < this.minLength) {
          err = {};
          err['minlength'] = {
            valid: false
          }
        }
        if (this.value.length > this.maxLength) {
          err = {};
          err['maxlength'] = {
            valid: false
          }
        }
      }
    }
    // validate invalid if only whitespaces
    if (this.value && this.value !== null) {
      if (this.multiselect) {
        for (let v of this.value) {
          if (v.length && !v.trim().length) {
            err = {};
            err['onlyWhitespaces'] = {
              valid: false
            }
          }
        }
      } else {
        if (this.value.length && !this.value.trim().length) {
          err = {};
          err['onlyWhitespaces'] = {
            valid: false
          }
        }
      }
    }
    this.valid = !err;
    return err || null;
  }

  // returns null when valid else the validation object
  public validate(c: UntypedFormControl) {
    return this._validate();
  }
}
