import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {of} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
// import {TabPanelComponent} from '../tab-container/tab-panel/tab-panel.component';
import {ActivatedRoute, Router} from '@angular/router';
import {
  BackendService, BaseParams, Capabilities, CapabilitiesService, Config, DmsObject, DmsParams,
  DmsService, EnaioEvent, EventService, NotificationsService, SystemService, TranslateService, UserService, Utils
} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EmptyState} from '../../eo-framework-core/empty-state/empty-state.interface';
import {EmptyStateService} from '../../eo-framework-core/empty-state/empty-state.service';
import {LocaleDatePipe} from '../../eo-framework-core/pipes/locale-date.pipe';
import {
  SelectionConfig,
  SelectionService
} from '../../eo-framework-core/selection/selection.service';
import {MediaComponent} from '../media/media.component';
import {IndexData} from '../ui/indexdata-summary/indexdata-summary.component';
@UntilDestroy()
@Component({
  selector: 'eo-recyclebin-details',
  templateUrl: './recyclebin-details.component.html',
  styleUrls: ['./recyclebin-details.component.scss']
})
export class RecyclebinDetailsComponent implements OnInit, AfterViewInit {

  @ViewChild('viewer') preview: MediaComponent;
  @ViewChild('confirmDelete') confirmDeleteButton: ElementRef;

  // generate unique id to pass to upload registry
  user$ = this.userService.user$;
  _showDeleteDialog = false;
  userId = '';
  item: DmsObject;
  indexDataPreview: IndexData;
  baseparams: BaseParams;
  hasError = false;
  lockTooltip: string;
  nodmsobject = false;
  showLoader = false;
  panelOrder = {'master': ['summary', 'history'], 'slave': ['preview']};

  @Input() cacheLayout: any;
  item2: DmsObject;
  indexDataPreview2: IndexData;
  baseparams2: BaseParams;
  private _emptyState: EmptyState = {icon: 'ic_no-file.svg', text: '', className: ''};

  capabilities: Capabilities;
  @Input() applySelection: SelectionConfig;
  @Input() parseDmsParams: (data) => {} = this.getDmsParams;
  @Output() hasContent: EventEmitter<boolean> = new EventEmitter<boolean>();

  set showDeleteDialog(val: boolean) {
    this._showDeleteDialog = val;

    if (this._showDeleteDialog) {
      setTimeout(() => this.confirmDeleteButton.nativeElement.focus(), 0);
    }
  }

  get showDeleteDialog() {
    return this._showDeleteDialog;
  }

  showDialog() {
    if (this.item.rights.delete) {
      this.showDeleteDialog = true;
    }
  }

  @Input()
  set emptyState(e: EmptyState) {
    this._emptyState = e || EmptyStateService.defaultEmptyState();
  }

  get emptyState() {
    return this._emptyState;
  }

  @Input('params')
  set dmsParams(params: DmsParams) {
    this.showLoader = true;
    this.loadDmsObject(params).subscribe((res: DmsObject) => {
      this.dmsObject = res;
      this.showLoader = false;
    },
      Utils.throw(() => this.onHasError(true, true, false))
    );
  }

  @Input('item')
  set dmsObject(v: DmsObject) {
    this.item = v;
    this.onHasError();
    // prepare indexdata preview
    this.onIndexDataSaved(null, this.item);
    this.eventService.trigger(EnaioEvent.DMS_OBJECT_LOADED, this.item);

    if (this.item) {

      if (this.item.lock) {
        this.lockTooltip = this.translate.instant('eo.object.lock') + ' '
          + (new LocaleDatePipe(this.translate).transform(this.item.lock.on))
          + ', ' + this.item.lock.by.label + ' (' + this.item.lock.by.name + ')';
      }
    } else {
      this.emptyState = this.empty.getEmptyState(0);
    }
  }

  @HostBinding('attr.data-type') get dataType() {
    return this.item ? this.item.typeName : 'none';
  }

  constructor(private router: Router,
    private system: SystemService,
    private empty: EmptyStateService,
    private backend: BackendService,
    private userService: UserService,
    private toast: NotificationsService,
    private dmsService: DmsService,
    private selection: SelectionService,
    private capabilityService: CapabilitiesService,
    private eventService: EventService,
    private configService: Config,
    private translate: TranslateService) {

    this.panelOrder = this.configService.getRaw('recyclebinDetailsTabs') || this.panelOrder;
    this.user$.subscribe(user => {this.userId = user.id; });
  }

  private getDmsParams(data: any): DmsParams {
    return data ? {...data, type: data.type} : null;
  }

  private updateContent() {
    if (this.applySelection) {
      this.selection
        .find(this.applySelection.in)
        .focus$
        .pipe(
          untilDestroyed(this)
        )
        .subscribe(result => {
          this.showLoader = true;
          this.loadDmsObject(this.parseDmsParams(result) as DmsParams).subscribe((res: DmsObject) => {
            if (this.item && res && (this.item.id === res.id) && res.version > this.item.version) {
              this.eventService.trigger(EnaioEvent.DMS_OBJECT_UPDATED, res);
            }
            this.dmsObject = res;
            this.showLoader = false;
          },
            Utils.throw((error) => {
              return this.onHasError(true, true, false);
            })
          );
        });
    }
  }

  restoreFile(openLocationAfterRestore = false) {
    this.dmsService.restore(this.item.id, this.userId, this.item.typeName).subscribe(() => {
      this.updateContent();
      if (openLocationAfterRestore) {
        this.router.navigate(['/object', this.item.id]);
      }
      this.toast.success(
        this.translate.instant('eo.recyclebin.details.restore.done.title'),
        this.translate.instant('eo.recyclebin.details.restore.done.message', {item : this.item.title}),
      );
      this.eventService.trigger(EnaioEvent.DMS_OBJECT_RESTORED_OR_DELETED);
    }, (error) => {
      if (error.status === 409) {
        this.toast.error(this.translate.instant('eo.recyclebin.details.restore.dms.object.error.409'));
      }
    });
  }
  deleteDmsObject() {
    this.dmsService
      .deleteDmsObject(this.item.id, this.item.type.name, true)
      .subscribe(() => {
          this.toast.success(
            this.translate.instant('eo.recyclebin.details.delete.done.title'),
            this.translate.instant('eo.recyclebin.details.delete.done.message', {item : this.item.title})
          );
          this._showDeleteDialog = false;
          this.eventService.trigger(EnaioEvent.DMS_OBJECT_RESTORED_OR_DELETED);
        });
      }

  private prepareIndexDataPreview(item: DmsObject, formData?: any) {
    const {id, data, type, created, modified, content, contentFileName, contentFileSize, version} = item;

    return this.system
      .getObjectTypeForm(type.name, 'EDIT')
      .pipe(
        map(form => {
          let indexDataPreview = {
            form,
            data: formData || data
          };
          let baseparams: BaseParams = {
            id,
            type: type.name,
            createdOn: new Date(created.on),
            modifiedOn: new Date(modified.on),
            createdBy: `${created.by.title} (${created.by.name})`,
            modifiedBy: `${modified.by.title} (${modified.by.name})`,
            contentFileName: contentFileName ? contentFileName : null,
            contentFileSize: contentFileSize ? Number(contentFileSize) : null,
            version: version || 0,
            mimeGroup: content ? content.contents ? content.contents[0].mimegroup : null : null,
            digest: content ? content.contents ? content.contents[0].digest : null : null,
            contentId: content ? content.contents ? content.id : null : null,
            mimeType: content ? content.contents ? content.contents[0].mimetype : null : null,
          };
          return {indexDataPreview, baseparams};
        }));
  }

  private onHasError(hasError = false, nodmsobject = false, showLoader = false) {
    this.hasError = hasError;
    this.nodmsobject = nodmsobject;
    this.showLoader = showLoader;
    this.hasContent.emit(!hasError);
  }

  loadDmsObject(params: DmsParams) {
    if (params) {
      delete params.version;
    }
    // check for valid id
    return params?.id?.length === 32 ? this.dmsService.getDmsObjectByParams(params) : of(null);
  }

  downloadOriginalContent() {
    this.backend.downloadContent([this.item], null, false, true);
  }

  // executed when the dms object changed due to saving its indexdata
  onIndexDataSaved(formData, item) {
    if (!this.item) {
      this.indexDataPreview = null;
      this.baseparams = null;
    }
    if (!this.item2) {
      this.indexDataPreview2 = null;
      this.baseparams2 = null;
    }
    if (item) {
      this.prepareIndexDataPreview(item, formData)
        .subscribe(data => {
          if (item === this.item) {
            this.indexDataPreview = data.indexDataPreview;
            this.baseparams = data.baseparams;
          } else {
            this.indexDataPreview2 = data.indexDataPreview;
            this.baseparams2 = data.baseparams;
          }
        });
    }
  }

  isVisible(id: string) {
    return this.panelOrder.master.includes(id) || this.panelOrder.slave.includes(id);
  }

  ngOnInit() {
    this.capabilities = this.capabilityService.getCapabilities();
    this.updateContent();
    this.eventService
      .on(EnaioEvent.DMS_OBJECT_UPDATED)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(event => {
        if (event.data && this.item.id === event.data.id) {
          this.dmsObject = this.dmsService.createLocalCopyOfDMSObject(event.data);
          if (this.preview) {
            this.preview.refresh();
          }
        }
      });
  }

  ngAfterViewInit() {
    // let panels = this.tabPanels.toArray();
    // this.tabPlugins.forEach(p => panels = panels.concat(p.tabPanels.toArray()));
    // this.externalPanels.reset(panels);
  }
}
