import {finalize, switchMap} from 'rxjs/operators';
import {of as observableOf} from 'rxjs';
import {Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthService, EnvironmentService, Utils, Config, EnvironmentEnaio, TranslateService, UserService} from '@eo-sdk/core';
import {PageTitleService} from '../../eo-framework-core/title/page-title.service';

@Component({
  selector: 'eo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('setAsPresent') setAsPresentButton: ElementRef;
  backgroundImage: SafeResourceUrl;
  appLogo: string;
  form: any = {};
  profiles: {label: string, value: string}[] = [];
  loading = false;
  invalid = false;
  private returnUrl: string;
  native = false;
  cloudEnvironment: boolean;
  urlToNavigate: string;
  showAbsentDialog = false;
  removeProfile = (p) => p;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private config: Config,
              private titleService: PageTitleService,
              private translate: TranslateService,
              private envService: EnvironmentService,
              private sanitizer: DomSanitizer,
              private cd: ChangeDetectorRef,
              private authService: AuthService,
              private userService: UserService) {

    this.cloudEnvironment = this.envService.isCloud();
    this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(`url(${this.config.getDashboardBackgroundImage()})`);
    this.titleService.setDefaultTitle();
    // if we are no web app (e.g. cordova or electron) the login
    // form will be extended by a field for selecting a target host
    this.native = !EnvironmentEnaio.isWebEnvironment();
    this.profiles = authService.hosts.map(host => ({
      label: host ? host : 'local',
      value: host || ''
    }));
    this.appLogo = this.config.getRaw('appLogo');
  }

  ngOnInit() {
    if (this.route.snapshot.params['logout']) {
      this.authService.logout(true);
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // because returnUrl was fetched from location, we need to grab the actual route
    const currentLoc = location.href.substr(location.origin.length);

    const prefix = currentLoc.substring(0, currentLoc.indexOf('/enter'));
    this.returnUrl = this.returnUrl.replace(prefix, '').replace('index.html', '');

    // loading login state as the user is already authenticated will redirect to home
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/']);
    } else {
      // if there is no logged in user, select the browsers language for
      // the login dialog
      let browserLang = this.translate.getBrowserLang();
      this.translate.use(browserLang.match(/en|de/) ? browserLang : 'en');
    }
  }

  login() {
    this.loading = true;
    this.invalid = false;
    observableOf(null).pipe(
      switchMap(() => {
        return this.cloudEnvironment ?
          this.authService.cloudLogin(this.form.host, this.form.tenant) :
          this.authService.login(this.form.host, this.form.username, this.form.password);
      }),
      finalize(() => (this.loading = false))
    ).subscribe(() => {
        this.urlToNavigate = this.returnUrl || '/';
        if (!this.userService.getCurrentUser().present) {
          this.showAbsentDialog = true;
          setTimeout(() => this.setAsPresentButton.nativeElement.focus(), 0);
        } else {
          this.router.navigateByUrl(this.urlToNavigate).then(() => {
            this.cd.markForCheck();
          });
        }
      }, Utils.throw(() => {
        this.invalid = true;
      })
    );
  }

  closeShowAbsentDialog(setAsPresent = false) {
    this.showAbsentDialog = false;

    if (setAsPresent) {
      this.userService.setPresence(setAsPresent).subscribe();
    }
    this.router.navigateByUrl(this.urlToNavigate).then(() => {
      this.cd.markForCheck();
    });
  }
}
