import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, Validator} from '@angular/forms';
import {ICodeSystem, SystemService} from '@eo-sdk/core';
import {AutoComplete} from '@yuuvis/components/autocomplete';

import {TreeNode} from '../../tree/tree.component.interface';

@Component({
  selector: 'eo-codesystem',
  templateUrl: './codesystem.component.html',
  styleUrls: ['./codesystem.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodesystemComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CodesystemComponent),
      multi: true
    }
  ]
})
export class CodesystemComponent
  implements OnInit, ControlValueAccessor, Validator {
  @ViewChild('autocomplete') autoCompleteInput: AutoComplete;
  @ViewChild('button') autoCompleteButton;

  display = false;
  private dirty = false;
  private isValid = true;
  value;
  //codesystemElement;
  tree: TreeNode[];
  private _selectedNodes: any;
  set selectedNodes(n: any) {
    this._selectedNodes = structuredClone(n);
  }
  get selectedNodes() {
    return this._selectedNodes;
  }

  autocompleteRes;
  autocompleteValues: TreeNode[] = [];

  /** filter function that can be set by form script developers. When set this function will be private filterFunction: Function; */

  /** current form situation */
  @Input() situation: string;
  @Input() pickerTitle: string;
  @Input() placeholder: string;
  @Input() codesystem: ICodeSystem;
  @Input() multiselect: boolean;
  @Input() readonly: boolean;
  @Input() inputStyleClass = '';

  private _filterFunc: Function;
  private _disablingFilterFunc: Function;

  @Input('filterFunction')
  set filterFunction(func: Function) {
    this._filterFunc = func;
    this.buildTree();
  }

  @Input('disablingFilterFunction')
  set disablingFilterFunction(func: Function) {
    this._disablingFilterFunc = func;
    this.buildTree();
  }

  constructor(private systemService: SystemService) {
  }

  protected _getCodesystemByQname(qname: string): ICodeSystem {
    const cs: Partial<ICodeSystem> = {};
    const objectType = this.systemService.getObjectType(qname.split('.')[0]);
    if (objectType && objectType.elements) {
      const el = objectType.elements.find(e => e.qname === qname) || {};
      Object.assign(cs, el.codesystem);
    }
    cs.entries = this.systemService.getCodesystem(this.codesystem.id).entries;
    return (cs as ICodeSystem);
  }

  ngOnInit() {
    /** load codesystem entries if they aren't attached */
    if (this.codesystem && !this.codesystem.entries) {
      this.codesystem.entries = this.systemService.getCodesystem(this.codesystem.id).entries;
    }
    // in search situation codesystems are always multiselectable
    if (this.situation === 'SEARCH') {
      this.multiselect = true;
    }
    this.buildTree();
  }

  propagateChange = (_: any) => {
  };

  writeValue(value: any): void {
    this.dirty = false;
    this.value = value || null;
    const values = !this.value || Array.isArray(this.value) ? this.value || [] : [this.value];
    if (value === null || value === undefined) {
      if (this.multiselect) {
        this.selectedNodes = [];
      } else {
        this.selectedNodes = null;
      }
    }
    const nodes = !this.selectedNodes || Array.isArray(this.selectedNodes) ? this.selectedNodes || [] : [this.selectedNodes];

    if (this.codesystem && (nodes.length !== values.length || nodes.some((n, i) => n.data.data !== values[i]))) {
      this.buildTree();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  // handler invoked when an entry was selected using the autocomplete input
  onAutoCompleteSelect(node?) {
    const wasInvalid = !this.isValid;
    this.isValid = true;
    //this.updateTree();
    this.setFormControlValue(wasInvalid);
  }

  onAutocompleteValueChange(v: any) {
    if (v === null) {
      this.selectedNodes = null
      this.setFormControlValue();
    }
  }

  // handler invoked when an entry was deselected using the autocomplete input
  onAutoCompleteUnselect(node?) {
    this.selectedNodes = this.selectedNodes.filter(
      sNode => sNode.id !== node.id
    );
    //this.updateTree();
    this.setFormControlValue();
  }

  // triggered when the selection was changed by
  onTreeSelectionChanged(evt) {
    if (!this.multiselect) {
      // hide the dialog
      this.display = false;
    }
    const wasInvalid = !this.isValid;
    this.isValid = true;
    this.setFormControlValue(wasInvalid);
  }

  onClear() {
    if (!this.multiselect) {
      this.selectedNodes = null;
    }
    this.setFormControlValue();
  }

  /**
   * Sets and propagates the form controls value based on the components inner values. Propagates only
   * when the value has changed.
   * @param forcePropagation - forces propagation even if the value hasn't been changed
   */
  private setFormControlValue(forcePropagation?: boolean) {
    let v;
    let changed: boolean;
    if (this.multiselect) {
      v = this.selectedNodes.map(node => node.data.data);
      changed =
        !this.value ||
        !(
          v.length === this.value.length &&
          v.every(val => this.value.some(curVal => curVal === val))
        );
    } else {
      v = this.selectedNodes ? this.selectedNodes.data.data : null;
      changed = v !== this.value;
    }
    // only propagate the change if the value is different from the current one
    // otherwise we would always set the dirty flag although nothing changed
    if (changed || forcePropagation) {
      this.value = v;
      this.propagateChange(this.value);
    }
  }

  // build the tree for the dialog
  private buildTree() {

    this.selectedNodes = this.multiselect ? [] : null;
    this.autocompleteValues = [];
    let tree: TreeNode[] = [];
    for (let i = 0; i < this.codesystem.entries.length; i++) {
      this.addTreeNode(tree, this.codesystem.entries[i]);
    }
    this.tree = tree;
  }

  private addTreeNode(parentNode: TreeNode[], codesystemEntry) {
    if (this._filterFunc && !this._filterFunc(codesystemEntry)) {
      return;
    }
    let node: TreeNode = this.csEntryToTreeNode(codesystemEntry);
    if (node.selectable) {
      /** add selectable nodes to autocomplete values */
      this.autocompleteValues.push(node);
    }
    if (codesystemEntry.subentries) {
      node.children = [];

      for (let i = 0; i < codesystemEntry.subentries.length; i++) {
        this.addTreeNode(node.children, codesystemEntry.subentries[i]);
      }
    }
    this.checkSelected(node);
    parentNode.push(node);
  }

  // some nodes are only visible in some cases.
  private isHidden(codesystemEntry) {
    return (
      (this.situation === 'CREATE' && !codesystemEntry.allowedonnew) ||
      (this.situation === 'EDIT' && !codesystemEntry.allowedonupdate) ||
      (this._disablingFilterFunc && this._disablingFilterFunc(codesystemEntry))
    );
  }

  private checkSelected(node: TreeNode) {
    if (!this.value) {
      return;
    }
    if (this.multiselect) {
      for (let i = 0; i < this.value.length; i++) {
        if (node.data.data === this.value[i]) {
          node.selected = true;
          this.selectedNodes.push(node);
        }
      }
    } else {
      if (node.data.data === this.value) {
        node.selected = true;
        this.selectedNodes = node;
      }
    }
  }

  autocompleteFn(term: string) {
    this.autocompleteRes = this.autocompleteValues.filter(acNode => {
      if (this._filterFunc && !this._filterFunc(acNode.data)) {
        return false;
      }
      // skip nodes that are already selected
      if (this.multiselect) {
        if (
          this.selectedNodes.find(node => acNode.data.data === node.data.data)
        ) {
          return false;
        }
      }
      return acNode.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
    });
  }

  // transform codesystem entry to tree node
  private csEntryToTreeNode(codesystemEntry): TreeNode {
    // determine whether or not the tree node should be selectable
    let selectable = false;
    if (this.codesystem.allelementsselectable) {
      // case: all elements are selectable. This means that not only leaf nodes
      // are selectable but also their parent nodes
      selectable = true;
    } else {
      // case: only leaf nodes are selectable
      selectable = !(
        codesystemEntry.subentries && codesystemEntry.subentries.length > 0
      );
    }
    return {
      id: codesystemEntry.id,
      name: codesystemEntry.defaultrepresentation,
      children: [],
      expanded: false,
      selected: false,
      selectable: selectable && !this.isHidden(codesystemEntry),
      data: codesystemEntry
    };
  }

  showDialog(event?, display = true) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
      //ignore synthetized events on enter
      if (event.type === 'click' && event.detail === 0) {
        return;
      }
    }
    this.display = !!display;
  }

  // returns null when valid else the validation object
  public validate(c: UntypedFormControl) {
    return this.isValid
      ? null
      : {
        codesystem: {
          valid: false
        }
      };
  }
}
