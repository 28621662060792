<div class="eo-resubmission" eoTrapFocus>
  <h2>{{header}}</h2>
  <form #titleField [formGroup]="addResubmissionForm">
    <eo-form-input [skipToggle]="true" [label]="dueFieldLabel">
      <eo-date [withTime]="true" formControlName="dueFieldFCN"></eo-date>
    </eo-form-input>
    <eo-form-input [skipToggle]="true" [label]="titleFieldLabel" [required]="true" [invalid]="titleValidity"
      *ngIf="selection.length === 1">
      <eo-string formControlName="titleFieldFCN"></eo-string>
    </eo-form-input>
    <div class="err-msg" *ngIf="titleValidity">
      <div translate>eo.form.property.required</div>
    </div>
    <eo-form-input [skipToggle]="true" [label]="inboxCbLabel">
      <eo-checkbox formControlName="inboxCbFCN" [tristate]="false"></eo-checkbox>
    </eo-form-input>
    <eo-form-input [skipToggle]="true" [label]="emailCbLabel">
      <eo-checkbox formControlName="emailCbFCN" [tristate]="false" [readonly]="!hasEmail"></eo-checkbox>
    </eo-form-input>
  </form>

  <!-- error/validation messages -->
  <div class="errors">
    <div class="err-msg" *ngIf="notificationMethodValidation" translate>eo.resubmission.error.msg</div>
    <div class="err-msg" *ngIf="dateValidation" translate>eo.resubmission.error.msg.date</div>
  </div>

  <div class="form-buttons" *ngIf="!processing; else indicator">
    <button class="secondary" (click)="cancel()" translate>eo.resubmission.cancel</button>
    <button class="secondary" *ngIf="selection[0].resubmissions.length" (click)="removeResubmission()"
      translate>eo.resubmission.delete</button>
    <button class="primary" [disabled]="addResubmissionForm.errors || titleValidity"
      *ngIf="selection[0].resubmissions.length" (click)="updateResubmission()" translate>eo.resubmission.apply</button>
    <button class="primary" [disabled]="addResubmissionForm.errors || titleValidity"
      *ngIf="!selection[0].resubmissions.length" (click)="createResubmission()" translate>eo.resubmission.add</button>
  </div>

  <ng-template #indicator>
    <div class="process-indicator"><eo-icon [iconSrc]="'ic_kitt-loader.svg'"></eo-icon></div>
  </ng-template>
</div>