<header class="eo-head eo-head-tabs">
  <h2 translate>eo.state.object.frontpage.title</h2>
</header>

<yvc-tabs>
  <ng-template [yvcTab]="{id: 'last-changes', label: 'eo.state.object.frontpage.lastchanges' | translate}">
    <div class="panel last-changes">
        <div class="doc" *ngFor="let doc of frontPageData?.lastModifications"
          [ngClass]="{selected: selectedItem && doc.id === selectedItem.id}" (click)="selectFrontPageDoc(doc)"
          (contextmenu)="onContextMenuClicked($event, doc)">
          <div class="marker"></div>
          <div class="content">
            <div class="date">{{doc.modified | localeDate}}</div>
            <strong>{{doc.title}}</strong>
            <div class="desc">{{doc.description}}</div>
          </div>
        </div>
      </div>
  </ng-template>

  <ng-template [yvcTab]="{id: 'last-seen', label: 'eo.state.object.frontpage.lastseen' | translate}">
   <div class="panel last-seen">
        <div class="doc" *ngFor="let doc of seenItems"
          [ngClass]="{selected: selectedItem && doc.id === selectedItem.id}" (click)="selectFrontPageDoc(doc)"
          (contextmenu)="onContextMenuClicked($event, doc)">
          <div class="marker"></div>
          <div class="content">
            <div class="date">{{doc.modified.on | localeDate}}</div>
            <strong>{{doc.title}}</strong>
            <div class="desc">{{doc.description}}</div>
          </div>
        </div>
    </div> 
  </ng-template>

  <ng-content></ng-content>
</yvc-tabs>