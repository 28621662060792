<!-- <eo-dialog [visible]="true" [styleClass]="'eo-app-search-dialog'" [align]="'start'" [minWidth]="'80vw'"
  [minHeight]="'100vh'" (hide)="closeSearchMenu($event)"> -->
<!-- <yvc-dialog [options]="{lightDismiss: true}" [template]="tplSearch" (closed)="closeSearchMenu()"></yvc-dialog> -->
<ng-template #tplSearch>

  <div class="eo-app-search" [ngClass]="{expert: query.expertMode}" eoRtlAware="full" *ngIf="query">

    <div class="head">

      <div class="search">
        <form (submit)="executeSearch()">
          <div class="title" translate>eo.search</div>

          <eo-query-scope-select [selection]="queryScope" (select)="setQueryScope($event)"></eo-query-scope-select>

          <div class="search-box">

            <yvc-autocomplete *ngIf="!query.expertMode; else expertInput" #searchInput
              [ngModelOptions]="{standalone: true}" [(ngModel)]="query.term"
              (autocompleteFnc)="autocompleteSuggest($event)" [autocompleteValues]="autocompleteResults"
              (suggestionSelect)="autocompleteSelect()" [minLength]="3" (keydown.enter)="executeSearch()" (paste)="autocompletePaste($event)">
            </yvc-autocomplete>

            <ng-template #expertInput>
              <input autofocus="autofocus" class="expert-search__input" #xpinput type="text"
                (ngModelChange)="expertTermChanged()" [(ngModel)]="query.term" [ngModelOptions]="{standalone: true}">
            </ng-template>

            <button class="btn expert-mode" type="button" (click)="toggleExpertMode()">
              <!--<span translate>eo.search.mode.expert</span>-->
              <eo-icon [iconSrc]="'assets/_default/svg/ic_expert-search.svg'"
                [iconTitle]="('eo.search.mode.expert' | translate)" *ngIf="!query?.expertMode; else close"></eo-icon>
              <ng-template #close>
                <eo-icon [iconSrc]="'assets/_default/svg/ic_expert-search.svg'"
                  [iconTitle]="('eo.search.mode.expert.close' | translate)"></eo-icon>
              </ng-template>
            </button>

            <button type="button" class="btn expert-mode expert-mode__reset" [ngClass]="{active: query.term}"
              (click)="resetExpertModeInput()">
              <eo-icon [iconSrc]="'assets/_default/svg/ic_clear.svg'"
                [iconTitle]="('eo.search.clear.search' | translate)"></eo-icon>
            </button>

            <button class="btn btn-default" type="submit" [disabled]="queryState.isEmpty"
              translate>eo.search.submit</button>
          </div>
        </form>
      </div>

      <div class="result" *ngIf="!loading; else spinner">{{queryState.totalCount | localeDecimal}}</div>
      <ng-template #spinner>
        <eo-loading-spinner class="search-aggregator__loader" [size]="'medium'" [mode]="'dark'"></eo-loading-spinner>
      </ng-template>
      <eo-icon class="button white close" [iconSrc]="'assets/_default/svg/ic_clear.svg'"
        [iconTitle]="('eo.search.close' | translate)" (click)="closeSearchMenu()"></eo-icon>

    </div>

    <div class="body" *ngIf="!query.expertMode; else expert">

      <yvc-tabs (tabChange)="onTabChange($event)">

        <!-- object type select -->
        <ng-template [yvcTab]="{id: 'restrictType', label: 'eo.search.restrict.type' | translate}">

          <div class="object-types rest-box-container" [ngClass]="{idxenabled: query.types.length < 1}"
            *ngIf="objectTypeGroups && !idxSearch">
            <div class="group rest-box" *ngFor="let group of objectTypeGroups">
              <h3 *ngIf="group.label !== '0'">{{group.label}}</h3>
              <h3 *ngIf="group.label === '0'" translate>{{'eo.search.group.general'}}</h3>
              <div class="type" *ngFor="let type of group.types"
                [ngClass]="{context: type.isContextFolder, folder: type.isFolder, abstract: type.isAbstract, selected: isTypeActive(type), saved: isTypeSaved(type)}">

                <div class="checkbox" (click)="toggleType(type)">
                  <svg fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                  </svg>
                </div>
                <div class="item" (click)="selectObjectType(type)">
                  <eo-icon [objectType]="type"></eo-icon>
                  <div class="label">{{type.label}} {{type.isAbstract ? '('+ ('eo.search.type.abstract' | translate) +
                    ')' : ''}}</div>
                  <div class="count">{{getAggregationCount(type) | localeDecimal}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="indexdata-search" *ngIf="idxSearch">
            <h2>
              <eo-icon class="button" [iconSrc]="'assets/_default/svg/ic_arrow_back.svg'"
                (click)="exitIndexdataSearch()"></eo-icon>
              <span translate [translateParams]="{type: idxSearch.type.label}">eo.search.objecttype.form</span>
            </h2>

            <div class="context" *ngIf="idxSearch.contextModels.length">

              <div class="context-select">
                <div translate>eo.search.objecttype.form.context</div>
                <div class="spacer"></div>

                <eo-context-search *ngFor="let ctx of idxSearch.contextModels; index as i" [ctx]="ctx" [query]="query"
                  [selected]="currentContextFolderName === ctx.name"
                  [disabled]="(currentContextFolderName && currentContextFolderName !== ctx.name)"
                  [idxSearch]="idxSearch" (setContext)="setContext($event)">
                </eo-context-search>
              </div>

              <section class="context form">
                <eo-object-form class="dark" #ctxform
                  *ngIf="idxSearch.contextFormOptions && idxSearch.contextFormOptions?.formModel?.elements?.length"
                  [formOptions]="idxSearch.contextFormOptions" (statusChanged)="onIndexDataChanged($event)">
                </eo-object-form>
              </section>
            </div>


            <section class="object-type form" *ngIf="idxSearch.formOptions">
              <eo-object-form #idxform [formOptions]="idxSearch.formOptions"
                (statusChanged)="onIndexDataChanged($event)" (onFormReady)="idxform.focusForm()">
              </eo-object-form>
            </section>
          </div>
        </ng-template>

        <!-- created tab -->
        <ng-template [yvcTab]="{id: 'restrictCreated', label: 'eo.search.restrict.creation' | translate }">
          <div class="created rest-box-container" *ngIf="queryState.aggregations">

            <div class="rest-box box-timespan">
              <h3 translate>eo.search.agg.time.span</h3>
              <div class="rest-item" *ngFor="let agg of aggregationsModel[PARAMS.CREATED]"
                (click)="toggleFilter(PARAMS.CREATED, OPERATOR.RANGE, agg.key, agg.selected)"
                [ngClass]="{selected: agg.selected, saved: agg.saved}">
                <div class="label">{{agg.label}}</div>
                <div class="count">{{agg.value | localeDecimal}}</div>
              </div>
            </div>

            <div class="rest-box box-timespan-custom">
              <h3 translate>eo.search.agg.time.span.custom</h3>
              <form #createdForm="ngForm">
                <eo-form-input [label]="'eo.search.agg.time.span' | translate">
                  <eo-datetime-range #createdField [(ngModel)]="filtersModel[PARAMS.CREATED].value" name="range"
                    [operator]="filtersModel[PARAMS.CREATED].value ? filtersModel[PARAMS.CREATED].value.operator : OPERATOR.EQUAL"
                    [withTime]="createdField.searchOption && createdField.searchOption !== OPERATOR.EQUAL"
                    (ngModelChange)="setFilter(PARAMS.CREATED, OPERATOR.EQUAL, $event, createdForm)">
                  </eo-datetime-range>
                </eo-form-input>
              </form>
            </div>

            <div class="rest-box box-creator">
              <h3 translate>eo.search.agg.creator</h3>
              <form #creatorForm="ngForm">
                <eo-form-input [label]="'eo.search.agg.createdby' | translate">
                  <eo-organization [multiselect]="true" [(ngModel)]="filtersModel[PARAMS.CREATOR].value" name="creator"
                    (onValueResolved)="resolveLabel(filtersModel[PARAMS.CREATOR], $event)" [situation]="'SEARCH'"
                    (ngModelChange)="setFilter(PARAMS.CREATOR, OPERATOR.IN, $event, creatorForm)"></eo-organization>
                </eo-form-input>
              </form>
            </div>

          </div>
        </ng-template>

        <ng-template [yvcTab]="{id: 'restrictModification', label: 'eo.search.restrict.modification' | translate}">
          <div class="modified rest-box-container" *ngIf="queryState.aggregations">

            <div class="rest-box box-timespan">
              <h3 translate>eo.search.agg.time.span</h3>
              <div class="rest-item" *ngFor="let agg of aggregationsModel[PARAMS.MODIFIED]"
                (click)="toggleFilter(PARAMS.MODIFIED, OPERATOR.RANGE, agg.key, agg.selected)"
                [ngClass]="{selected: agg.selected, saved: agg.saved}">
                <div class="label">{{agg.label}}</div>
                <div class="count">{{agg.value | localeDecimal}}</div>
              </div>
            </div>

            <div class="rest-box box-timespan-custom">
              <h3 translate>eo.search.agg.time.span.custom</h3>
              <form #modifiedForm="ngForm">
                <eo-form-input [label]="'eo.search.agg.time.span' | translate">
                  <eo-datetime-range #modifiedField [(ngModel)]="filtersModel[PARAMS.MODIFIED].value" name="range"
                    [operator]="filtersModel[PARAMS.MODIFIED].value ? filtersModel[PARAMS.MODIFIED].value.operator : OPERATOR.EQUAL"
                    [withTime]="modifiedField.searchOption && modifiedField.searchOption !== OPERATOR.EQUAL"
                    (ngModelChange)="setFilter(PARAMS.MODIFIED, OPERATOR.EQUAL, $event, modifiedForm)">
                  </eo-datetime-range>
                </eo-form-input>
              </form>
            </div>

            <div class="rest-box box-modifier">
              <h3 translate>eo.search.agg.modifier</h3>
              <form #modifierForm="ngForm">
                <eo-form-input [label]="'eo.search.agg.modifiedby' | translate">
                  <eo-organization [multiselect]="true" [(ngModel)]="filtersModel[PARAMS.MODIFIER].value"
                    name="modifier" (onValueResolved)="resolveLabel(filtersModel[PARAMS.MODIFIER], $event)"
                    [situation]="'SEARCH'"
                    (ngModelChange)="setFilter(PARAMS.MODIFIER, OPERATOR.IN, $event, modifierForm)"></eo-organization>
                </eo-form-input>
              </form>
            </div>
          </div>
        </ng-template>

        <ng-template [yvcTab]="{id: 'restrictFile', label: 'eo.search.restrict.file' | translate}">
          <div class="file rest-box-container" *ngIf="queryState.aggregations">

            <div class="rest-box box-mimetype-group">
              <h3 translate>eo.search.restrict.mimetypegroup</h3>
              <div class="rest-item" *ngFor="let agg of aggregationsModel[PARAMS.MIMETYPEGROUP]"
                (click)="setFilter(PARAMS.MIMETYPEGROUP, OPERATOR.IN, resolveSelection(aggregationsModel[PARAMS.MIMETYPEGROUP], agg.key, agg.selected))"
                [ngClass]="{selected: agg.selected, saved: agg.saved}">
                <div class="checkbox">
                  <svg fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                  </svg>
                </div>
                <div class="label">{{agg.label}}</div>
                <div class="count">{{agg.value | localeDecimal}}</div>
              </div>
            </div>

            <div class="rest-box box-filesize">
              <h3 translate>eo.search.restrict.filesize</h3>
              <div class="rest-item" *ngFor="let agg of aggregationsModel[PARAMS.FILESIZE]"
                (click)="toggleFilter(PARAMS.FILESIZE, OPERATOR.RANGE, agg.key, agg.selected)"
                [ngClass]="{selected: agg.selected, saved: agg.saved}">
                <div class="label">{{agg.label}}</div>
                <div class="count">{{agg.value | localeDecimal}}</div>
              </div>
            </div>

            <div class="rest-box box-filename">
              <h3 translate>eo.search.restrict.filename</h3>
              <form #filenameForm="ngForm">
                <eo-form-input [label]="'eo.search.restrict.filename' | translate">
                  <eo-string [autocomplete]="true" [qname]="'filename'"
                    [(ngModel)]="filtersModel[PARAMS.FILENAME].value" name="filename"
                    (ngModelChange)="setFilter(PARAMS.FILENAME, OPERATOR.EQUAL, $event, filenameForm)"></eo-string>
                </eo-form-input>
              </form>
            </div>
          </div>
        </ng-template>
      </yvc-tabs>

      <!-- summary of the current search filters -->
      <div class="summary-panel">

        <div class="headline stored-query" *ngIf="isStoredQuery; else reghead">
          <div class="pre" translate>eo.search.aside.summary.storedquery</div>
          <div class="stored-query-name">{{storedQuery.name}}</div>
        </div>

        <ng-template #reghead>
          <div class="headline" translate>eo.search.aside.summary</div>
        </ng-template>

        <section class="query-types">
          <div class="section section-searchterm" *ngIf="query.term">
            <div class="title" translate>eo.search.aside.summary.term</div>
            <div class="filter">
              <div class="label">{{query.term}}</div>
              <eo-icon [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="removeTerm()"></eo-icon>
            </div>
          </div>

          <div class="section section-contexttype" *ngIf="query.contextFolderTypes.length">
            <div class="title" translate>eo.search.aside.summary.contextfoldertypes</div>
            <div class="filter" *ngFor="let type of query.contextFolderTypes">
              <div class="label">{{type.label}}</div>
              <eo-icon [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="removeContextType()"></eo-icon>
            </div>
          </div>

          <div class="section section-objecttype" *ngIf="query.types.length">
            <div class="title" translate>eo.search.aside.summary.types</div>
            <div class="filter" *ngFor="let type of query.types">
              <div class="label">{{type.label}}</div>
              <eo-icon [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="removeType(type)"></eo-icon>
            </div>
          </div>

          <ng-template #filterTemplate let-filter let-translate>
            <ng-container *ngIf="filter.innerValue.length">
              <div class="filter" *ngFor="let val of filter.innerValue; index as i">
                <div class="label">{{filter.label[i] | async}}</div>
                <eo-icon [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="resetFilter(filter, val)"></eo-icon>
              </div>
            </ng-container>
          </ng-template>

          <div class="section section-creation"
            *ngIf="filtersModel[PARAMS.CREATED].innerValue.length || filtersModel[PARAMS.CREATOR].innerValue.length">
            <div class="title" translate>eo.search.restrict.creation</div>
            <ng-template [ngTemplateOutlet]="filterTemplate"
              [ngTemplateOutletContext]="{ $implicit: filtersModel[PARAMS.CREATED] }"></ng-template>
            <ng-template [ngTemplateOutlet]="filterTemplate"
              [ngTemplateOutletContext]="{ $implicit: filtersModel[PARAMS.CREATOR] }"></ng-template>
          </div>

          <div class="section section-modification"
            *ngIf="filtersModel[PARAMS.MODIFIED].innerValue.length || filtersModel[PARAMS.MODIFIER].innerValue.length">
            <div class="title" translate>eo.search.restrict.modification</div>
            <ng-template [ngTemplateOutlet]="filterTemplate"
              [ngTemplateOutletContext]="{ $implicit: filtersModel[PARAMS.MODIFIED] }"></ng-template>
            <ng-template [ngTemplateOutlet]="filterTemplate"
              [ngTemplateOutletContext]="{ $implicit: filtersModel[PARAMS.MODIFIER] }"></ng-template>
          </div>

          <div class="section section-mimetypegroup"
            *ngIf="filtersModel[PARAMS.MIMETYPEGROUP].innerValue.length || filtersModel[PARAMS.FILESIZE].innerValue.length || filtersModel[PARAMS.FILENAME].innerValue.length">
            <div class="title" translate>eo.search.restrict.file</div>
            <ng-template [ngTemplateOutlet]="filterTemplate"
              [ngTemplateOutletContext]="{ $implicit: filtersModel[PARAMS.MIMETYPEGROUP] }"></ng-template>
            <ng-template [ngTemplateOutlet]="filterTemplate"
              [ngTemplateOutletContext]="{ $implicit: filtersModel[PARAMS.FILESIZE] }"></ng-template>
            <ng-template [ngTemplateOutlet]="filterTemplate"
              [ngTemplateOutletContext]="{ $implicit: filtersModel[PARAMS.FILENAME] }"></ng-template>
          </div>
        </section>

        <div class="actions" *ngIf="query.term || query.filters.length || query.types.length; else emptySearch">

          <ng-container *ngIf="isStoredQuery; else regactions">
            <button class="secondary btn-reset" (click)="resetQuery()" translate>eo.search.storedquery.close</button>
            <button class="primary grey btn-update" (click)="updateStoredQuery()"
              *ngIf="capabilities.storedqueries && isOwnQuery" translate>eo.search.storedquery.update</button>
            <button class="primary grey btn-save" (click)="saveQuery()"
              *ngIf="capabilities.storedqueries && !isOwnQuery" translate>eo.search.save</button>
            <button class="primary btn-submit" (click)="executeSearch()" [disabled]="queryState.isEmpty"
              translate>eo.search.submit</button>
          </ng-container>

          <ng-template #regactions>
            <button class="primary grey btn-reset" (click)="resetQuery()" translate>eo.search.reset</button>
            <button class="primary grey btn-save" (click)="saveQuery()"
              *ngIf="capabilities.storedqueries && !isOwnQuery" translate>eo.search.save</button>
            <button class="primary btn-submit" (click)="executeSearch()" [disabled]="queryState.isEmpty"
              translate>eo.search.submit</button>
          </ng-template>


        </div>

        <ng-template #emptySearch>
          <div class="empty-search" translate>eo.search.summary.empty</div>
        </ng-template>

      </div>


    </div>

    <!-- expert mode body panel containing explanations -->
    <ng-template #expert>
      <div class="body expert">

        <div class="empty" *ngIf="expertModeQueryEmpty"><span translate>eo.search.mode.expert.result.empty</span></div>

        <!--
        <h2><span translate>eo.search.mode.expert</span></h2>
        <div class="intro" translate>eo.search.mode.expert.intro</div>

        <div class="base-params fields">
          <h2 translate>eo.search.mode.expert.support.baseparams</h2>
          <div class="items">
            <div class="item" *ngFor="let el of expertModeSupport.baseParams" (click)="xpAddToTerm(el.data)">
              <div class="label"><span>{{el.label}}</span></div>
              <div class="data">{{el.data}}</div>
            </div>
          </div>
        </div>

        <div class="system-types fields">

          <div class="column column-group">
            <h2 translate>eo.search.mode.expert.support.group</h2>
            <div class="items">
              <div class="item" *ngFor="let group of typeGroupTree"
                   [ngClass]="{selected: expertModeSupport.selectedGroup && group.id === expertModeSupport.selectedGroup.id}"
                   (click)="xpSelectGroup(group)">
                <div *ngIf="group.label !== '0'">{{group.label}}</div>
                <div *ngIf="group.label === '0'">{{'eo.search.group.general' | translate}}</div>
              </div>
            </div>
          </div>

          <div class="column column-type" *ngIf="expertModeSupport.selectedGroup">
            <h2 translate>eo.search.mode.expert.support.type</h2>
            <div class="items">
              <div class="item" *ngFor="let type of expertModeSupport.selectedGroup.tree"
                   [ngClass]="{selected: expertModeSupport.selectedType && type.id === expertModeSupport.selectedType.id}"
                   (click)="xpSelectType(type)">
                <div>{{type.label}}</div>
              </div>
            </div>
          </div>

          <div class="column section-field" *ngIf="expertModeSupport.selectedType">
            <h2 translate>eo.search.mode.expert.support.field</h2>
            <div class="items">
              <div class="item" *ngFor="let el of expertModeSupport.selectedType.children" (click)="xpAddToTerm(el.data)">
                <div class="label"><span>{{el.label}}</span><span class="type">{{el.type}}</span></div>
                <div class="data">{{el.data}}</div>
              </div>
            </div>
          </div>

        </div>

        -->
      </div>
    </ng-template>
  </div>
  <!-- </eo-dialog> -->
</ng-template>
