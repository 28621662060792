import {debounceTime, switchMap, map} from "rxjs/operators";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ElementRef
} from "@angular/core";
import {ObjectFormHelperService} from "../../object-form/object-form-helper.service";
import {ObjectFormControlWrapper} from "../../object-form/object-form/object-form-control-wrapper";
import {AppSearchService} from "../../../eo-framework-core/search/app-search.service";
import {ObjectFormGroup} from "../../object-form/object-form/object-form-group.model";
import {
  StoredQueriesService,
  SearchFilter,
  SearchState,
  StoredQuery,
  SearchService,
  TranslateService,
  SystemService,
  QueryScope
} from "@eo-sdk/core";
import {UtilitiesService} from "../../util/services/utilities.service";
import {forkJoin, Observable, of} from "rxjs";

@Component({
  selector: "eo-stored-query",
  templateUrl: "./stored-query.component.html",
  styleUrls: ["./stored-query.component.scss"]
})
export class StoredQueryComponent {
  queryScope: QueryScope;
  _storedQuery: StoredQuery;
  queryFormControls: ObjectFormControlWrapper[];
  queryForm: ObjectFormGroup;
  fulltextFormControl: ObjectFormControlWrapper;
  _formOnly: boolean;

  @Input("query")
  set query(storedQuery: StoredQuery) {
    if (storedQuery instanceof StoredQuery) {
      this._storedQuery = storedQuery;
      this._storedQuery.state.count = {value : 0};
      this.queryScope = this._storedQuery.scope;

      this.createQueryForm();
      this.aggregate();
    }
  }

  get query() {
    return this._storedQuery;
  }

  // set to true only the stored query form will be rendered, otherwise
  // we'll get the complete control
  @Input()
  set formOnly(b: boolean) {
    this._formOnly = b;
  }

  @Output() onQueryLoaded = new EventEmitter();
  @Output() onQueryExecute = new EventEmitter();

  @HostBinding("class.open") isOpen = this.formOnly;

  constructor(
    private searchService: SearchService,
    private elementRef: ElementRef,
    private translate: TranslateService,
    private systemService: SystemService,
    private storedQueriesService: StoredQueriesService,
    private formHelperService: ObjectFormHelperService,
    private appSearchService: AppSearchService
  ) {}

  public setQueryScope(scope: QueryScope) {
    this._storedQuery.scope = scope;
    this.queryScope = scope;
    this.aggregate();
  }

  // generate the form for the dynamic parts of the stored query
  createQueryForm() {
    this.queryFormControls = [];
    this.fulltextFormControl = null;

    // parameters of a stored query contain the dynamic parts of the query, so
    // these are the fields to be rendered
    if (this._storedQuery.parameter && this._storedQuery.parameter.length > 0) {
      const paramQNames = this._storedQuery.parameter.map(p => p.qname);
      const fulltextParam = this._storedQuery.parameter.find(
        p => p.type === StoredQueriesService.FULLTEXT
      );

      // fulltext param get a special handling
      if (fulltextParam) {
        this.fulltextFormControl = this.formHelperService.elementToFormControl(
          this.storedQueriesService.getFulltextFormElement(
            this.translate.instant("eo.storedquery.search.term"),
            this._storedQuery.term
          ),
          "SEARCH"
        );
      }

      this.getFormElements().subscribe(elements => {
        if (elements.length) {
          let paramElements = elements.filter(
            e => paramQNames.indexOf(e.qname) !== -1
          );
          // now, create the actual form controls from the param elements
          paramElements.forEach(paramElement => {
            if(paramElement.type === 'TABLE'){
              let filters = this._storedQuery.getTableFilters(paramElement.qname);
              if(filters && filters.length){
                paramElement.value = this.searchService.tableFiltersToElementValue(filters, paramElement.elements);
              } else {
                paramElement.value = [{}];
                delete paramElement.isNotSetValue;
              }
            }else{
              // grab value for the control from corresponding query filters
              let filter = this._storedQuery.getFilter(paramElement.qname);
              // we may have params that are not part of the queries filters. This may be the
              // case when we mark form elements as dynamic fields without providing a value
              if (filter) {
                // filter to have no value
                if (
                  filter.operator === SearchFilter.OPERATOR.EQUAL &&
                  filter.firstValue === null
                ) {
                  paramElement.isNotSetValue = true;
                } else {
                  delete paramElement.isNotSetValue;
                }
  
                paramElement.value = this.searchService.filterToElementValue(
                  this._storedQuery.getFilter(paramElement.qname),
                  paramElement.type
                );
              } else {
                paramElement.value = undefined;
              }
            }
            // name property of stored query param has to be qname of form element
            // because we may combine multiple forms (context form) and in this case name could be not unique
            paramElement.name = paramElement.qname;
            let wrapper = this.formHelperService.elementToFormControl(
              paramElement,
              "SEARCH"
            );
            if (wrapper) {
              this.queryFormControls.push(wrapper);
            }
          });
        }

        let form = new ObjectFormGroup({});
        // add fulltext when available
        if (this.fulltextFormControl) {
          form.addControl(
            this.fulltextFormControl._eoFormControlWrapper.controlName,
            this.fulltextFormControl
          );
        }
        this.queryFormControls.forEach(c =>
          form.addControl(c._eoFormControlWrapper.controlName, c)
        );

        // only setup the form if we actually have controls applied to it
        if (Object.keys(form.controls).length) {
          this.queryForm = form;
          this.queryForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            // update query based on the form values
            //for (let key of Object.keys(formData)) {
            for (let key of Object.keys(this.queryForm.controls)) {
              //let value = formData[key];
              let value = this.getValue(key);

              if (key === StoredQueriesService.FULLTEXT) {
                this._storedQuery.term = value || "";
              } else {
                const wrapper: ObjectFormControlWrapper = this.queryForm
                  .controls[key] as ObjectFormControlWrapper;
                const formElementRef: any =
                  wrapper.controls[wrapper._eoFormControlWrapper.controlName];

                const filters: SearchFilter[] =
                  UtilitiesService.isEmpty(value) &&
                    !formElementRef._eoFormElement.isNotSetValue
                    ? []
                    : this.searchService.getSearchFilter(
                      this._storedQuery.types,
                      key,
                      formElementRef._eoFormElement.isNotSetValue
                        ? null
                        : value
                    );
                    
                if(formElementRef._eoFormElement.type === 'TABLE'){
                  formElementRef._eoFormElement.elements.forEach(el => {
                    this._storedQuery.removeFilter(el.qname);
                  });
                }
                filters.forEach(f => this._storedQuery.addFilter(f));
                if (!filters.length) {
                  this._storedQuery.removeFilter(key);
                }
              }
            }
            // fetch aggregations every time the form data changes
            this.aggregate();
          });
          // set focus to the first input, needs Timeout because form may not have been rendered
          setTimeout(() => {
            const el = this.elementRef.nativeElement.querySelector("input");
            if (el) {
              el.focus();
            }
          }, 0);
        }
      });
    }
  }

  private getFormElements(): Observable<any[]> {
    // for form fields we use the qname of the parameter to fetch the corresponding form element
    // from object definition although they may be provided, because they may have
    // changed since the query was persisted
    if (this._storedQuery.types.length === 1) {
      // creating params form only makes sense if we have one single target type ...
      let formElements = [];
      const elementFetchTasks = [];
      elementFetchTasks.push(
        this.systemService.getFormElementsFromFormModel(
          this._storedQuery.types[0].qname,
          "SEARCH"
        )
      );
      // ... contextfoldertype may also be provided. They will be set up first to be
      // on top of the generated form
      if (this._storedQuery.contextFolderTypes.length === 1) {
        elementFetchTasks.push(
          this.systemService.getFormElementsFromFormModel(
            this._storedQuery.contextFolderTypes[0].qname,
            "SEARCH",
            true
          )
        );
        // elements = this._storedQuery.contextFolderTypes[0].elements;
      }
      return forkJoin(elementFetchTasks).pipe(
        map((res: any[]) => {
          formElements = res.length === 2 ? [...res[0], ...res[1]] : res[0];
          return formElements.map(e => ({...e}));
        })
      );
    } else {
      return of([]);
    }
  }

  private getValue(qname: string): any {
    const control = this.queryForm.controls[qname];
    return control.value && control.value[qname];
  }

  load() {
    this.appSearchService.setQuery(this._storedQuery);
    this.onQueryLoaded.emit();
  }

  execute() {
    this.onQueryExecute.emit({
      queryJson: this._storedQuery.getQueryJson()
    });
  }

  private aggregate() {
    this.searchService
      .getSearchState({aggs: {type: {}}, ...this._storedQuery.getQueryJson()})
      .subscribe((state: SearchState) => {
        this._storedQuery.state = state;
      });
  }
}
