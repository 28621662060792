<yvc-split-view [layoutSettingsID]="'recyclebin.state'">
  <ng-template yvcSplitArea [size]="25">
    <eo-list-container #eoList [applySelection]="{}" [parseDmsParams]="parseDmsParams">
      <div class="eo-header">
        <eo-icon class="eo-header-icon" [iconSrc]="'assets/_default/svg/ic_trash.svg'"></eo-icon>
        <div class="eo-header-info">
          <div class="eo-header-title" translate>eo.recyclebin.list.title</div>
        </div>
        <div class="eo-header-actions">
          <eo-icon class="button refresh-button" [iconSrc]="'assets/_default/svg/ic_refresh.svg'"
            (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"
            [iconTitle]="('eo.list.refresh' | translate)"></eo-icon>

          <eo-overlay #oFilter class="overlay-filter" [iconSrc]="'assets/_default/svg/ic_filter.svg'" [iconTitle]="'eo.list.sort'  | translate">
            <eo-custom-filter #cFilter (change)="cFilter.updateGrid(eoList.eoGridSubject, $event)"
              (active)="oFilter.onActiveChanged($event)">
              <eo-text-filter [title]="'eo.recyclebin.filter.title' | translate" [id]="'recyclebin.text'"
                [matchFields]="['title','description']" [filterParams]="textFilterParams">
              </eo-text-filter>

            </eo-custom-filter>
          </eo-overlay>

          <eo-overlay #oSort class="overlay-sort" [iconSrc]="'assets/_default/svg/ic_sort.svg'" [iconTitle]="'eo.list.sort' | translate">
            <eo-custom-sort #cSort [id]="'recyclebin.sort'" (change)="cSort.updateGrid(eoList.eoGrid, $event)"
              (active)="oSort.onActiveChanged($event)" [sortFields]="sortFields"></eo-custom-sort>
          </eo-overlay>

        </div>
      </div>
      <div class="eo-body">
        <eo-grid #eoGrid *ngIf="gridOptions?.rowData" [gridOptions]="gridOptions" [fullWidth]="true"
          [showHeader]="true" [showFooter]="true" [selectFirst]="true"
          [selectionLimit]="1"
          (eoGridCountChanged)="eoList.onCountChanged($event)"
          (eoGridSelectionChanged)="eoList.onSelectionChanged($event)"
          (eoGridFocusChanged)="eoList.onFocusChanged($event)"
          >

          <div class="header">
          </div>

          <div class="footer">
            <eo-total-count [gridCount]="eoList.gridCount" [outsideGrid]="false" class="flex-row"></eo-total-count>
          </div>

          <div class="empty">
            <eo-error-message
              [emptyState]="{icon: 'ic_trash.svg', text: oFilter.active ? 'eo.no.filter.result' : 'eo.recyclebin.list.empty'}"></eo-error-message>
            <eo-reset-filter [isFilterActive]="oFilter.active"
              (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"></eo-reset-filter>
          </div>

        </eo-grid>
      </div>
    </eo-list-container>
  </ng-template>

  <ng-template yvcSplitArea [size]="75">
    <eo-recyclebin-details [applySelection]="{}" [parseDmsParams]="parseDmsParams" [emptyState]="emptyState"
        [cacheLayout]="'recyclebin.state.details'" (hasContent)="hasContent = $event">
        <eo-tab-plugin [type]="'object-details-tab.favorite'"></eo-tab-plugin>
      </eo-recyclebin-details>
  </ng-template>
</yvc-split-view>
