import {Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation} from '@angular/core';

// import { ObjectFormComponent } from '../../object-form.component';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {LocalStorageService, Utils} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {PendingChangesService} from '../../../../../eo-framework-core/pending-changes/pending-changes.service';
import {FormStatusChangedEvent} from '../../../form-status-changed-event.interface';
import {FormGenResult, ObjectFormHelperService} from '../../../object-form-helper.service';
import {ObjectFormControlWrapper} from '../../object-form-control-wrapper';
import {ObjectFormGroup} from '../../object-form-group.model';
import {ObjectFormScriptService} from '../../object-form-script/object-form-script.service';
import {ObjectFormScriptingScope} from '../../object-form-script/object-form-scripting-scope';
import {EditRow, EditRowResult} from '../form-element.interface';

/**
 * Component for editing a row from an object forms table.
 */
@UntilDestroy()
@Component({
  selector: 'eo-row-edit',
  templateUrl: './row-edit.component.html',
  styleUrls: ['./row-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ObjectFormScriptService],
})
export class RowEditComponent implements OnDestroy {
  @ViewChild('confirmDelete') confirmDeleteButton: ElementRef;

  // ID set by pendingChanges service when editing row data
  // Used to finish the pending task when editing is done
  pendingTaskId: string;

  editForm: ObjectFormGroup;
  editFormControls: ObjectFormControlWrapper[] = [];
  private scriptingScope: ObjectFormScriptingScope;
  private subscriptions: Subscription[] = [];
  // property for holding the forms data used for comparison when a form-changed-event
  // is fetched to indicate wher or not the indexdata were changed or just the properties
  // of the form elements (eg. form script setting fields to readonly)
  private formData: any;

  _row: EditRow;
  isNewRow: boolean;
  formState?: FormStatusChangedEvent;
  queryEnabled = true;
  copyEnabled = true;
  deleteEnabled = true;
  saveEnabled = true;
  createNewCheckbox: UntypedFormControl;
  createNewRow = false;
  _showDeleteDialog = false;
  saving = false;

  set showDeleteDialog(val: boolean) {
    this._showDeleteDialog = val;

    if (this._showDeleteDialog) {
      setTimeout(() => this.confirmDeleteButton.nativeElement.focus(), 0);
    }
  }

  get showDeleteDialog() {
    return this._showDeleteDialog;
  }

  @Input() limitReached: boolean;

  @Input()
  set row(r: EditRow) {
    this._row = r;
    this.queryEnabled = r?.situation === 'SEARCH';
    this.copyEnabled = !this.queryEnabled;
    this.saveEnabled = !this.queryEnabled;
    this.deleteEnabled = !this.queryEnabled;
    this.isNewRow = this._row?.index === -1;
    this.unsubscribeAll();

    this._buildRowEditForm()
  }

  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<EditRowResult>();
  @Output() onSaveCopy = new EventEmitter<EditRowResult>();
  @Output() onDelete = new EventEmitter<number>();

  constructor(private pendingChanges: PendingChangesService,
    private formScriptService: ObjectFormScriptService,
    private formHelperService: ObjectFormHelperService,
    private fb: UntypedFormBuilder, private storageService: LocalStorageService) {
    this.createNewRow = !!this.storageService.getItem('createNewRow');
    this.createNewCheckbox = this.fb.control(this.createNewRow);
    this.createNewCheckbox.valueChanges.pipe(untilDestroyed(this)).subscribe((v) => {
      this.createNewRow = v;
      this.storageService.setItem('createNewRow', this.createNewRow);
    });
  }

  /**
 * Returns the observed model that was passed to the current form script running. If there is
 * no form script, this method will return NULL.
 * @returns
 */
  private _getObservedScriptModel() {
    return this.scriptingScope ? this.scriptingScope.getModel() : null;
  }

  private _getFormData() {
    return this.formHelperService.extractFormData(
      this.editForm,
      this._row.situation,
      this._row.form.data,
      true
    );

  }

  onFormReady() {
    // execute after form has been set up, because otherwise not all of the components are ready to be used
    // if for example a form script executed `onrowedit` tries to apply a filter or something like that
    if (this._row.tableElement.onrowedit && !this._row.tableElement.readonly) {

      // Generate row API object (wrapper) for the script
      const row = {
        model: this._getObservedScriptModel(),
        index: this._row.index,
        copyEnabled: this.copyEnabled,
        deleteEnabled: this.deleteEnabled,
        saveEnabled: this.saveEnabled,
        persisted: this.isNewRow // Not persisted if it is a new row
      };
      // Call the script function ...
      this._row.tableElement.onrowedit(this._row.tableElement, row);
      // ... and respect the result
      this.copyEnabled = row.copyEnabled;
      this.deleteEnabled = row.deleteEnabled;
      this.saveEnabled = row.saveEnabled;
    }
  }

  onFormStatusChanged(evt) {

    // check if indexdata has been changed
    let currentFormData = this._getFormData();
    let idxChange = !!this.formData && JSON.stringify(this.formData) !== JSON.stringify(currentFormData);
    this.formData = Utils.formDataParse(Utils.formDataStringify(currentFormData));

    this.formState = {
      invalid: this.editForm.invalid,
      dirty: this.editForm.dirty,
      data: this.formData,
      indexdataChanged: idxChange
    };
    if (this.formState.indexdataChanged) {
      this.startPending();
    } else {
      this.finishPending();
    }
  }

  private _buildRowEditForm() {
    if (this._row) {
      const formGenRes: FormGenResult = this.formHelperService.buildReactiveForm(
        {
          formModel: {
            situation: this._row.situation,
            elements: [{
              type: 'o2mGroup',
              elements: structuredClone(this._row.form.elements)
            }]
          }, disabled: this._row.form.disabled, data: this._row.form.data
        }, this.formScriptService, true
      );
      this.editForm = formGenRes.form;
      this.scriptingScope = formGenRes.scriptingScope;
      this.subscriptions = formGenRes.controlValueSubscriptions;

      const ctrls = (this.editForm.controls.core as ObjectFormGroup).controls;
      this.editFormControls = Object.keys(ctrls).map(k => ctrls[k] as ObjectFormControlWrapper)
      this.formState = {
        invalid: this.editForm.invalid,
        dirty: this.editForm.dirty,
        data: this.formData,
        indexdataChanged: false
      };
      setTimeout(() => {
        if (!this.editForm) return;
        let formStateWatch = this.editForm.statusChanges.pipe()
          .subscribe((e) => this.onFormStatusChanged(e));
        this.subscriptions.push(formStateWatch);
        this.onFormReady();
      }, 300);
    }
  }

  private startPending() {
    // because this method will be called every time the form status changes,
    // pending task will only be started once until it was finished
    if (!this.pendingChanges.hasPendingTask(this.pendingTaskId || ' ')) {
      this.pendingTaskId = this.pendingChanges.startTask();
    }
  }

  finishPending() {
    this.pendingChanges.finishTask(this.pendingTaskId);
  }

  save() {
    this.saving = true;
    this.finishPending();
    setTimeout(() => {
      if (!this.formState.invalid) {
        this.onSave.emit({
          index: this._row.index,
          rowData: this._getFormData(),
          createNewRow: this.createNewRow
        });
      }
      this.saving = false;
    }, 500);
  }

  saveCopy() {
    this.saving = true;
    setTimeout(() => {
      if (!this.formState.invalid) {
        this.onSaveCopy.emit({
          index: this._row.index,
          rowData: this._getFormData(),
          createNewRow: this.createNewRow
        });
      }
      this.saving = false;
    }, 500);
  }

  delete() {
    this.onDelete.emit(this._row.index);
    this.showDeleteDialog = false;
  }

  cancel() {
    this.finishPending();
    this.onCancel.emit();
  }

  private unsubscribeAll() {
    if (this.subscriptions.length) {
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions = [];
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }
}
