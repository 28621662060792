import {map} from 'rxjs/operators';
import {ApplicationRef, Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {AgentService} from '../agent/agent.service';
import {
  EoUser, Logger, NotificationsService, BackendService, ClipboardService,
  DmsService, EventService, UserService, DmsObject, ClipboardAction, Utils,
  TranslateService, HttpOptions, EnaioEvent
} from '@eo-sdk/core';

export const UNDOCK_WINDOW_NAME = 'eoViewer';
@Injectable()
export class PluginsService {

  private user: EoUser;
  private agentAction = {
    OPEN: 'eo.agent.action.open',
    MAIL: 'eo.agent.action.mail',
    MAIL_AS_PDF: 'eo.agent.action.mail-pdf',
    CLIPBOARD: 'eo.agent.action.clipboard',
    CLIPBOARD_AS_PDF: 'eo.agent.action.clipboard-pdf',
    EXTERNAL: 'eo.agent.action.external'
  };

  public get currentUrl() {
    return this.router.url;
  }

  public get api() {
    return this.getApi();
  }

  public applyFunction(value: string | Function | any, params?: string, args?: any) {
    const fnc = value?.toString().trim();
    if (!fnc) return;
    const f = fnc.match(/^function|^\(.*\)\s*=>/)
      ? `return (${fnc}).apply(this,arguments)`
      : typeof value === 'string' && !fnc.startsWith("'")
      ? `return '${fnc}'`
      : `return ${fnc}`;
      try {
        return new Function(...(params || 'api').split(',').map((a) => a.trim()), f).apply(this.api, args || [this.api]);
      } catch(e) {
        console.error(e);
        return;
      }
  }

  constructor(private backend: BackendService,
    private notifications: NotificationsService,
    private clipboard: ClipboardService,
    private logger: Logger,
    public translate: TranslateService,
    private dmsService: DmsService,
    private router: Router,
    private agentService: AgentService,
    private eventService: EventService,
    private userService: UserService,
    private appRef: ApplicationRef,
    private ngZone: NgZone) {

    window['api'] = this.api;

    this.userService
      .user$
      .subscribe((user) => this.user = user);
  }

  // todo: create inreface for API

  public getApi(): any {
    return {
      detectChanges: () => {
        setTimeout(() => {
          this.appRef.tick();
        }, 500);
      },
      router: {
        get: () => this.router
      },
      events: {
        enaioEventType: EnaioEvent,
        on: (type: string) => this.ngZone.run(() => this.eventService.on(type)),
        trigger: (type: string, data?: any) => this.ngZone.run(() => this.eventService.trigger(type, data))
      },
      session: {
        getUser: () => this.getCurrentUser()
      },
      dms: {
        getObject: (id, type, version) => this.getDmsObject(id, type, version),
        getResult: (fields, type) => this.getResult(fields, type),
        downloadContent: (dmsObjects: DmsObject[], rendition?: 'PDF'
          | 'TIFF'
          | 'TEXT'
          | 'JPEG') => this.backend.downloadContent(dmsObjects, rendition)
      },
      http: {
        get: (uri, base, options) => this.get(uri, base, options),
        post: (uri, data, base, options) => this.post(uri, data, base, options),
        del: (uri, base, options) => this.del(uri, base, options),
        put: (uri, data, base, options) => this.put(uri, data, base, options)
      },
      config: {
        get: () => this.getConfig()
      },
      content: {
        viewer: () => window[UNDOCK_WINDOW_NAME] || (window.document.querySelector('eo-media iframe[src]') || {})['contentWindow'],
        triggerError: (err, win, parameters) => this.ngZone.run(() => this.eventService.trigger(UNDOCK_WINDOW_NAME + 'Error', { err, win, parameters })),
        catchError: () => this.ngZone.run(() => this.eventService.on(UNDOCK_WINDOW_NAME + 'Error'))
      },
      util: {
        $: (selectors, element) => (element || window.document).querySelector(selectors),
        $$: (selectors, element) => (element || window.document).querySelectorAll(selectors),
        styles: (styles, id = '__styles', win: any = window) => {
          let s = win.document.head.querySelector('#' + id);
          if (!s) {
            s = win.document.createElement('style');
            s.setAttribute('id', id);
            win.document.head.appendChild(s);
          }
          s.innerHTML = styles;
        },
        translate: (key, data) => this.translate.instant(key, data),
        encodeFileName: (filename) => this.encodeFileName(filename),
        notifier: {
          success: (text, title) => this.notifications.success(title, text),
          error: (text, title) => this.notifications.error(title, text),
          info: (text, title) => this.notifications.info(title, text),
          warning: (text, title) => this.notifications.warning(title, text)
        }
      },
      clipboard: {
        set: (elements: DmsObject[], action: ClipboardAction) => this.clipboard.set(elements, action),
        get: () => this.clipboard.get(),
        clear: () => this.clipboard.clear()
      },
      // Agent
      agent: {
        getAvailability: () => this.getAgentAvailability(),
        executeAction: (action, args) => this.executeAgentAction(action, args),
        action: this.agentAction
      }
    };
  }


  public getAgentAvailability(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.agentService.isConnected) {
        resolve(this.agentService.agentAvailability.CONNECTED);
      } else {
        resolve(this.agentService.agentAvailability.UNAVAILABLE);
      }
    });
  }

  public executeAgentAction(action, args) {

    switch (action) {
      case this.agentAction.CLIPBOARD:
        this.agentService.copyToClipboard(args);
        break;
      case this.agentAction.CLIPBOARD_AS_PDF:
        this.agentService.copyToClipboard(args);
        break;
      case this.agentAction.EXTERNAL:
        if (!args.executable) {
          this.logger.error('Missing field args.executable');
        } else if ((args.args && args.args.length === 0) && !Array.isArray(args.args)) {
          this.logger.error('Field args.args must be empty or array.');
        } else {
          this.agentService.sendExternalAction(args.executable, args.args || []);
        }
        break;
      case this.agentAction.MAIL:
      case this.agentAction.MAIL_AS_PDF:
        this.agentService.sendAsMail(args);
        break;
      case this.agentAction.OPEN:
        this.agentService.openDocument(args, args.lock);
        break;
      default:
        break;
    }
  }

  public get(uri, base = '', options?: HttpOptions) {
    return this.backend
      .get(uri, this.backend.getHost() + base, options || {observe: 'response'}).pipe(
        map((res: any) => {
          return options ? res : { status: res.status, data: res.body };
        })).toPromise();
  }

  public put(uri, data, base = '', options?: HttpOptions) {
    return this.backend.put(uri, data, this.backend.getHost() + base, options).toPromise();
  }

  public post(uri, data, base = '', options?: HttpOptions) {
    return this.backend.post(uri, data, this.backend.getHost() + base, options).toPromise();
  }

  public del(uri, base = '', options?: HttpOptions) {
    return this.backend.del(uri, this.backend.getHost() + base, options).toPromise();
  }

  public getCurrentUser(): EoUser {
    return this.user;
  }

  public encodeFileName(filename) {
    return Utils.encodeFileName(filename);
  }

  public getConfig() {
    return {
      serviceBase: this.backend.getServiceBase()

      // todo: do we have to provide the plugin developers with these informations?
      // after speaking to Andreas also removed from documentation
      // pluginsBase: $eoConfig.getPluginBase(),
      // pluginStatesBase: $eoConfig.getPluginStatesBase(),
      // themeBase: $eoConfig.getThemeBase()
    };
  }

  /**
   * fetches dms objects from the server that match the given params
   *
   * @param fields - the fields to matched. example: {name: 'max', plz: '47111}
   * @param type - the target object type
   *
   * @returns which will be resolved by an array of DmsObjects matching the given params
   */
  public getResult(fields, type): Promise<DmsObject[]> {

    let q = Object.keys(fields).filter(k => (fields[k] !== undefined && fields[k] !== null)).map(k => k + '=' + fields[k]).join(';');

    return this.backend
      .getJson(`/result/query?query=${encodeURIComponent(q)}&type=${type}`)
      .toPromise()
      .then((response: any) => {
        let resObjects = [];
        for (let item of response) {
          resObjects.push(new DmsObject(item));
        }
        return Promise.resolve(resObjects);
      })
      .catch(this.handleError);
  }

  /**
   * Loads a DMS object from the backend.
   *
   * @param id - The id of the DMS-Object to be fetched.
   * @param [type] - The object type of the selected DMS-Object. Will improve performance if set.
   * @param [version] - retrieve a specific version of the dms object
   * @param [intent] - used for server side logging. If you provide an intent, the
   *
   * @returns which will be resolved by the DMS object fetched from the server
   */
  public getDmsObject(id, type, version, intent?): Promise<DmsObject> {

    return this.dmsService.getDmsObject(id, type, version, intent)
      .toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
