import {AfterViewInit, Component, HostBinding, Input, OnDestroy, Renderer2, ViewChild} from '@angular/core';
import {
  Config,
  DmsObject,
  DmsObjectAttachment,
  DmsService,
  ObjectType,
  SystemService,
  TranslateService,
} from '@eo-sdk/core';
import {PopoutTriggerPosition, PopoutWindowConfig} from '@yuuvis/components/popout';
import {fromEvent, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FileSizePipe} from '../../eo-framework-core/pipes/filesize.pipe';
import {PluginsService} from './../../eo-framework-core/api/plugins.service';
import {ContentPreviewService} from './content-preview.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

export interface ViewerConfig {
  mimeType?: string | string[];
  fileExtension?: string | string[];
  viewer: string;
  error?: boolean;
  type?: 'compare' | 'extend' | 'error' | 'default';
}
export interface PreviewFile {
  uri: string;
  uriPdf: string;
  mimetype: string;
  mimegroup: string;
  size: number;
  path?: string;
  fileExtension?: string;
  mediaTypeName?: string;
}
@UntilDestroy()
@Component({
  selector: 'eo-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  providers: [ContentPreviewService]
})
export class MediaComponent implements AfterViewInit, OnDestroy {

  static VIDEO_VIEWER = 'viewer/view/api/video/?path=${path}&mimeType=${mimeType}&fileExtension=${fileExtension}&lang=${lang}&theme=${theme}';
  static PDF_VIEWER = 'viewer/view/api/pdf/web/viewer.html?file=&path=${path}&pathPdf=${pathPdf}&mimeType=${mimeType}&fileExtension=${fileExtension}&lang=${lang}&theme=${theme}&css=%23toolbarViewerRight%20%23print%7Bdisplay%3Ablock!important%3B%7D';

  private SIZE_LIMIT = 1024 * 1024 * 10; // 10MB
  private pdfjs: any;
  latestUri: string;
  slideUri: string;
  viewer: any;
  dmsObjectTitle: string;
  private _previewUri: string;
  private _dmsObject: DmsObject;
  fileSizePipe: FileSizePipe;
  currentIndex: number;
  attachments: DmsObjectAttachment[] = [];
  attachmentsLoading: Subscription;
  attachmentsShown: boolean;
  searchTerm: string;
  mediaType: ObjectType;
  customConfig: any[] = [];

  popoutTriggerPosition = PopoutTriggerPosition.BOTTOM_RIGHT
  popoutWindowConfig: PopoutWindowConfig;




  defaultViewers = [
    {
      "mimeType": ["application/json", "text/json", "text/plain", "text/xml", "text/java", "text/javascript", "application/javascript", "text/html", "text/markdown", "text/x-web-markdown", "text/x-markdown"],
      "viewer": "viewer/view/api/monaco/?path=${path}&mimeType=${mimeType}&fileExtension=${fileExtension}&lang=${lang}&theme=${theme}"
    },
    {
      "mimeType": ["audio/mp3", "audio/webm", "audio/ogg", "audio/mpeg", "video/mp4", "video/webm", "video/ogg", "application/ogg"],
      "viewer": "viewer/view/api/video/?path=${path}&mimeType=${mimeType}&fileExtension=${fileExtension}&lang=${lang}&theme=${theme}"
    },
    {
      "mimeType": ["image/tiff", "image/jpeg", "image/png", "image/apng", "image/gif", "image/svg+xml", "image/webp"],
      "viewer": "viewer/view/api/img/?path=${path}&mimeType=${mimeType}&fileExtension=${fileExtension}&lang=${lang}&theme=${theme}"
    },
    {
      "mimeType": ["message/rfc822", "application/vnd.ms-outlook"],
      "viewer": "viewer/view/api/mail/?path=${path}&mimeType=${mimeType}&fileExtension=${fileExtension}&lang=${lang}&theme=${theme}"
    },
    // We need another pathPdf for email attachment files. This will only come in place if the mimetype is pdf or one of the not supported ones.
    {"viewer": "() => {var attachment = parameters.pathPdf.match(new RegExp('attachment=(\\\\d)')); if (attachment) {parameters.pathPdf = location.origin + '/rest-ws/service/dms/' + dmsObject.content.id + '/attachments/' + attachment[1]} return parameters.defaultViewer;}"},
    {"error": true, "type": "error", "viewer": "viewer/view/api/error/?path=${path}&mimeType=${mimeType}&fileExtension=${fileExtension}&lang=${lang}&theme=${theme}"}
  ];

  isUndocked: boolean;
  loading: boolean;

  @Input() undockDisabled = false;

  @Input() enableCloseBtn = false;
  // by default, the viewer ignores the version of the dms object. This will always show the latest version.
  // We need this to be able to refresh the viewer because otherwise pdfjs worker will fail.
  @Input() useVersion = false;
  @HostBinding('class.open') open;

  @ViewChild('iframe', {static: true}) iframe;

  @Input('attachments')
  set setAttachments(attachments) {
    this.attachments = attachments;
    this.attachmentsShown = false;
  }

  @Input('searchTerm')
  set setSearchTerm(searchTerm) {
    this.searchTerm = searchTerm || '';
  }

  @Input()
  set previewUri(uri: string) {
    this._previewUri = uri;
    this.toggleViewer();
  }

  get previewUri() {
    return this._previewUri;
  }

  @Input()
  set previewFile(file: PreviewFile) {
    if (file) {
      this.viewer = this.getViewer(file);
      this.mediaType = file.mediaTypeName && this.system.getObjectType(file.mediaTypeName);
      this.previewUri = this.viewer.previewUri;
    } else {
      this.previewUri = '';
      this.popoutWindowConfig = undefined;
    }
    this._setPopoutWindowConfig(!file);
  }

  @Input()
  set dmsObject(item: DmsObject) {
    this._dmsObject = item;
    this.dmsObjectTitle = item.title || '...';


    this.setAttachments = [];
    if (this.attachmentsLoading) {
      // cancel any pending requests
      this.attachmentsLoading.unsubscribe();
    }
    if (item.content && item.content.contents && item.content.contents.length) {
      const file = item.content.contents[0] || {};

      this.previewFile = {
        uri: this.dms.getPreview(item.content.id, item.content.type, this.useVersion ? item.version : null, undefined, true),
        uriPdf: this.dms.getPreview(item.content.id, item.content.type, this.useVersion ? item.version : null, 'PDF', true),
        mimetype: file.mimetype,
        mimegroup: file.mimegroup,
        size: file.size,
        path: file.path,
        mediaTypeName: item.typeName
      };

      if (!!file.mimegroup?.match(/^mail|octet-stream/) && this.viewer.viewer.startsWith(MediaComponent.PDF_VIEWER)) {
        this.slideUri = this.dms.getSlide(item.content.id, item.content.type, this.useVersion ? item.version : null, 256);
        this.attachmentsLoading = this.dms.getAttachments(item).subscribe(a => this.setAttachments = a);
      }

    } else {
      this.previewUri = '';
    }
  }

  constructor(public translate: TranslateService,
    private dms: DmsService,
    private renderer: Renderer2,
    private system: SystemService,
    private config: Config,
    private pluginsService: PluginsService) {
    this.SIZE_LIMIT = this.config.getRaw('preview.fileSizeLimit') || this.SIZE_LIMIT;
    this.customConfig = (this.config.getRaw('preview.viewers') || []).concat(this.defaultViewers);

    this.pluginsService.api.content.catchError().subscribe((evt: any) => {
      const {err, win, parameters} = evt.data;
      const uri = this.pluginsService.applyFunction(this.customConfig?.find((v) => v.error)?.viewer, 'api, err, win, parameters', [
        this.pluginsService.api,
        err,
        win,
        parameters
      ]);
      uri && win && (win.location.href = this.resolveUri(uri, parameters));
    });
  }

  private _setPopoutWindowConfig(reset?: boolean) {
    const winInset = 20;
    this.popoutWindowConfig = !reset ? {
      title: this.dmsObjectTitle,
      position: {
        blockStart: winInset,
        inlineStart: window.screen.width / 2
      }, size: {
        width: window.screen.width / 2 - winInset,
        height: window.screen.height - winInset * 2,
      }
    } : undefined;
  }

  selectAttachment(attachment?: any) {
    const previewUri = attachment && this.getViewer({uriPdf: attachment.previewUri} as PreviewFile).previewUri;
    this.loadDocument(previewUri || this.previewUri, true);
  }

  public toggleViewer() {
    this.open = !!this.previewUri;
    if (!!this.previewUri) {
      this.loadDocument(this.previewUri);
    }
  }

  public refresh() {
    this.loadDocument(this.previewUri, true);
  }

  getViewer(file: PreviewFile): any {
    const isVideo = !!file.mimegroup?.match(/^audio|^video/);
    const defaultViewer = isVideo ? MediaComponent.VIDEO_VIEWER : MediaComponent.PDF_VIEWER;
    const fileExtension = file.fileExtension || (file.path?.includes('.') ? file.path.split('.').pop() : '');
    const mimeType = file.mimetype;
    const config = this.customConfig.find((c: ViewerConfig) => {
      const matchMT = !c.mimeType || (typeof c.mimeType === 'string' ? [c.mimeType] : c.mimeType).includes(mimeType?.toLowerCase());
      const matchFE = !c.fileExtension || (typeof c.fileExtension === 'string' ? [c.fileExtension] : c.fileExtension).includes((fileExtension).toLowerCase());
      return matchMT && matchFE && (!c.type || c.type === 'default');
    });
    const parameters = {
      file,
      path: location.origin + file.uri,
      pathPdf: location.origin + file.uriPdf,
      viewer: config?.viewer || defaultViewer,
      load: config?.load,
      fileExtension,
      mimeType,
      theme: '',
      defaultViewer,
      previewUri: ''
    };
    parameters.viewer = this.pluginsService.applyFunction(parameters.viewer, 'component, dmsObject, parameters', [this, this._dmsObject, parameters]) || defaultViewer;

    const extend = this.customConfig.find((c) => c.type === 'extend');
    if (extend) parameters.viewer = this.pluginsService.applyFunction(extend.viewer, 'component, dmsObject, parameters', [this, this._dmsObject, parameters]);

    parameters.previewUri = this.resolveUri(parameters.viewer, parameters);
    return parameters;
  }

  private resolveUri(viewer: string, parameters: any) {
    let origin = window.location.origin + this.config.getRaw('uri.contextPath');
    if (viewer.startsWith('http')) {
      origin = '';
    }

    return origin + viewer
      .replace('${originalPath}', parameters.path)
      .replace('${path}', encodeURIComponent(parameters.path))
      .replace('${pathPdf}', encodeURIComponent(parameters.pathPdf))
      .replace('${fileExtension}', encodeURIComponent(parameters.fileExtension))
      .replace('${mimeType}', encodeURIComponent(parameters.mimeType))
      .replace('${theme}', encodeURIComponent(parameters.theme))
      .replace('${lang}', ContentPreviewService.mapLang(this.translate.currentLang));
  }

  loadDocument(uri: string, force?: boolean) {
    if (!force && (!uri || (uri === this.latestUri))) {
      return false;
    }
    this.latestUri = uri || '';

    if (this.previewUri) {
      this.renderer.setAttribute(this.iframe.nativeElement, 'src', this.latestUri);
      const onload = () => this.pluginsService.applyFunction(this.viewer?.load, 'iframe, component', [this.iframe.nativeElement, this]);
      this.iframeInit(this.iframe.nativeElement, this.viewer?.load && onload);
    } else {
      this.renderer.setAttribute(this.iframe.nativeElement, 'src', this.latestUri);
      const onload = () => this.pluginsService.applyFunction(this.viewer?.load, 'iframe, component', [this.iframe.nativeElement, this]);
      this.iframeInit(this.iframe.nativeElement, this.viewer?.load && onload);
    }
  }

  /**
   * Custom search inside PDF.JS based on search term
   * @param term search term
   * @param win iframe window
   */
  private searchPDF(term = '', win: any) {
    // remove all special characters
    term = (term || '').replace(/[\"|\*]/g, '').trim();
    if (term && win?.PDFViewerApplication?.appConfig?.findBar) {
      win.PDFViewerApplication.appConfig.findBar.findField.value = term;
      win.PDFViewerApplication.appConfig.findBar.highlightAllCheckbox.checked = true;
      win.PDFViewerApplication.appConfig.findBar.caseSensitiveCheckbox.checked = false;
      // trigger find event on pdf.js load
      win.PDFViewerApplication.initializedPromise?.then(() => win.PDFViewerApplication.findBar.dispatchEvent());
    }
  }

  private preventDropEvent(win: any) {
    if (win?.location?.origin === window.location.origin) {
      win?.document?.addEventListener('drop', (e) => e.stopPropagation());
      // dispach drag & drop events to main window
      win?.document?.addEventListener('dragenter', (e) => {
        window.document.dispatchEvent(new DragEvent('dragenter', e));
        setTimeout(() => window.document.dispatchEvent(new DragEvent('dragleave', e)), 10);
      });
    }
  }

  iframeInit(iframe = this.iframe, onload?: Function) {
    if (iframe) {
      this.loading = true;
      iframe._init || fromEvent(iframe, 'load')
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          const win = this.setApi(iframe);
          onload && onload();
          this.loading = false;
          setTimeout(() => {
            this.searchPDF(this.searchTerm, win);
            this.preventDropEvent(win);
          }, 100);
        });
      iframe._init = !!this.setApi(iframe);
    }
  }

  private setApi(iframe: any) {
    // set api to iframe window
    const win = iframe?.contentWindow || iframe;
    win['api'] = window['api'];
    if (win['api']?.content) {
      win['api'].content.resolveViewerParams = (parameters: any) => {
        const uri = parameters.path.replace(location.origin, '');
        const viewer = this.getViewer({uri, mimetype: parameters.mimeType, fileExtension: parameters.fileExtension, uriPdf: uri, mimegroup: '', size: 1});
        return {uri: viewer.previewUri || parameters.path};
      };
    }
    return win;
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

}
