import {Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@eo-sdk/core';
import {DatepickerLabels} from '@yuuvis/components/datepicker';
import {Subscription} from 'rxjs';
import {LocaleDatePipe} from '../../../eo-framework-core/pipes/locale-date.pipe';

@Component({
  selector: 'eo-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true,
    }
  ],
})
export class DateComponent implements ControlValueAccessor, OnDestroy {

  @Input() withTime: boolean = false;
  @Input() readonly: boolean = false;

  private _sub: Subscription;
  private datePipe: LocaleDatePipe;
  value: Date | null = null;
  labels?: DatepickerLabels;
  locale: string = this.translate.currentLang;

  constructor(private translate: TranslateService) {
    this.datePipe = new LocaleDatePipe(translate);
    this._setLabels(this.locale)
    this._sub = this.translate.onLangChange.subscribe({
      next: (lang) => {
        this.locale = lang.lang;
        this._setLabels(this.locale)
      }
    })
  }

  propagateChange = (_: any) => { };
  validationChange = () => { };

  writeValue(value: any): void {
    this.value = value || null;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.validationChange = fn;
  }

  registerOnTouched(fn: () => void): void { }

  onValueChange(e) {
    this.propagateChange(e);
  }

  formatDate(value: Date) {
    return !value ? null :
      this.withTime ? value.toISOString().replace(':00.000', '') : this.datePipe.transform(value, 'yyyy-MM-dd');
  }

  private _setLabels(iso: string) {
    this.labels = {
      calendarApply: this.translate.instant('eo.datepicker.calendar.select'),
      calendarCancel: this.translate.instant('eo.datepicker.calendar.cancel'),
      shortcut: {today: this.translate.instant('eo.datepicker.calendar.today'), }
    }
  }

  ngOnDestroy(): void {
    this._sub!.unsubscribe();
  }
}
