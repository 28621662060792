<ng-container>
  <div class="fade"></div>
  <div class="dashboard" [style.background-image]="backgroundImage">
    <div class="footerButton">
      <eo-icon id="dashboardButton" [iconSrc]="toolbarIconSrc" [iconTitle]="toolbarIconTooltip | translate"></eo-icon>
    </div>
    <yuv-widget-grid-workspaces *ngIf="workspaceConfig" [workspaceConfig]="workspaceConfig"
      (configChange)="onWorkspacesConfigChange($event)" [options]="workspaceOptions">
    </yuv-widget-grid-workspaces>
  </div>
</ng-container>