<div class="form-element" [formGroup]="element" *ngIf="element">

  <div class="form-field t-{{formElementRef._eoFormElement.type}}" #formField
    [ngClass]="{disabled: !!formElementRef._eoFormElement.readonly}" *ngIf="elementTemplate">

    <ng-container *ngTemplateOutlet="elementTemplate; context: {element, formElementRef}"></ng-container>

    <!-- the fields description -->
    <div class="description" *ngIf="formElementRef._eoFormElement.description">
      {{formElementRef._eoFormElement.description}}</div>

    <!-- error/validation messages -->
    <div class="err-msg" *ngIf="formElementRef.errors">

      <div *ngIf="formElementRef.errors.daterange" translate>eo.form.property.daterange.invalid</div>
      <div *ngIf="formElementRef.errors.numberrange" translate>eo.form.property.numberrange.invalid</div>
      <div *ngIf="formElementRef.errors.number" translate>eo.form.property.number</div>
      <div *ngIf="formElementRef.errors.precision" translate
        [translateParams]="formElementRef.errors.precision.translateValues">
        {{formElementRef.errors.precision.translateKey}}</div>
      <div *ngIf="formElementRef.errors.scale" translate
        [translateParams]="formElementRef.errors.scale.translateValues">{{formElementRef.errors.scale.translateKey}}
      </div>

      <div *ngIf="formElementRef.errors.regex || formElementRef.errors.pattern" translate>
        eo.form.property.string.regex.nomatch</div>
      <div *ngIf="formElementRef.errors.classificationemail" translate>
        eo.form.property.string.error.classification.email</div>
      <div *ngIf="formElementRef.errors.classificationurl" translate>eo.form.property.string.error.classification.url
      </div>
      <div *ngIf="formElementRef.errors.onlyWhitespaces" translate>eo.form.property.string.error.whitespaces</div>
      <div *ngIf="formElementRef.errors.datecontrol" translate>eo.form.property.date.invalid</div>
      <div *ngIf="formElementRef.errors.codesystem" translate>eo.form.property.codesystem.value.invalid</div>
      <div *ngIf="formElementRef.errors.eoformScript">{{formElementRef._eoFormElement.error.msg}}</div>
      <div *ngIf="formElementRef.errors.required" translate>eo.form.property.required</div>
      <div *ngIf="formElementRef.errors.maxlength" translate
        [translateParams]="{maxlength: formElementRef._eoFormElement.maxlen}">eo.form.property.maxlength</div>
      <div *ngIf="formElementRef.errors.minlength" translate
        [translateParams]="{minlength: formElementRef._eoFormElement.minlen}">eo.form.property.minlength</div>
      <div *ngIf="formElementRef.errors.deletedIDReference" translate>eo.form.property.idreference.deleted</div>
      <div *ngIf="formElementRef.errors.deletedOrgObject" translate>eo.form.property.orgobject.deleted</div>
    </div>

  </div>



  <!-- STRING -->
  <ng-template eoFormElementTemplate propertyType="STRING" let-element="element" let-formElementRef="formElementRef">
    <eo-form-input [tag]="tag" [label]="formElementRef._eoFormElement.label" [isNull]="isNull"
      [skipToggle]="skipToggle || situation !== 'SEARCH'" (onToggleLabel)="labelToggled($event)"
      [required]="formElementRef._eoFormElement.required" [disabled]="formElementRef._eoFormElement.readonly"
      [invalid]="formElementRef.invalid">

      <eo-string
        *ngIf="!formElementRef._eoFormElement.reference && formElementRef._eoFormElement.classification !== 'selector'"
        [situation]="element._eoFormControlWrapper.situation"
        [formControlName]="element._eoFormControlWrapper.controlName"
        [autocomplete]="formElementRef._eoFormElement.autocomplete"
        [multiselect]="!!formElementRef._eoFormElement.multiselect"
        [multiline]="formElementRef._eoFormElement.multiline"
        [classification]="formElementRef._eoFormElement.classification" [regex]="formElementRef._eoFormElement.regex"
        [qname]="formElementRef._eoFormElement.qname" [size]="formElementRef._eoFormElement.size"
        [readonly]="formElementRef._eoFormElement.readonly" [minLength]="formElementRef._eoFormElement.minlen"
        [maxLength]="formElementRef._eoFormElement.maxlen"></eo-string>

      <!-- Dynamic List -->
      <eo-dynamic-list
        *ngIf="!formElementRef._eoFormElement.reference && formElementRef._eoFormElement.classification === 'selector'"
        [situation]="element._eoFormControlWrapper.situation" [pickerTitle]="formElementRef._eoFormElement.label"
        [filterFunction]="formElementRef._eoFormElement.filterFunction"
        [multiselect]="formElementRef._eoFormElement.multiselect" [listObject]="formElementRef._eoFormElement.list"
        [readonly]="formElementRef._eoFormElement.readonly"
        [formControlName]="element._eoFormControlWrapper.controlName"></eo-dynamic-list>


      <!-- Reference -->
      <eo-reference *ngIf="formElementRef._eoFormElement.reference"
        [formControlName]="element._eoFormControlWrapper.controlName"
        [readonly]="formElementRef._eoFormElement.readonly" [reference]="formElementRef._eoFormElement.reference"
        [multiselect]="!!formElementRef._eoFormElement.multiselect ? formElementRef._eoFormElement.multiselect : situation === 'SEARCH'"
        [minLength]="formElementRef._eoFormElement.minlen"
        [maxLength]="formElementRef._eoFormElement.maxlen"></eo-reference>
    </eo-form-input>
  </ng-template>

  <!-- ID-Reference -->
  <ng-template eoFormElementTemplate propertyType="REFERENCE" let-element="element" let-formElementRef="formElementRef">
    <eo-form-input [tag]="tag" [label]="formElementRef._eoFormElement.label" [isNull]="isNull"
      [skipToggle]="skipToggle || situation !== 'SEARCH'" (onToggleLabel)="labelToggled($event)"
      [required]="formElementRef._eoFormElement.required" [disabled]="formElementRef._eoFormElement.readonly"
      [invalid]="formElementRef.invalid">

      <eo-id-reference [formControlName]="element._eoFormControlWrapper.controlName"
        [situation]="element._eoFormControlWrapper.situation"
        [dataToRender]="formElementRef._eoFormElement.dataToRender"
        [reference]="formElementRef._eoFormElement.reference" [readonly]="formElementRef._eoFormElement.readonly"
        [multiselect]="formElementRef._eoFormElement.multiselect" [contextId]="formElementRef._eoFormElement.contextId"
        [exceptionIDs]="formElementRef._eoFormElement.exceptionIDs"
        [queryFilters]="formElementRef._eoFormElement.queryFilters"
        [objectTypesFilter]="formElementRef._eoFormElement.objectTypesFilter"></eo-id-reference>
    </eo-form-input>
  </ng-template>

  <!-- ID -->
  <ng-template eoFormElementTemplate propertyType="ID" let-element="element" let-formElementRef="formElementRef">
    <eo-form-input [tag]="tag" [label]="formElementRef._eoFormElement.label" [isNull]="isNull"
      [skipToggle]="skipToggle || situation !== 'SEARCH'" (onToggleLabel)="labelToggled($event)"
      [required]="formElementRef._eoFormElement.required" [disabled]="formElementRef._eoFormElement.readonly"
      [invalid]="formElementRef.invalid">

      <eo-string [situation]="element._eoFormControlWrapper.situation"
        [formControlName]="element._eoFormControlWrapper.controlName"
        [autocomplete]="formElementRef._eoFormElement.autocomplete"
        [multiselect]="formElementRef._eoFormElement.multiselect" [regex]="'^[0-9a-fA-F]*$'"
        [qname]="formElementRef._eoFormElement.qname" [readonly]="formElementRef._eoFormElement.readonly"
        [minLength]="32" [maxLength]="32"></eo-string>

    </eo-form-input>
  </ng-template>


  <!-- BOOLEAN -->
  <ng-template eoFormElementTemplate propertyType="BOOLEAN" let-element="element" let-formElementRef="formElementRef">
    <eo-form-input [tag]="tag" class="checkbox" [label]="formElementRef._eoFormElement.label" [isNull]="isNull"
      [skipToggle]="skipToggle || situation !== 'SEARCH'" [required]="formElementRef._eoFormElement.required"
      [disabled]="formElementRef._eoFormElement.readonly" (onToggleLabel)="labelToggled($event)"
      [invalid]="formElementRef.invalid">

      <eo-checkbox [formControlName]="element._eoFormControlWrapper.controlName"
        [required]="formElementRef._eoFormElement.required" [readonly]="formElementRef._eoFormElement.readonly"
        [tristate]="true"></eo-checkbox>
    </eo-form-input>
  </ng-template>

  <!-- CODESYSTEM -->
  <ng-template eoFormElementTemplate propertyType="CODESYSTEM" let-element="element"
    let-formElementRef="formElementRef">
    <eo-form-input [tag]="tag" [label]="formElementRef._eoFormElement.label" [isNull]="isNull"
      [skipToggle]="skipToggle || situation !== 'SEARCH'" (onToggleLabel)="labelToggled($event)"
      [required]="formElementRef._eoFormElement.required" [disabled]="formElementRef._eoFormElement.readonly"
      [invalid]="formElementRef.invalid">

      <eo-codesystem [situation]="element._eoFormControlWrapper.situation"
        [pickerTitle]="formElementRef._eoFormElement.label" [readonly]="formElementRef._eoFormElement.readonly"
        [codesystem]="formElementRef._eoFormElement.codesystem"
        [multiselect]="formElementRef._eoFormElement.multiselect"
        [filterFunction]="formElementRef._eoFormElement.filterFunction"
        [disablingFilterFunction]="formElementRef._eoFormElement.disablingFilterFunction"
        [formControlName]="element._eoFormControlWrapper.controlName"></eo-codesystem>
    </eo-form-input>
  </ng-template>

  <!-- ORGANIZATION -->
  <ng-template eoFormElementTemplate propertyType="ORGANIZATION" let-element="element"
    let-formElementRef="formElementRef">
    <eo-form-input [tag]="tag" [label]="formElementRef._eoFormElement.label" [isNull]="isNull"
      [skipToggle]="skipToggle || situation !== 'SEARCH'" (onToggleLabel)="labelToggled($event)"
      [required]="formElementRef._eoFormElement.required" [disabled]="formElementRef._eoFormElement.readonly"
      [invalid]="formElementRef.invalid">

      <eo-organization [situation]="element._eoFormControlWrapper.situation"
        [dataMeta]="formElementRef._eoFormElement.dataMeta" [readonly]="formElementRef._eoFormElement.readonly"
        [filterObject]="formElementRef._eoFormElement.filter" [multiselect]="formElementRef._eoFormElement.multiselect"
        (onDataMetaChanged)="organizationDataMetaResolved($event)"
        [formControlName]="element._eoFormControlWrapper.controlName"></eo-organization>
    </eo-form-input>
  </ng-template>

  <!-- NUMBER -->
  <ng-template eoFormElementTemplate propertyType="NUMBER" let-element="element" let-formElementRef="formElementRef">
    <eo-form-input [tag]="tag" [label]="formElementRef._eoFormElement.label" [isNull]="isNull"
      [skipToggle]="skipToggle || situation !== 'SEARCH'" (onToggleLabel)="labelToggled($event)"
      [required]="formElementRef._eoFormElement.required" [disabled]="formElementRef._eoFormElement.readonly"
      [invalid]="formElementRef.invalid">

      <eo-number *ngIf="situation !== 'SEARCH'" [readonly]="formElementRef._eoFormElement.readonly"
        [precision]="formElementRef._eoFormElement.precision" [scale]="formElementRef._eoFormElement.scale"
        [grouping]="formElementRef._eoFormElement.grouping" [pattern]="formElementRef._eoFormElement.pattern"
        [formControlName]="element._eoFormControlWrapper.controlName">
      </eo-number>

      <eo-number-range *ngIf="situation === 'SEARCH'" [readonly]="formElementRef._eoFormElement.readonly"
        [precision]="formElementRef._eoFormElement.precision" [scale]="formElementRef._eoFormElement.scale"
        [grouping]="formElementRef._eoFormElement.grouping" [pattern]="formElementRef._eoFormElement.pattern"
        [formControlName]="element._eoFormControlWrapper.controlName"></eo-number-range>
    </eo-form-input>
  </ng-template>

  <!-- DATE AND DATETIME -->
  <ng-template eoFormElementTemplate propertyType="DATETIME" let-element="element" let-formElementRef="formElementRef">
    <eo-form-input [tag]="tag" [label]="formElementRef._eoFormElement.label" [isNull]="isNull"
      [skipToggle]="skipToggle || situation !== 'SEARCH'" (onToggleLabel)="labelToggled($event)"
      [required]="formElementRef._eoFormElement.required" [disabled]="formElementRef._eoFormElement.readonly"
      [invalid]="formElementRef.invalid">

      <eo-date *ngIf="situation !== 'SEARCH'" [withTime]="formElementRef._eoFormElement.withtime"
        [readonly]="formElementRef._eoFormElement.readonly"
        [formControlName]="element._eoFormControlWrapper.controlName"></eo-date>

      <eo-datetime-range *ngIf="situation === 'SEARCH'" [pickerTitle]="formElementRef._eoFormElement.label"
        [withTime]="formElementRef._eoFormElement.withtime"
        [formControlName]="element._eoFormControlWrapper.controlName"></eo-datetime-range>
    </eo-form-input>
  </ng-template>

  <ng-content></ng-content>

  <!-- print out the type if it's not matching anything -->
  <ng-template #tplUnknown><b>{{formElementRef._eoFormElement.type}}</b></ng-template>
</div>