import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {ValidatorFn, Validators} from '@angular/forms';
import {Logger, RangeValue, SearchFilter, SearchService, SystemService, Utils} from '@eo-sdk/core';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';

import {PluginsService} from '../../eo-framework-core/api/plugins.service';
import {ReferenceService} from '../../eo-framework-core/references/reference.service';
import {ObjectFormOptions} from './object-form-options.interface';
import {FormValidation} from './object-form/form-validation/form-validation';
import {ObjectFormControl} from './object-form/object-form-control';
import {ObjectFormControlWrapper} from './object-form/object-form-control-wrapper';
import {ObjectFormGroup} from './object-form/object-form-group.model';
import {ObjectFormScriptService} from './object-form/object-form-script/object-form-script.service';
import {ObjectFormScriptingScope} from './object-form/object-form-script/object-form-scripting-scope';
import {ObjectFormModel} from './object-form/object-form.model';

export interface FormGenScope {
  gCount: number;
  formOptions: ObjectFormOptions;
  isInnerTableForm: boolean;
  initialValidators: {[name: string]: ValidatorFn | null};
  formGenResult: FormGenResult;
}
export interface FormGenResult {
  // Form controls generated from the model as 'Quick access object'
  // Form elements name is key and the value is the form control itself
  formControls: {[name: string]: ObjectFormControl};
  formModel: any,
  // Subscriptions to value changes of the generated form controls
  // You should always unsubscribe from all of them when consuming
  // component is destroyed
  controlValueSubscriptions: Subscription[];
  scriptingScope: ObjectFormScriptingScope;
  scriptModel: {[name: string]: any};
  form: ObjectFormGroup;
}

@Injectable()
export class ObjectFormHelperService {

  private datePipe = new DatePipe('en');

  constructor(private logger: Logger,
    private referenceService: ReferenceService,
    private searchService: SearchService,
    private pluginService: PluginsService,
    private systemService: SystemService) {
    this._clearTemporaryStorageEntries();
  }

  /**
   * Generates a reactive form based on a form model that may also include form scripts.
   * @param formOptions `ObjectFormOptions` defining the form
   * @param formScriptService Instance of a `ObjectFormScriptService`. This service is supposed to be
   * scoped to the component rendering the form (providers array in component decorator).
   * @param isInnerTableForm whether or not the form is part of another form (as is with table edit forms)
   * @returns An `FormGenResult` object
   */
  buildReactiveForm(
    formOptions: ObjectFormOptions,
    formScriptService: ObjectFormScriptService,
    isInnerTableForm?: boolean): FormGenResult {

    const _scope: FormGenScope = {
      gCount: 0,
      formOptions,
      isInnerTableForm,
      initialValidators: {},
      formGenResult: {
        formControls: {},
        formModel: {},
        controlValueSubscriptions: [],
        scriptingScope: undefined,
        scriptModel: {},
        form: null
      }
    }

    const formModel = this._dataToForm(_scope, formOptions.formModel, formOptions.data);
    _scope.formGenResult.formModel = formModel;
    if (!formModel) return;

    // if a script is available, we'll init the form scripting for the
    // current form
    let scriptingScope: ObjectFormScriptingScope;
    if (_scope.isInnerTableForm || (formModel.script && formModel.script.length > 0)) {
      this.logger.debug('adding form scripting scope');
      scriptingScope = new ObjectFormScriptingScope(
        formModel.situation,
        this._getScriptingModelChanged(_scope),
        this.pluginService.getApi(),
        _scope.isInnerTableForm
      );
      scriptingScope.objectId = formOptions.objectId;
    }
    _scope.formGenResult.scriptingScope = scriptingScope;


    let form = new ObjectFormGroup({});
    if (formModel.elements[0] && formModel.elements[0].elements) {
      this._addFormControl(_scope, form, formModel.elements[0], 'core');
    }
    if (formModel.elements[1] && formModel.elements[1].elements) {
      this._addFormControl(_scope, form, formModel.elements[1], 'data');
    }
    _scope.formGenResult.form = form;
    this._initValidators(_scope.formGenResult.form, _scope);
    this._initScriptingScope(_scope, formScriptService);
    return _scope.formGenResult;
  }



  /**
   * Converts a form element object to an ObjectFormControlWrapper which then can be used to
   * render a from control. Result can be used as input for FormElementComponent.
   *
   * @param element - the element object or a json string
   * @param situation - optional property to set up a form situation for the control (default is EDIT)
   * @return the converted ObjectFormControlWrapper or null in case of an error
   */
  elementToFormControl(element: any, situation?: string): ObjectFormControlWrapper {

    let formElement;
    if (typeof element === 'string') {
      try {
        formElement = JSON.parse(element);
      } catch (e) {
        this.logger.error('Unable to parse form element from json ', formElement);
      }
    } else {
      formElement = element;
    }

    if (!formElement) {
      return null;
    }

    // Create the ObjectFormControlWrapper
    let wrapper = new ObjectFormControlWrapper({});
    let formSituation = situation ? situation : 'EDIT';

    wrapper._eoFormControlWrapper = {
      controlName: formElement.name,
      situation: formSituation
    };

    // for codesystem elements add entries if not yet provided
    if (formElement.type === 'CODESYSTEM' && (!formElement.codesystem || !formElement.codesystem.entries)) {
      formElement.codesystem = this.systemService.getCodesystem(formElement.codesystem.id);
    }
    // create the actual form control
    let controlDisabled = !!formElement.readonly;
    let formControl = new ObjectFormControl({
      value: formElement.value,
      disabled: controlDisabled
    }, FormValidation.getValidators(formElement, formSituation));

    // Form elements in SEARCH situation may arrive with a value set to NULL (explicit search for
    // fields that are NOT set). In that case we need to prepare the form control
    if (formSituation === 'SEARCH' && formElement.value === null) {
      formElement.isNotSetValue = true;
    }

    formControl._eoFormElement = formElement;
    wrapper.addControl(formElement.name, formControl);

    return wrapper;
  }

  /**
   * Extract data from an object form based on situation.
   *
   * @param form - form to extract data from
   * @param situation - form situation
   * @param [initialData] - optional form data to match the current values against
   * This is required for editig indexdata (EDIT situation), because we have to compare new values
   * against the initial values. If a property that is contained in the forms initial data is removed
   * (e.g. set to null) then we have to set this null value, because otherwise the server would
   * ignore the changes.
   * @param isTableRowEditForm Flag indicating that the provided form is an inline form used by
   * a forms table element for editing rows. Those need a special handling.
   * @return extracted data as object
   */
  extractFormData(form: ObjectFormGroup, situation: string, initialData?: any, isTableRowEditForm?: boolean) {
    let extractedData = {};
    this.getElementValues(extractedData, form, situation || 'EDIT', initialData, isTableRowEditForm);
    return extractedData;
  }

  // Recursive method to get the values from each form control.
  private getElementValues(data: any, formControl: ObjectFormModel, situation: string, initialData?: any, isTableRowEditForm?: boolean) {

    if (!formControl || !formControl.controls) {
      return;
    }

    if (formControl instanceof ObjectFormControlWrapper) {

      const fc: any = formControl.controls[formControl._eoFormControlWrapper.controlName];

      if (fc._eoFormElement.isNotSetValue === true) {
        // form elements may explicitly set to have NULL value (e.g. from Search form if values are requested that have no values)
        if (isTableRowEditForm) {
          this.setDataValue(fc._eoFormElement.name, null, data, fc._eoFormElement, isTableRowEditForm);
        } else {
          this.setDataValue(fc._eoFormElement.qname, null, data, fc._eoFormElement, isTableRowEditForm);
        }

      } else if (fc.value !== undefined) {
        let val = fc.value;

        // make sure that meta data are also up to date
        if (fc._eoFormElement.type === 'CODESYSTEM') {
          const cs = this.systemService.getCodesystem(fc._eoFormElement.codesystem.id);
          fc._eoFormElement.dataMeta = cs.entries.find(e => e.data === val);
        }

        switch (situation) {
          case 'SEARCH': {
            if (val !== null) {
              // row editing forms use the name instead of the qname because otherwise the
              // tables grid isn't able to map the fields
              if (!isTableRowEditForm) {
                this.setDataValue(fc._eoFormElement.qname, val, data, fc._eoFormElement, isTableRowEditForm)
              } else {
                this.setDataValue(fc._eoFormElement.name, val, data, fc._eoFormElement, isTableRowEditForm)
              }
            }
            break;
          }
          case 'CREATE': {
            // also add NULL values (override existing defaultvalues)
            this.setDataValue(fc._eoFormElement.name, val, data, fc._eoFormElement, isTableRowEditForm)
            break;
          }
          case 'EDIT': {
            // in edit situation we have to compare new values against the initial values
            // If a property that is contained in the forms initial data is removed (e.g. set to null)
            // then we have to set this null value, because otherwise the server will ignore the changes
            if (val !== null || (initialData && initialData[fc._eoFormElement.name] !== undefined)) {
              // data[fc._eoFormElement.name] = val;
              this.setDataValue(fc._eoFormElement.name, val, data, fc._eoFormElement, isTableRowEditForm)
            }
            break;
          }
        }
      }
    } else {
      Object.keys(formControl.controls)
        .forEach(controlKey => {
          let formControlKeyed = <ObjectFormGroup>formControl.controls[controlKey];
          this.getElementValues(data, formControlKeyed, situation, initialData, isTableRowEditForm);
        });
    }
  }

  private setDataValue(key, value, data, formElement, isTableRowEditForm) {
    data[key] = this.formatValue(value, formElement);

    if (isTableRowEditForm && formElement.dataMeta) {
      // inner tables need to set up the meta data as well
      data[key + '_meta'] = formElement.dataMeta;
    }
  }

  formatValue(value, formElement) {
    if (formElement.type === 'DATETIME' && !formElement.withtime) {
      if (typeof value === 'string' || (value instanceof Date && !isNaN(value.getTime()))) {
        return this.datePipe.transform(value, 'yyyy-MM-dd');
      } else if (value instanceof RangeValue) {
        return new RangeValue(
          value.operator,
          value.firstValue && this.datePipe.transform(value.firstValue, 'yyyy-MM-dd'),
          value.secondValue && this.datePipe.transform(value.secondValue, 'yyyy-MM-dd')
        );
      }
    }

    if (formElement.type === 'TABLE') {
      (formElement.elements || []).forEach(el => {
        (value || []).forEach(v => v[el.name] = this.formatValue(v[el.name], el));
      });
    }
    return value;
  }


  // Functions involved in building an object form

  /**
   * Recursive method adding a new UntypedFormControl (group or control) to a parent form group
   *
   * @param parentGroup - the parent group to add the control to
   * @param formElement - the enaio form model element to create the child control from
   * @param [useName] - use this name instead of the one from the model
   */
  private _addFormControl(scope: FormGenScope, parentGroup: ObjectFormGroup, formElement: any, useName?: string) {

    let ctrl;
    let name;

    // add a form group
    if (formElement.type === 'o2mGroup' || formElement.type === 'o2mGroupStack') {

      // do not add groups that are empty
      if (!formElement.elements || formElement.elements.length === 0) {
        this.logger.error('Found empty form group', formElement);
        return;
      }

      ctrl = new ObjectFormGroup({});
      ctrl._eoFormGroup = {
        label: formElement.label,
        layout: formElement.layout,
        type: formElement.type
      };

      if (useName === 'core' || useName === 'data') {
        ctrl._eoFormGroup.label = useName;
      }

      for (let e of formElement.elements) {
        this._addFormControl(scope, ctrl, e);
      }
      name = useName || 'fg' + scope.gCount++;
      ctrl._eoFormGroup.id = `${scope.formOptions.layoutSettingsID || scope.formOptions.formModel.name}.${name}`;
    } else {
      // add form control
      // To be able to integrate recursive form controls into the main form,
      // we have to wrap them in a form group
      ctrl = new ObjectFormControlWrapper({});
      ctrl._eoFormControlWrapper = {
        // the name of the wrapped UntypedFormControl
        controlName: formElement.name,
        situation: scope.formOptions.formModel.situation
      };
      if (scope.formOptions.formModel.situation === 'SEARCH' && formElement.value === null) {
        formElement.isNotSetValue = true;
      }
      // do not set a reference as the form controls value
      // otherwise we could not reset the form
      let value: any;
      if (Array.isArray(formElement.value)) {
        // copy by value for arrays of objects (e.g. table data)
        value = [];
        formElement.value.forEach((o) => {
          value.push(Utils.formDataParse(Utils.formDataStringify(o)));
        });
      } else {
        value = formElement.value;
      }

      // for codesystem elements add entries if not yet provided
      if (formElement.type === 'CODESYSTEM' && !formElement.codesystem.entries) {
        formElement.codesystem = this.systemService.getCodesystem(formElement.codesystem.id);
      }

      // create the actual form control
      let controlDisabled = scope.formOptions.disabled || !!formElement.readonly;
      let formControl = new ObjectFormControl({
        value: value,
        disabled: controlDisabled
      });

      formElement.readonly = controlDisabled;

      formControl._eoFormElement = formElement;
      scope.formGenResult.formControls[formElement.name] = formControl;

      if (formElement.type === 'CODESYSTEM' || (formElement.type === 'STRING' && formElement.classification === 'selector')) {
        formControl._eoFormElement.applyFilter = (func: Function) => {
          formControl._eoFormElement.filterFunction = func;
        };
        formControl._eoFormElement.applyDisablingFilter = (func: Function) => {
          formControl._eoFormElement.disablingFilterFunction = func;
        };
      }

      if (formElement.type === 'STRING' && formElement.classification === 'selector') {
        formControl._eoFormElement.setList = (listObject: any) => {
          formControl._eoFormElement.list = listObject;
        };
      }

      if (formElement.type === 'ORGANIZATION') {
        formControl._eoFormElement.setFilter = (filterObject: any) => {
          formControl._eoFormElement.filter = filterObject;
        };
      }

      if (formElement.type === 'TABLE') {
        formControl._eoFormElement.object = scope.formOptions.object;
      }

      if (formElement.type === 'REFERENCE') {
        formControl._eoFormElement.setQueryFilters = (queryFiltersObject: {[fieldQname: string]: {o: string, v1: any, v2: any}}, objectTypes?: string[]) => {
          formControl._eoFormElement.queryFilters = queryFiltersObject;
          formControl._eoFormElement.objectTypesFilter = objectTypes;
        };
      }

      if (scope.formOptions.formModel.situation === 'SEARCH') {
        // in search situation even readonly fields should be editable ...
        formControl._eoFormElement.readonly = false;
        // ... and required makes no sense here
        formControl._eoFormElement.required = false;
      }

      // remove empty descriptions
      let desc = formControl._eoFormElement.description;
      if (desc && desc.trim().length === 0) {
        formControl._eoFormElement.description = null;
      }

      // add the form element to the script model that will be injected into
      // the forms scripting scope later on
      scope.formGenResult.scriptModel[formElement.name] = formControl._eoFormElement;

      // apply change listener to the form control, that will trigger
      // the form elements onChange listener
      let controlWatch = ctrl.valueChanges.pipe(debounceTime(formElement.type === 'TABLE' ? 0 : 500));
      controlWatch.subscribe((v) => {
        if (formControl._eoFormElement.type === 'REFERENCE') {
          this._getDataMeta(formControl._eoFormElement, v[formControl._eoFormElement.name]).subscribe(m => {
            if (m) {
              formControl._eoFormElement.dataMeta = formControl._eoFormElement.multiselect ? m : m[0];
            } else {
              delete formControl._eoFormElement.dataMeta;
            }
          });
        }
        if (scope.formGenResult.scriptingScope) {
          scope.formGenResult.scriptingScope.modelChanged(v);
        }
      });
      scope.formGenResult.controlValueSubscriptions.push(controlWatch);

      ctrl.addControl(formElement.name, formControl);
      name = 'fg_' + formElement.name;
    }
    parentGroup.addControl(name, ctrl);
  }

  /**
   * Merge data into a form model.
   *
   * @param model - form model
   * @param data - data object or array of SearchFilter objects in case of a search form
   */
  private _dataToForm(scope: FormGenScope, model: any, data: any) {
    if (model && data) {
      this._setElementValues(scope, model.elements, data);
    }
    return model;
  }

  // recursive method for adding values to model elements
  private _setElementValues(scope: FormGenScope, elements, data) {

    elements.forEach(element => {
      if (this._hasValue(data, element)) {

        element.value = this._getValue(scope, data, element);
        if (element.value) {
          // add meta data for some of the types
          this._fetchMetaData(scope, data, element);
        }
      } else {
        delete element.value;
      }
      if (element.type !== 'TABLE' && element.elements && element.elements.length > 0) {
        this._setElementValues(scope, element.elements, data);
      }
    });
  }

  // in some cases required meta data may not be available on the element itself
  // so they have to be fetched and added to the element for the form to be able
  // to render the element correctly
  private _fetchMetaData(scope: FormGenScope, data, element) {

    if (scope.formOptions.formModel.situation === 'SEARCH') {
      // todo: how to fetch meta data in search situation
    } else {
      if (element.type === 'ORGANIZATION' && data[element.name + '_meta']) {
        element.dataMeta = data[element.name + '_meta'];
      } else if (element.type === 'CODESYSTEM' && data[element.name + '_meta']) {
        element.dataMeta = data[element.name + '_meta'];
        element.defaultrepresentation = data[element.name + '_meta'].defaultrepresentation;
      } else if (element.type === 'REFERENCE' && data[element.name + '_meta']) {
        element.dataMeta = data[element.name + '_meta'];
      }
    }
  }

  private _getDataMeta(formElement: any, newValue: any): Observable<any> {

    if (newValue) {
      switch (formElement.type) {
        case 'ORGANIZATION': {
          return this.systemService.getOrganizationObject(newValue);
        }
        case 'REFERENCE': {
          return this.referenceService.fetchIDReferenceMetaData(Array.isArray(newValue) ? newValue : [newValue]);
        }
        case 'CODESYSTEM': {
          return of(this.systemService.getCodesystem(formElement.codesystem.id).entries.find((entry) => {
            return entry.defaultrepresentation === newValue;
          }));
        }
      }
    }
    return of(null);
  }

  private _hasValue(data, element) {

    // differ between array of SearchFilters and a form data object
    if (Array.isArray(data)) {
      if (element.type === 'TABLE') {
        return !!(data.find((filter) => filter.property.startsWith(element.qname)));
      } else {
        return !!(data.find((filter) => filter.property === element.qname));
      }
    } else {
      return data.hasOwnProperty(element.name);
    }
  }

  private _getValue(scope: FormGenScope, data, element) {

    let value;
    if (scope.formOptions.formModel.situation === 'SEARCH') {
      if (scope.isInnerTableForm) {
        if (element.type === 'DATETIME' || element.type === 'NUMBER') {
          value = SearchService.toRangeValue(data[element.name]);
        } else {
          value = data[element.name];
        }
      } else if (element.type === 'TABLE') {
        value = this.searchService.tableFiltersToElementValue(this._getTableFilters(data, element.qname), element.elements);
      } else {
        const filter = data.find(f => f.property === element.qname);
        value = this.searchService.filterToElementValue(filter, element.type);
      }
    } else {

      if (element.type === 'DATETIME' && data[element.name]) {
        value = new Date(`${data[element.name]}${element.withtime ? '' : 'T00:00:00'}`);
      } else {
        value = data[element.name];
      }
    }
    return value;
  }

  private _getTableFilters(filters: SearchFilter[], propertyName: string): SearchFilter[] {
    return filters.filter(f => f.property.startsWith(propertyName + '.'));
  }

  private _initValidators(formControl: ObjectFormGroup, scope: FormGenScope) {
    if (formControl) {
      for (let key in formControl.controls) {
        const control = formControl.controls[key] as any;
        if (control.controls) {
          this._initValidators(control, scope);
        } else {
          scope.initialValidators[control._eoFormElement.name] = control.validator;
          control.setValidators(Validators.compose(
            this._getValidators(scope, control._eoFormElement).concat(
              [scope.initialValidators[control._eoFormElement.name]])
          ));
          control.updateValueAndValidity();
        }
      }
    }
  }

  /**
  * Build validators for the given form element to be attached to
  * a reactive formControl.
  *
  * @param formElement - form element object
  */
  private _getValidators(scope: FormGenScope, formElement: any): ValidatorFn[] {
    let elmValidators = FormValidation.getValidators(formElement, scope.formOptions.formModel.situation);
    // add custom validator for script enabled forms
    if (scope.formGenResult.scriptingScope) {
      elmValidators.push(FormValidation.customScriptingValidation);
    }

    return elmValidators;
  }

  private _initScriptingScope(scope: FormGenScope, formScriptService: ObjectFormScriptService) {
    let {data, actions, objects, context} = <ObjectFormOptions>(scope.formOptions || {});

    if (scope.formGenResult.scriptingScope) {
      scope.formGenResult.scriptingScope.setModel(scope.formGenResult.scriptModel);
      /** provide access to actions (used inside of BPM-Forms) */
      scope.formGenResult.scriptingScope.actions = actions;
      /** provide access to additional objects (used for example in BPM-Start-Forms to
       * add data of DMS-Objects to start the process for)
       */
      scope.formGenResult.scriptingScope.objects = objects;
      scope.formGenResult.scriptingScope.context = context;
      /** provide readonly access to initial form data (which may also contain values that
       * are not rendered as form elements (invisible values))
       */
      scope.formGenResult.scriptingScope.data = data;

      /** by default, scripting scopes are applied to forms. But table elements create their own scope
       * for editing rows. Being one of those inner forms should not run the form script again, but
       * instead just provide the observing abilities of the scripting scope.
       */
      if (!scope.formOptions.disabled && !scope.isInnerTableForm && scope.formGenResult.formModel) {
        const scriptName = scope.formGenResult.formModel.name + '_' + scope.formGenResult.formModel.situation;
        this.logger.debug('executing form script ' + scriptName);
        formScriptService.runFormScript(
          scope.formGenResult.scriptingScope,
          scope.formGenResult.formModel.script,
          scriptName
        );
      }
    }
  }

  /**
     * This method will be called each time the script changes its internal model.
     * It is used to transfer the script changes to the actual form model.
     *
     * To ensure the right context, we define an instance method as callback for the scripting scope
     * @see: https://blog.johnnyreilly.com/2014/04/typescript-instance-methods.html
     *
     * @param formControlName
     * @param change
     */

  private _getScriptingModelChanged(scope: FormGenScope): Function {
    return (formControlName, change) => {

      // find the target control
      let fc: ObjectFormControl = scope.formGenResult.formControls[formControlName] as ObjectFormControl;
      if (fc) {

        // change only allowed properties
        switch (change.name) {
          case 'value': {
            if (Array.isArray(change.newValue)) {
              this._processArrayValueChange(fc, change);
            } else {
              fc._eoFormElement.value = change.newValue;
              if (fc.value !== change.newValue) {
                fc.patchValue(change.newValue);
                fc.updateValueAndValidity();
                fc.markAsDirty();
              }
            }
            scope.formGenResult.form.markAsDirty();
            break;
          }
          case 'required': {
            if (fc._eoFormElement.required !== change.newValue) {
              fc._eoFormElement.required = change.newValue;
              // apply new validators
              // @see: https://scotch.io/tutorials/how-to-implement-conditional-validation-in-angular-2-model-driven-forms
              fc.setValidators(Validators.compose(this._getValidators(scope, fc._eoFormElement).concat([scope.initialValidators[fc._eoFormElement.name]])));
              // need to mark form control as touched because otherwise form validation will not show
              // error messages
              fc.markAsTouched();
              fc.updateValueAndValidity();
            }
            break;
          }
          case 'readonly': {
            fc._eoFormElement.readonly = change.newValue;
            if (change.newValue === true) {
              fc.disable();
            } else {
              fc.enable();
            }
            break;
          }
          case 'error': {
            if (JSON.stringify(fc._eoFormElement.error) !== JSON.stringify(change.newValue)) {
              fc._eoFormElement.error = change.newValue;
              fc.markAsTouched();
              fc.updateValueAndValidity();
            }
            break;
          }
          // new onrowedit function was applied by the script
          case 'onrowedit': {
            fc._eoFormElement.onrowedit = change.newValue;
            break;
          }
          // new onchange function was applied by the script
          case 'onchange': {
            fc._eoFormElement.onchange = change.newValue;
            break;
          }
          // new contextId was applied by the script
          case 'contextId': {
            fc._eoFormElement.contextId = change.newValue;
            break;
          }
        }
      }
    }
  }

  private _processArrayValueChange(fc, change) {
    const newVal = change.newValue;
    const targetType = fc._eoFormElement.type;
    // for some types we have to ensure that meta data are provided as well
    switch (targetType) {
      case 'ORGANIZATION': {
        this._getDataMeta(fc._eoFormElement, newVal).subscribe(m => {
          fc._eoFormElement.dataMeta = m;
        });
        break;
      }
      case 'CODESYSTEM': {
        if (!fc._eoFormElement.codesystem.entries) {
          fc._eoFormElement.codesystem = this.systemService.getCodesystem(fc._eoFormElement.codesystem.id);
        }
        break;
      }
      case 'TABLE': {
        const dataToBeProcessed = {};
        fc._eoFormElement.elements.forEach(e => {
          if (e.type === 'ORGANIZATION' || e.type === 'CODESYSTEM') {
            dataToBeProcessed[e.name] = e;
          }
        });
        if (Object.keys(dataToBeProcessed).length) {
          let obs = [];
          newVal.forEach(rowData => {
            Object.keys(rowData).forEach(key => {
              if (dataToBeProcessed[key]) {
                obs.push(this._getDataMeta(dataToBeProcessed[key], rowData[key]).pipe(
                  tap(m => {
                    if (m) {
                      rowData[key + '_meta'] = m;
                    } else {
                      delete rowData[key + '_meta'];
                    }
                  })
                ));
              }
            });
          });
          forkJoin(obs).subscribe(() => this._updateArrayValue(fc, newVal));
        }
        break;
      }
    }
    this._updateArrayValue(fc, newVal);
  }

  private _updateArrayValue(fc, newValue) {
    fc._eoFormElement.value = [].concat(newValue);
    fc.patchValue([].concat(newValue));
    fc.updateValueAndValidity();
    fc.markAsDirty();
  }

  private TEMP_STORAGE_ENTRIES_KEY = 'eo.cmp.objectform.tmpstorage.entries';

  /**
   * Add a reference to a temporary localstorage entry that should be cleared when a 
   * new browser session starts. In context of forms this will be used to cleanup the 
   * layout state of forms (selected tabs etc.) that are stored locally but should only
   * be pesisted for one browser session.
   * @param storageKey Key of the localstorage item
   */
  addTemporaryStorageEntry(storageKey: string) {
    // entries will themselves be stored in localstorage so we can check for entries
    // to be removed once the application starts
    const current = this._getTemporaryStorageEntries();
    if(!current.includes(storageKey))
    localStorage.setItem(this.TEMP_STORAGE_ENTRIES_KEY, JSON.stringify([...current, storageKey]))
  }

  private _clearTemporaryStorageEntries() {
    this._getTemporaryStorageEntries().forEach(e => localStorage.removeItem(e));
    localStorage.removeItem(this.TEMP_STORAGE_ENTRIES_KEY);
  }

  private _getTemporaryStorageEntries(): string[] {
    let res = [];
    try {
      const entries: string[] = JSON.parse(localStorage.getItem(this.TEMP_STORAGE_ENTRIES_KEY));
      if (Array.isArray(entries)) res = entries;
    } catch (e) {
      console.debug(e)
    }
    console.debug(`Found ${res.length} tmp entries to be removed`);
    return res;
  }
}
