import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {CdkPortalOutlet, TemplatePortal} from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {EnaioEvent, EventService, Utils} from '@eo-sdk/core';
import {filter} from 'rxjs/operators';
import {PendingChangesService} from '../../../eo-framework-core/pending-changes/pending-changes.service';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'eo-dialog',
  templateUrl: './eo-dialog.component.html',
  styleUrls: ['./eo-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EoDialogComponent
  implements OnDestroy, AfterViewInit {

  @ViewChild(CdkPortalOutlet) portalOutlet: CdkPortalOutlet;
  @ViewChild('dialogContent') dialogContent: TemplateRef<any>;

  private overlayRef: OverlayRef;
  private ready: boolean;

  private _visible = false;
  private _lastFocused;
  private active: boolean;
  private id = Utils.uuid();
  private parentId = '';
  @Input() hasPreviewFile = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() styleClass = '';

  // array of pendingTask IDs to be checked before the dialog closes
  @Input() dirtyCheck: string | string[] = [];

  @Input() minWidth: number | string = 200;
  @Input() minHeight = 'auto';
  @Input() height: string;
  @Input() width: string;
  @Input() focusOnShow = true;
  @Input() align: 'center' | 'start' = 'center';
  @Input() isFormTable = false;
  @Input() showPreview = false;

  @Output() onTogglePreview: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();
  @Output() hide: EventEmitter<any> = new EventEmitter();
  @Output() show: EventEmitter<any> = new EventEmitter();

  @Input()
  get visible(): boolean {
    return this._visible;
  }

  set visible(val) {
    this._visible = !!val;
    this.visibleChange.emit(this._visible);
    this._visible ? this.show.emit(val) : this.hide.emit(val);
    this.toggleActive(this._visible);
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    // close active dialog on Escape event
    if (this.active) {
      this.closeDialog();
    }
  }

  @HostListener('document:mousedown', ['$event.target'])
  onMousedown(targetElement: HTMLElement) {
    // close active dialog on background/mask click
    if (this.active && targetElement.classList.contains('ui-dialog-mask')) {
      this.closeDialog();
    }
  }

  constructor(
    private eventService: EventService,
    private pendingChanges: PendingChangesService,
    private renderer: Renderer2,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) {
    this._lastFocused = document.activeElement;
    this.eventService
      .on(EnaioEvent.EO_DIALOG_STACK_CHANGED)
      .pipe(
        untilDestroyed(this),
        filter(
          evt =>
            (this.active || evt.data.id === this.parentId) &&
            evt.data.id !== this.id
        )
      )
      .subscribe((event: any) => {
        this.parentId = event.data.active ? event.data.id : '';
      });
  }

  private open() {
    // We create the overlay
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.getPositionStrategy(),
      minHeight: this.minHeight,
      minWidth: this.minWidth,
      height: this.height,
      width: this.width
    });
    //Then we create a portal to render a component
    const portal = new TemplatePortal(this.dialogContent, this.viewContainerRef);
    // We add a custom CSS class to our overlay
    // this.overlayRef.addPanelClass("example-overlay");
    this.overlayRef.attach(portal);
    this.overlayRef.backdropClick().subscribe((_) => {
      this.closeDialog();
    });
  }

  private getPositionStrategy() {
    let positionStrategy;
    if (this.align === 'center') {
      positionStrategy = this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically();
    } else if (this.align === 'start') {
      positionStrategy = this.overlay
        .position()
        .global()
        .left()
        .top();
    }
    return positionStrategy;
  }

  toggleActive(active: boolean, trigger = true) {
    this.active = !!active;

    if (active && this.ready && !this.overlayRef) {
      this.open();
    }
    if (this.overlayRef && !active) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }

    if (trigger) {
      this.eventService.trigger(EnaioEvent.EO_DIALOG_STACK_CHANGED, {
        id: this.id,
        active: this.active
      });
    }
  }

  closeDialog() {
    if (this.dirtyCheck) {
      if (!this.pendingChanges.checkForPendingTasks(this.dirtyCheck)) {
        this.visible = false;
      }
    } else {
      this.visible = false;
    }
  }

  togglePreview() {
    this.showPreview = !this.showPreview;
    this.onTogglePreview.emit();
  }

  ngOnDestroy() {
    if (this.active) {
      this.toggleActive(false);
    }
  }

  ngAfterViewInit(): void {
    this.ready = true;
    if (this._visible) {this.open();}
  }
}
