import {Component, Input, ViewChild, ViewChildren, QueryList} from '@angular/core';
import {PluginDirective} from './plugin.directive';
import {PluginService} from './plugin.service';
// import {TabPanelComponent} from '../tab-container/tab-panel/tab-panel.component';

@Component({
  selector: 'eo-plugin',
  template: `
    <ng-template eoPlugin></ng-template>`
})
export class PluginComponent {

  @ViewChild(PluginDirective, {static: true}) eoPlugin: PluginDirective;

  @Input()
  set type(type) {
    this.pluginService.loadByType(type, this.eoPlugin.viewContainerRef);
  }

  @Input()
  set id(id) {
    this.pluginService.loadById(id, this.eoPlugin.viewContainerRef);
  }

  constructor(public pluginService: PluginService) {
  }

}

@Component({
  selector: 'eo-tab-plugin',
  template: `
  <ng-template *ngFor="let t of types" [yvcTab]="{id: t.id, label: t.id | translate}">
      <eo-plugin [id]="t.id"></eo-plugin>
  </ng-template>`
})
export class TabPluginComponent {
  types: any[];

  // @ViewChildren(TabPanelComponent) tabPanels: QueryList<TabPanelComponent>;

  @Input()
  set type(type) {
    this.types = this.pluginService.loadTabs(type);
  }

  constructor(public pluginService: PluginService) {
  }

}

@Component({
  selector: 'eo-sidebar-plugin',
  template: `
    <div #navItem class="nav-item" *ngFor="let l of _links">
      <a [href]="l.path" *ngIf="l.path.startsWith('http');else link">{{l.id | translate}}</a>
      <ng-template #link>
        <a [routerLink]="l.path" [queryParams]="l.queryParams" routerLinkActive="active-link">{{l.id | translate}}</a>
      </ng-template>
    </div>`
})
export class SidebarPluginComponent {
  _links: any[];

  @ViewChildren('navItem') links: QueryList<any>;

  @Input()
  set type(type) {
    this._links = this.pluginService.loadLinks(type);
  }

  constructor(public pluginService: PluginService) {
  }

}
