
  <yvc-tabs *ngIf="modifiedByMe?.lately.length || createdByMe?.lately.length">
    <ng-template [yvcTab]="{id: 'LastEdited', label: 'eo.dashboard.modified.title' | translate}">
      <div class="lastEdits" *ngIf="modifiedByMe?.lately.length">

        <div class="body">

          <a class="item" *ngFor="let item of modifiedByMe.lately" [routerLink]="['/object/', item.id]">
            <eo-icon [objectType]="item.type"></eo-icon>
            <div>
              <div class="modified">{{item.modified | localeDate}}</div>
              <div class="title">{{item.title}}</div>
              <div class="description">{{item.description}}</div>
            </div>
          </a>

        </div>
      </div>
    </ng-template>
    <ng-template [yvcTab]="{id: 'LastCreated', label: 'eo.dashboard.created.title' | translate}">
      <div class="lastEdits" *ngIf="createdByMe?.lately.length">

        <div class="body">

          <a class="item" *ngFor="let item of createdByMe.lately" [routerLink]="['/object/', item.id]">
            <eo-icon [objectType]="item.type"></eo-icon>
            <div>
              <div class="modified">{{item.created | localeDate}}</div>
              <div class="title">{{item.title}}</div>
              <div class="description">{{item.description}}</div>
            </div>
          </a>

        </div>
      </div>


    </ng-template>
  </yvc-tabs>
