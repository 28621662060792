<div class="eo-id-reference">
  <yvc-autocomplete [(ngModel)]="innerValues" [minLength]="1" #autocomplete
    (suggestionSelect)="onAutoCompleteSelect($event)" (suggestionUnselect)="removeItem($event)"
    (blur)="onAutoCompleteBlur()" [autocompleteValues]="autocompleteRes" field="title" [disabled]="readonly"
    [forceSelection]="true" (autocompleteFnc)="autocompleteFn($event)" [multiple]="true">

    <!-- [styleClass]="!multiselect && innerValues.length === 1 ? 'xxx' : ''"> -->

    <ng-template #chipTemplate let-item>
      <a *ngIf="!item.state || item.state === 'OK'" class="link router-link"
        [routerLink]="['/object', item.id, {outlets: {modal: null}}]" [queryParams]="{type: referenceType.qname}">
        <svg focusable="false" class="ref-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
          viewBox="0 0 24 24">
          <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8
        13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z">
          </path>
        </svg>
      </a>
      <eo-icon [objectType]="referenceType" title="{{referenceType?.label}}" class="ref-type-icon" [ngClass]="{'deleted-reference-label': item.state && (item.state === 'RECYCLED' || item.state === 'GONE')}"></eo-icon>
      <span *ngIf="!(item.state && (item.state === 'RECYCLED' || item.state === 'GONE')); else deleted" class="label" eoRtlAware="full">
        <span *ngIf="item.title || referenceType?.label; else noAccess">
          {{item.title || referenceType?.label}}
        </span>
      </span>
      <ng-template #noAccess>
        <span class="label no-access-reference-label">{{('eo.references.noAccess' | translate)}}</span>
      </ng-template>
      <ng-template #deleted>
        <span class="label deleted-reference-label">{{('eo.references.deleted' | translate)}}</span>
      </ng-template>
    </ng-template>
  </yvc-autocomplete>

  <ng-template #notFound><span class="label">{{innerValues && innerValues.length ? ('eo.references.not.available' |
      translate) : ''}}</span></ng-template>

  <button #button class="ui-button" *ngIf="!readonly"
    title="{{('eo.references.search' | translate) + ' ' + tooltipTypeHint}}" [disabled]="selectionDisabled"
    (click)="showDialog()">
    <eo-icon [iconSrc]="'assets/_default/svg/ic_none.svg'"></eo-icon>
  </button>
</div>

<eo-dialog [title]="('eo.references.add' | translate)" [subtitle]="referenceType?.label" [(visible)]="visibleDialog"
  [minWidth]="577" [minHeight]="590" [styleClass]="'reference-field-dialog'" #dialog>

  <eo-reference-finder *ngIf="dialog.visible" [isDisabled]="isDisabled" [types]="referenceType.qname"
    [multiselect]="multiselect" [currentSelection]="innerValues" [clipboard]="clipboard" [contextId]="contextId"
    [exceptionIDs]="exceptionIDs" [queryFilters]="queryFilters" (addDmsObjects)="paste($event)">
  </eo-reference-finder>
</eo-dialog>
