import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IWidgetComponent} from '@yuuvis/widget-grid';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IHitlist} from '../IHitlist';
import {StoredQueriesService, StoredQuery, TranslateService, UserService, Utils} from '@eo-sdk/core';
import {skip} from 'rxjs';
import {tap} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'eo-hitlist-setup',
  templateUrl: './hitlist-setup.component.html',
  styleUrls: ['./hitlist-setup.component.scss']
})
export class HitlistSetupComponent implements IWidgetComponent, OnInit {
  private _widgetConfig: IHitlist;
  @Output() widgetConfigChange = new EventEmitter<IHitlist>();
  storedQueries: StoredQuery[];

  setupForm: UntypedFormGroup = this.fb.group({
    storedQuery: [null],
    size: [],
    countTile: [false],
    sortField: [],
    sortRevert: [],
  });

  storedQueriesPicker = {
    title: this.translate.instant('eo.search.title.storedqueries'),
    codesystem: null,
    value: null,
    parameter: null,
    types: null
  };

  sortFiledPicker = {
    title: this.translate.instant('eo.workspace.widget.form.sortField'),
    codesystem: null,
    value: null,
    parameter: null,
    types: null
  };
  selectedSavedSearchName = '';
  storedQuery: StoredQuery;

  @Input() set widgetConfig(wc: IHitlist) {
    this._widgetConfig = wc;
  }

  get widgetConfig() {
    return this._widgetConfig;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private storedQueriesService: StoredQueriesService,
    private translate: TranslateService,
    private userService: UserService
  ) {
    this.setupForm.valueChanges.pipe(skip(1), untilDestroyed(this)).subscribe((_) => {
      this.setWidgetConfig();
    });
    this.getStoredQuerys();
  }

  private setWidgetConfig() {
    if (this.setupForm.valid && this.storedQueries) {
      this.storedQuery = this.storedQueries.find((sq) => sq.name === this.setupForm.get('storedQuery').value);
      this.widgetConfig = {
        storedQuery: this.storedQuery?.id,
        formValue: this.setupForm.value,
      };
      if (this.setupForm.get('storedQuery').value !== this.selectedSavedSearchName) {
        this.selectedSavedSearchName = this.setupForm.get('storedQuery').value;
        if (this.widgetConfig?.storedQuery) {
          setTimeout(() => {
            this.sortFiledPicker.codesystem = null;
            if (this.storedQuery.types.length !== 1) {
              this.getSortFields('sysdocument');
            } else {
              this.getSortFields(this.storedQuery.types[0].qname);
            }
          }, 100);
        }
      }
      this.widgetConfigChange.emit(this._widgetConfig);
    }
  }

  getStoredQuerys() {
    this.storedQueriesService
      .fetchStoredQueries().pipe(
      untilDestroyed(this),
    ).subscribe(storedQueries => {
      this.storedQueries = storedQueries;
      this.storedQueriesPicker.codesystem = {
        entries: this.storedQueries.map(storedQuery => ({
          id: storedQuery.id,
          label: storedQuery.name,
          defaultrepresentation: storedQuery.name,
          data: storedQuery.name
        })).sort(Utils.sortValues('label')),
      };
    });
  }

  getStoredQuery(id: string) {
    return this.storedQueriesService.getStoredQuery(id).pipe(
      untilDestroyed(this),
      tap(storedQuery => {
        this.storedQuery = storedQuery;
      })
    );
  }

  getSortFields(type) {
    return this.userService.getFieldDefinition(type).pipe(untilDestroyed(this)).subscribe(definition => {
      this.sortFiledPicker.codesystem = {
        entries: definition.elements.map(field => ({
          id: field.name,
          defaultrepresentation: field.baseparam ? this.translate.instant('eo.global.baseparam.' + field.name) : field.label,
          data: field.name
        })).sort(Utils.sortValues('defaultrepresentation')),
      };
    });
  }

  ngOnInit() {
    if (this._widgetConfig?.storedQuery) {
      this.getStoredQuery(this._widgetConfig?.storedQuery).subscribe(() => {
        this.setupForm.patchValue({
          storedQuery: this.storedQuery ? this.storedQuery.name : '',
          size: this._widgetConfig ? this._widgetConfig.formValue.size : '5',
          countTile: this._widgetConfig ? this._widgetConfig.formValue.countTile : '',
          sortRevert: this._widgetConfig ? this._widgetConfig.formValue.sortRevert : '',
        });
        this.getSortFields(this.storedQuery?.types[0].qname);
        setTimeout(() => {
          this.setupForm.patchValue({
            sortField: this._widgetConfig ? this._widgetConfig.formValue.sortField : '',
          });
        }, 100);
      });
    } else {
      this.setupForm.patchValue({
        storedQuery: '',
        size: '5',
      });
    }
  }
}
