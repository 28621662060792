import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {
  BackendService,
  BaseParams,
  Capabilities,
  CapabilitiesService,
  Config,
  DmsObject,
  DmsParams,
  DmsService,
  EnaioEvent,
  EventService,
  SubscriptionMode,
  SystemService,
  TranslateService,
  UploadRegistryService,
  UploadTarget,
  Utils,
} from '@eo-sdk/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AgentService} from '../../eo-framework-core/agent/agent.service';
import {EmptyState} from '../../eo-framework-core/empty-state/empty-state.interface';
import {EmptyStateService} from '../../eo-framework-core/empty-state/empty-state.service';
import {LocaleDatePipe} from '../../eo-framework-core/pipes/locale-date.pipe';
import {SelectionConfig, SelectionService} from '../../eo-framework-core/selection/selection.service';
import {ActionService} from '../actions/action-service/action.service';
import {ContentPreviewService} from '../media/content-preview.service';
import {MediaComponent} from '../media/media.component';
import {GlobalShortcutComponent} from '../shortcuts/global-shortcut-component.interface';
import {Shortcut} from '../shortcuts/shortcut.interface';
import {IndexData} from '../ui/indexdata-summary/indexdata-summary.component';
import {Tabs} from '@yuuvis/components/tabs';
import {SplitTabsLayoutSettings} from '@yuuvis/components/split-tabs';

@UntilDestroy()
@Component({
  selector: 'eo-object-details',
  templateUrl: './object-details.component.html',
  styleUrls: ['./object-details.component.scss'],
  providers: [ContentPreviewService]
})
export class ObjectDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('viewer') preview: MediaComponent;
  @ViewChildren(Tabs) tabContainers: QueryList<Tabs>;

  // generate unique id to pass to upload registry
  private uploadTarget = new UploadTarget(Utils.uuid(), UploadTarget.OBJECT);
  item: DmsObject;
  indexDataPreview: IndexData;
  baseparams: BaseParams;
  hasError = false;
  lockTooltip: string;
  nodmsobject = false;
  showLoader = false;

  private _panelOrder = {'master': ['summary', 'indexdata', 'history', 'links', 'signature'], 'slave': ['preview']};
  set panelOrder(po: {master: string[], slave: string[]}) {
    this._panelOrder = po;
    this._checkCapabilities();
  }
  get panelOrder() {
    return this._panelOrder;
  }

  agentIsConnected$: Observable<boolean> = this.agentService.isConnected$;
  subscriptionIconTooltip: string;
  split: boolean = false;
  splitTabConfig: SplitTabsLayoutSettings;
  @HostBinding('class.reference') isReference: boolean;

  @Input() set reference(isRef: boolean) {
    this.isReference = isRef;
  }
  @Input() searchTerm: string;
  @Input() enableCompare = false;
  @Input() recyclebinTabs = false;
  @Input() enableDiff = true;
  @Input() enableSync = true;
  @Input() cacheLayout: any;
  item2: DmsObject;
  indexDataPreview2: IndexData;
  baseparams2: BaseParams;
  private _emptyState: EmptyState = {icon: 'ic_no-file.svg', text: '', className: ''};

  @Input()
  set emptyState(e: EmptyState) {
    this._emptyState = e || EmptyStateService.defaultEmptyState();
  }

  get emptyState() {
    return this._emptyState;
  }

  @Input('params')
  set dmsParams(params: DmsParams) {
    this.showLoader = true;
    this.loadDmsObject(params).subscribe((res: DmsObject) => {
      this.dmsObject = res;
      this.showLoader = false;
    },
      Utils.throw(() => this.onHasError(true, true, false))
    );
  }

  @Input('params2')
  set dmsParams2(params: DmsParams) {
    this.loadDmsObject(params)
      .subscribe((res: DmsObject) => {
        this.dmsObject2 = res;
      }, Utils.throw(() => this.onHasError(true, true, false)));
  }

  @Input('item')
  set dmsObject(v: DmsObject) {
    const isDifferent = this.item?.id !== v?.id;
    this.item = v;
    this.onHasError();
    // prepare indexdata preview
    this.onIndexDataSaved(null, this.item);
    this.eventService.trigger(EnaioEvent.DMS_OBJECT_LOADED, this.item);

    if (this.item) {
      if (isDifferent && this.item.isActiveVersion && !this.enableCompare) {
        this.uploadTarget.referenceObject = this.item;
        this.uploadTarget.onUploadSuccess = () => this.dmsParams = {id: v.id, type: v.typeName};
        this.uploadRegistry.register(this.uploadTarget);
      }

      if (this.item.lock) {
        this.lockTooltip = this.translate.instant('eo.object.lock') + ' '
          + (new LocaleDatePipe(this.translate).transform(this.item.lock.on))
          + ', ' + this.item.lock.by.label + ' (' + this.item.lock.by.name + ')';
      }
    } else {
      this.emptyState = this.empty.getEmptyState(0);
    }

    if (this.item?.subscriptions.length) {
      this.subscriptionIconTooltip = this.createSubscriptionIconTooltip(this.item);
    }
  }

  @Input('item2')
  set dmsObject2(v: DmsObject) {
    this.item2 = structuredClone(v);
    this.onHasError();
    this.onIndexDataSaved(null, this.item2);
  }

  capabilities: Capabilities;
  preventClickThrough = false;

  // shortcut actions
  shortcuts: GlobalShortcutComponent = {
    id: 'eo.object-details',
    label: this.translate.instant('eo.shortcuts.eo-object-details.title'),
    labelKey: 'eo.shortcuts.eo-object-details.title',
    actions: [
      {
        name: this.translate.instant('eo.shortcuts.action.open.context'),
        nameKey: 'eo.shortcuts.action.open.context',
        shortcut: new Shortcut('O', false, false),
        onExecuteAction: () => {
          this.openObject(this.item.id, this.item.typeName);
        },
        isHidden: () => {
          return false;
        }
      },
      {
        name: this.translate.instant('eo.object.details.actions.title'),
        nameKey: 'eo.object.details.actions.title',
        shortcut: new Shortcut('A', false, false),
        onExecuteAction: () => {
          this.showActions();
        },
        isHidden: () => {
          return false;
        }
      }
    ]
  };

  @Input() applySelection: SelectionConfig;
  @Input() parseDmsParams: (data) => {} = this.getDmsParams;
  @Output() hasContent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('attr.data-type') get dataType() {
    return this.item ? this.item.typeName : 'none';
  }

  // undockWinActive = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private system: SystemService,
    private empty: EmptyStateService,
    private agentService: AgentService,
    private backend: BackendService,
    private dmsService: DmsService,
    private selection: SelectionService,
    private actionService: ActionService,
    private capabilityService: CapabilitiesService,
    private uploadRegistry: UploadRegistryService,
    private eventService: EventService,
    private configService: Config,
    private translate: TranslateService) {

    this.panelOrder = this.configService.getRaw('objectDetailsTabs') || this.panelOrder;

    this.agentService.setAgentStatus(this.route.snapshot.queryParams['connectagent']).subscribe((hasStatus: boolean) => {
      if (hasStatus) {
        this.router.navigate([], {queryParams: {connectagent: null}, queryParamsHandling: 'merge'});
      }
    });
  }

  private getDmsParams(data: any): DmsParams {
    return data ? {...data, type: data.typeName || data.type} : null;
  }

  private updateContent() {

    if (this.applySelection) {
      if (this.enableCompare) {
        this.selection
          .find(this.applySelection.in)
          .selection$
          .pipe(
            untilDestroyed(this)
          )
          .subscribe((res: any[]) => {
            this.dmsParams = this.parseDmsParams(res[0]) as DmsParams;
            this.dmsParams2 = this.parseDmsParams(res[1]) as DmsParams;
          });
        return;
      } else {
        this.selection
          .find(this.applySelection.in)
          .focus$
          .pipe(
            untilDestroyed(this)
          )
          .subscribe(res => {
            this.showLoader = true;
            this.loadDmsObject(this.parseDmsParams(res) as DmsParams).subscribe((res: DmsObject) => {
              // refreshing object details may result in an updated version of the object
              // so we'll use this trigger to also emitt those changes to other listening components
              if (this.item && res && (this.item.id === res.id)) {
                this.eventService.trigger(EnaioEvent.DMS_OBJECT_UPDATED, res);
              }
              this.dmsObject = res;
              this.showLoader = false;
            },
              Utils.throw((error) => {
                this.uploadRegistry.unregister(this.uploadTarget.id);
                return this.onHasError(true, true, false);
              })
            );
          });

      }
    }
  }

  refreshContent() {
    if (!this.preventClickThrough) {
      this.preventClickThrough = true;
      this.updateContent();
    }
    setTimeout(() => {
      this.preventClickThrough = false;
    }, 5000);
  }

  private prepareIndexDataPreview(item: DmsObject, formData?: any) {
    const {id, data, type, created, modified, content, contentFileName, contentFileSize, version} = item;

    return this.system
      .getObjectTypeForm(type.name, 'EDIT')
      .pipe(
        map(form => {
          let indexDataPreview = {
            form,
            data: formData || data
          };
          let baseparams: BaseParams = {
            id,
            type: type.name,
            createdOn: new Date(created.on),
            modifiedOn: new Date(modified.on),
            createdBy: `${created.by.title} (${created.by.name})`,
            modifiedBy: `${modified.by.title} (${modified.by.name})`,
            contentFileName: contentFileName ? contentFileName : null,
            contentFileSize: contentFileSize ? Number(contentFileSize) : null,
            version: version || 0,
            mimeGroup: content ? content.contents ? content.contents[0].mimegroup : null : null,
            digest: content ? content.contents ? content.contents[0].digest : null : null,
            contentId: content ? content.contents ? content.id : null : null,
            mimeType: content ? content.contents ? content.contents[0].mimetype : null : null,
          };
          return {indexDataPreview, baseparams};
        }));
  }

  private onHasError(hasError = false, nodmsobject = false, showLoader = false) {
    this.hasError = hasError;
    this.nodmsobject = nodmsobject;
    this.showLoader = showLoader;
    this.hasContent.emit(!hasError);
  }

  loadDmsObject(params: DmsParams) {
    if (!this.enableCompare && params) {
      delete params.version;
    }
    // check for valid id
    return params?.id?.length === 32 ? this.dmsService.getDmsObjectByParams(params) : of(null);
  }

  downloadOriginalContent() {
    this.backend.downloadContent([this.item]);
  }

  showActions() {
    const actions = [this.item, this.item2].filter(v => v);
    this.actionService.showActions(actions, 'DMS_OBJECT');
  }

  openObject(id, _type?) {
    const queryParams: NavigationExtras = {queryParams: {'type': _type}};
    return this.router.navigate(['/object', id], queryParams);
  }

  // executed when the dms object changed due to saving its indexdata
  onIndexDataSaved(formData, item) {
    if (!this.item) {
      this.indexDataPreview = null;
      this.baseparams = null;
    }
    if (!this.item2) {
      this.indexDataPreview2 = null;
      this.baseparams2 = null;
    }
    if (item) {
      this.prepareIndexDataPreview(item, formData)
        .subscribe(data => {
          if (item === this.item) {
            this.indexDataPreview = data.indexDataPreview;
            this.baseparams = data.baseparams;
          } else {
            this.indexDataPreview2 = data.indexDataPreview;
            this.baseparams2 = data.baseparams;
          }
        });
    }
  }

  onCompareTabChange(event) {
    if (this.enableSync) {
      this.tabContainers.forEach(t => {
        if (t.selectedTab !== event) {
          t.selectById(event)
        }
      })
    }
  }

  get referenceTitle(): string {
    return this.item.contextFolder ? this.item.contextFolder.title : this.item.title;
  }

  isJournalObject(item) {
    return item.type.supertypes.find(sT => sT === 'sysjournalobject');
  }

  private createSubscriptionIconTooltip(item: DmsObject): string {
    let tooltip = '';
    const modes = this.item.subscriptions.map(subscription => subscription.mode);
    const subscribedToAllChanges = modes.includes(SubscriptionMode.DMS_OBJECT_CHANGED) || (modes.includes(SubscriptionMode.ONLY_DOCUMENT_CONTENT_CHANGED) && modes.includes(SubscriptionMode.ONLY_INDEX_DATA_CHANGED));
    const subscribedToMetaChangesOnly = modes.includes(SubscriptionMode.ONLY_INDEX_DATA_CHANGED) && !modes.includes(SubscriptionMode.ONLY_DOCUMENT_CONTENT_CHANGED) && !modes.includes(SubscriptionMode.DMS_OBJECT_CHANGED);
    const subscribedToContentChangesOnly = modes.includes(SubscriptionMode.ONLY_DOCUMENT_CONTENT_CHANGED) && !modes.includes(SubscriptionMode.ONLY_INDEX_DATA_CHANGED) && !modes.includes(SubscriptionMode.DMS_OBJECT_CHANGED);
    if (subscribedToAllChanges) {
      tooltip = this.translate.instant('eo.object.subscription.icon.tooltip.allChanges');
    }
    else if (subscribedToMetaChangesOnly) {
      tooltip = this.translate.instant('eo.object.subscription.icon.tooltip.meta');
    }
    else if (subscribedToContentChangesOnly) {
      tooltip = this.translate.instant('eo.object.subscription.icon.tooltip.content');
    }
    return tooltip;
  }

  private _checkCapabilities() {
    if (!this.capabilities) return;
    if (!this.capabilities.signing) {
      this._panelOrder.master = this._panelOrder?.master?.length ?
        this._panelOrder.master.filter(id => id !== 'signature') : [];

      this._panelOrder.slave = this._panelOrder?.slave?.length ?
        this._panelOrder.slave.filter(id => id !== 'signature') : [];
    }

    this.splitTabConfig = structuredClone({
      primary: this._panelOrder?.master?.length ? {tabs: this._panelOrder.master, active: this._panelOrder.master[0]} : undefined,
      secondary: this._panelOrder?.slave?.length ? {tabs: this._panelOrder.slave, active: this._panelOrder.slave[0]} : undefined,
    })
  }

  ngOnInit() {
    this.capabilities = this.capabilityService.getCapabilities();
    this._checkCapabilities();
    this.updateContent();

    this.eventService
      .on(EnaioEvent.DMS_OBJECT_UPDATED)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(event => {
        if (event.data && this.item && this.item.id === event.data.id) {
          this.dmsObject = this.dmsService.createLocalCopyOfDMSObject(event.data);
          if (this.preview) {
            this.preview.refresh();
          }
        }
      });

    this.eventService
      .on(EnaioEvent.INBOX_ITEM_CONFIRMED)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(event => {
        if (this.item && this.item.id === event.data.target.id) {
          this.dmsParams = null;
        }
      });
  }

  ngAfterViewInit() {
    // let panels = this.tabPanels.toArray();
    // this.tabPlugins.forEach(p => panels = panels.concat(p.tabPanels.toArray()));
    // this.externalPanels.reset(panels);
  }

  ngOnDestroy() {
    this.uploadRegistry.unregister(this.uploadTarget.id);
  }
}
