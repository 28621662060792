import {of as observableOf, Observable} from 'rxjs';
import {Component} from '@angular/core';
import {SignatureComponent} from './signature/signature.component';
import {DmsObjectTarget} from '../../action-target';
import {SelectionRange} from '../../selection-range.enum';
import {DmsObject, SigningService, TranslateService} from '@eo-sdk/core';


@Component({
  selector: 'eo-signature-action',
  template: ''
})
export class SignatureActionComponent extends DmsObjectTarget {

  label: string;
  description: string;
  priority = 2;
  iconSrc = 'assets/_default/svg/ic_signature.svg';
  group = 'common';
  range = SelectionRange.SINGLE_SELECT;
  extComponents = SignatureComponent;

  constructor(private translate: TranslateService, private signingService: SigningService) {
    super();
    this.label = this.translate.instant('eo.action.signature.label');
    this.description = this.translate.instant('eo.action.signature.description');
  }

  isExecutable(element: DmsObject): Observable<boolean> {
    return observableOf(!this.signingService.signatureRequested(element)
      && this.isAllowedMimeType(element)
      && this.isAllowedObjectType(element)
      && this.hasEditRights(element)
      && !this.isFinalized(element));
  }

  isAllowedMimeType(item: DmsObject): boolean {
    return item.content && (item.content.contents[0].mimetype === 'application/pdf'
      || item.content.contents[0].mimetype === 'application/msword'
      || item.content.contents[0].mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      || item.content.contents[0].mimetype === 'application/vnd.ms-excel'
      || item.content.contents[0].mimetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      || item.content.contents[0].mimetype === 'application/vnd.ms-powerpoint'
      || item.content.contents[0].mimetype === 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
  }

  isAllowedObjectType(item: DmsObject): boolean {
    return item.type.supertypes.includes('yuvsigning');
  }

  hasEditRights(item: DmsObject): boolean {
    return item.rights.edit;
  }

  isFinalized(item: DmsObject): boolean {
    return item.isFinalized;
  }
}
