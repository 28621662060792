import {Component, Input} from '@angular/core';
import {IWidgetComponent} from '@yuuvis/widget-grid';
import {HitlistCountTileResult, IHitlist} from './IHitlist';
import {SearchQuery, SearchService, StoredQueriesService, StoredQuery, SystemService, Utils} from '@eo-sdk/core';
import {AppSearchService} from '../../../../eo-framework-core/search/app-search.service';
import {map} from 'rxjs';
import {NavigationExtras} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {tap} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'eo-hitlist-widget',
  templateUrl: './hitlist-widget.component.html',
  styleUrls: ['./hitlist-widget.component.scss']
})
export class HitlistWidgetComponent implements IWidgetComponent {

  hitlistResult: [];
  private _query: SearchQuery;
  countTileResult: HitlistCountTileResult;
  refreshingRequest: boolean;
  private _widgetConfig: IHitlist;
  storedQuery: StoredQuery;

  constructor(private searchService: SearchService,
    private appSearchService: AppSearchService,
    private systemService: SystemService,
    private storedQueriesService: StoredQueriesService
  ) {}

  @Input() editMode = false;

  @Input() set widgetConfig(c: IHitlist) {
    this._widgetConfig = c;
    if (this._widgetConfig?.storedQuery) {
      this.getStoredQuery(this._widgetConfig.storedQuery).subscribe(() => {
        this._query = this.searchService.buildQuery(this.storedQuery);
        this._widgetConfig.formValue.storedQuery = this.storedQuery.name;
        this.executeQuery();
      });
    }
  }

  get widgetConfig() {
    return this._widgetConfig;
  }

  getStoredQuery(id: string) {
    return this.storedQueriesService.getStoredQuery(id).pipe(
      untilDestroyed(this),
      tap(storedQuery => {
        this.storedQuery = storedQuery;
      })
    );
  }

  private executeQuery() {
    this.refreshingRequest = true;
    if (this._widgetConfig?.formValue.countTile) {
      let count = new Map();
      this.appSearchService.setQuery(this._query);
      this.appSearchService.aggregate().valueOf();
      this.searchService.getSearchState({aggs: {type: {}}, ...this._query.getQueryJson()})
        .pipe(map(state => {
          return count = state.aggregations.type;
        }))
        .subscribe(() => {
          this.refreshingRequest = false;
          this.countTileResult = {count: Array.from(count.values()).reduce((acc, value) => acc + value, 0)};
        });
    } else {
      const queryName = this._query.name;
      delete this._query.name;
      this._query.fields = ['title', 'description', 'type', 'id', 'created'];
      if (this._widgetConfig?.formValue.sortField) {
        if (this._widgetConfig?.formValue.sortRevert) {
          this._query.addSortOption(this._widgetConfig?.formValue.sortField, 'desc');
        } else {
          this._query.addSortOption(this._widgetConfig?.formValue.sortField, 'asc');
        }
      }
      this.appSearchService.setQuery(this._query);
      this.appSearchService.aggregate();
      this.searchService
        .executeQuery(this._query.getQueryJson(), false, this._widgetConfig?.formValue.size)
        .pipe(map(result => {
          return result.hits.hits.map(hit => {
            return {
              'title': hit._source.title,
              'description': hit._source.description,
              'type': this.systemService.getObjectType(hit._source.type),
              'id': hit._source.id,
              'date': hit._source.created
            };
          });
        }))
        .subscribe((hits) => {
          this.refreshingRequest = false;
          this.hitlistResult = hits;
          this._query.name = queryName;
          this._query.removeSortOption(this._widgetConfig?.formValue.sortField);
        });
    }

  }

  refresh() {
    this.getStoredQuery(this._widgetConfig.storedQuery).subscribe(() => {
      this._query = this.searchService.buildQuery(this.storedQuery);
      this._widgetConfig.formValue.storedQuery = this.storedQuery.name;
      this.executeQuery();
    });
  }

  clearQuery(clearAfterExecute) {
    if (clearAfterExecute) {
      this.appSearchService.clearQuery();
    }
  }

  onItemClick(item, event) {
    const url = window.location.href.split('/dashboard')[0] + '/object/' + item.id + '?type=' + item.type.name + '\n';
    window.open(url, event?.ctrlKey || this.editMode ? '_blank' : '_self');
  }

  onEmitQueryClick(event) {
    delete this._query.fields;
    const uriParam = encodeURIComponent(Utils.formDataStringify(this._query.getQueryJson()));
    const uriParamQuery: NavigationExtras = {queryParams: {'query': uriParam}};
    const queryParams = new URLSearchParams(uriParamQuery.queryParams).toString();
    const url = window.location.href.split('/dashboard')[0] + '/result?' + queryParams;
    window.open(url, event?.ctrlKey || this.editMode ? '_blank' : '_self');
  }
}
