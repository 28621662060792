import {forkJoin, of} from 'rxjs';
import {Component, OnInit, EventEmitter, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {ActionComponent} from '../../../interfaces/action-component.interface';
import {Input, Output} from '@angular/core';
import {UntypedFormGroup, Validators, UntypedFormBuilder} from '@angular/forms';
import {TranslateService, DmsObject, UserService, DmsService} from '@eo-sdk/core';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'eo-add-resubmission',
  templateUrl: './add-resubmission.component.html',
  styleUrls: ['./add-resubmission.component.scss']
})
export class AddResubmissionComponent implements OnInit, AfterViewInit, ActionComponent {

  @Input() selection: DmsObject[];
  @ViewChild('titleField', {static: true}) titleField: ElementRef<any>;

  get hasEmail() {
    return this.userService.getCurrentUser().email && this.userService.getCurrentUser().email.length;
  }

  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  header: string;
  addResubmissionForm: UntypedFormGroup;
  dueFieldLabel: string;
  titleFieldLabel: string;
  inboxCbLabel: string;
  emailCbLabel: string;
  processing: boolean;

  constructor(private translate: TranslateService,
    private dms: DmsService,
    private userService: UserService,
    private fb: UntypedFormBuilder) {
  }

  createResubmissionForm() {
    this.addResubmissionForm = this.fb.group({
      titleFieldFCN: [this.createTitle(this.selection[0])],
      dueFieldFCN: [],
      inboxCbFCN: [true],
      emailCbFCN: [false],
    }, {
      validator: Validators.compose([
        this.notificationMethodValidator('inboxCbFCN', 'emailCbFCN'),
        this.dueDateValidator('dueFieldFCN')
      ])
    });

    if (this.selection.length === 1) {
      this.addResubmissionForm.controls['titleFieldFCN'].setValidators(Validators.required);
    }

    this.setFormValues(this.addResubmissionForm.controls, this.selection[0]);
  }

  setFormValues(controls, selection: DmsObject) {
    if (selection.resubmissions.length) {
      // if resubmissions array isn't empty, it's an update
      const {info, due, onlyemail, email} = selection.resubmissions[0];
      this.header = this.translate.instant('eo.object.inlinemenu.editresubmission');
      this.patchForm(info, new Date(`${due}`), !onlyemail, email);
    } else {
      this.header = this.translate.instant('eo.object.inlinemenu.addresubmission');
    }
  }

  patchForm(titleFieldFCN, dueFieldFCN, inboxCbFCN, emailCbFCN = false) {
    this.addResubmissionForm.setValue({titleFieldFCN, dueFieldFCN, inboxCbFCN, emailCbFCN});
  }

  get titleValidity() {
    return this.addResubmissionForm.get('titleFieldFCN').invalid;
  }

  private dueDateValidator(dueDate) {
    const now = new Date();
    return (group: UntypedFormGroup) => {
      if (group.controls[dueDate].value > now) {
        return null;
      }
      return {'dueDate': true}
    }
  }

  get dateValidation(): boolean {
    const {errors, controls} = this.addResubmissionForm;
    let dateValidationError = false;
    if (errors) {
      dateValidationError = (controls.dueFieldFCN.touched || controls.dueFieldFCN.dirty) && errors.dueDate;
    }
    return dateValidationError;
  }

  private notificationMethodValidator(inboxCbFCN, emailCbFCN) {
    return (group: UntypedFormGroup) => {
      if (group.controls[inboxCbFCN].value || group.controls[emailCbFCN].value) {
        return null;
      }
      return {'notificationMethod': true}
    }
  }

  get notificationMethodValidation(): boolean {
    const {errors} = this.addResubmissionForm;
    let notificationMethodValidationError = false;
    if (errors) {
      notificationMethodValidationError = errors.notificationMethod;
    }
    return notificationMethodValidationError;
  }

  createResubmission() {
    const {titleFieldFCN, dueFieldFCN, inboxCbFCN, emailCbFCN: sendEmail} = this.addResubmissionForm.value;
    const onlyEmail = this.hasEmail ? sendEmail && !inboxCbFCN : false;

    this.processing = true;
    const createTasks = [];
    this.selection.forEach(item => {
      const title = this.selection.length === 1 ? titleFieldFCN : this.createTitle(item);
      createTasks.push(this.dms.createResubmission(item, title, dueFieldFCN, undefined, sendEmail, onlyEmail)
        .pipe(
          // even in case oif an error return something because otherwise the whole forkJoin will fail
          // todo: maybe add some error notification that something went wrong
          catchError(e => of(null)
          )
        ));
    });
    if (createTasks.length) {
      forkJoin(createTasks).subscribe(() => {
        this.processing = false;
        this.finished.emit();
      }
      )
    } else {
      this.processing = false;
      this.finished.emit();
    }
  }

  updateResubmission() {
    const {titleFieldFCN, dueFieldFCN, inboxCbFCN, emailCbFCN: sendEmail} = this.addResubmissionForm.value;
    const onlyEmail = this.hasEmail ? sendEmail && !inboxCbFCN : false;

    this.processing = true;
    const updateTasks = [];
    this.selection.forEach(item => {
      const title = this.selection.length === 1 ? titleFieldFCN : this.createTitle(item);
      updateTasks.push(this.dms.updateResubmission(item, title, dueFieldFCN, undefined, sendEmail, onlyEmail).pipe(
        // even in case oif an error return something because otherwise the whole forkJoin will fail
        // todo: maybe add some error notification that something went wrong
        catchError(e => of(null))
      ));
    });
    if (updateTasks.length) {
      forkJoin(updateTasks).subscribe(() => {
        this.processing = false;
        this.finished.emit();
      }
      )
    } else {
      this.processing = false;
      this.finished.emit();
    }
  }

  removeResubmission() {
    this.processing = true;
    const removeTasks = [];
    this.selection.forEach(item => {
      removeTasks.push(this.dms.removeResubmissionsById(item.id, item)
        // even in case oif an error return something because otherwise the whole forkJoin will fail
        // todo: maybe add some error notification that something went wrong
        .pipe(catchError(e => of(null))));
    });
    if (removeTasks.length) {
      forkJoin(removeTasks).subscribe(() => {
        this.processing = false;
        this.finished.emit();
      }
      )
    } else {
      this.processing = false;
      this.finished.emit();
    }
  }

  private createTitle(item: DmsObject) {
    return item.isContextFolder && item.contextFolder ? `${item.contextFolder.title} > ${item.title}` : item.title;
  }

  cancel() {
    this.canceled.emit();
  }

  ngOnInit(): void {
    this.dueFieldLabel = this.translate.instant('eo.resubmission.field.due');
    this.titleFieldLabel = this.translate.instant('eo.resubmission.field.info');
    this.inboxCbLabel = this.translate.instant('eo.resubmission.field.notification.inbox');
    this.emailCbLabel = this.translate.instant('eo.resubmission.field.notification.email');

    this.createResubmissionForm();
  }

  ngAfterViewInit() {
    this.titleField.nativeElement.getElementsByTagName('input')[0].select();
  }

}
