import {ColDef, GridOptions} from '@ag-grid-community/core';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DmsObject, DmsParams, DmsService, EnaioEvent, EventService, TranslateService, Utils} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PageTitleService} from '../../eo-framework-core/title/page-title.service';
import {ISetFilter} from '../../eo-framework/grid';
import {CustomSortComponent} from '../../eo-framework/grid/extensions/custom-sort/custom-sort.component';
import {SetFilterComponent} from '../../eo-framework/grid/extensions/filter/setFilters/set-filter.component';
import {GridComponent} from '../../eo-framework/grid/grid.component';

@UntilDestroy()
@Component({
  selector: 'eo-version-state',
  templateUrl: './version-state.component.html',
  styleUrls: ['./version-state.component.scss']
})
export class VersionStateComponent implements OnInit {

  versionItem: DmsObject;
  gridOptions: GridOptions;
  sortFields: ColDef[];
  typeFilterFields: ISetFilter[];
  userFilterFields: ISetFilter[];
  gridData;

  @ViewChild('eoGrid') eoGrid: GridComponent;

  constructor(private route: ActivatedRoute,
              private translate: TranslateService,
              private titleService: PageTitleService,
              private eventService: EventService,
              private dmsService: DmsService) {
    this.titleService.setBaseTitle(this.translate.instant('eo.versions.list.title'));
  }

  ngOnInit() {
    this.eventService.on(EnaioEvent.DMS_OBJECT_VERSION_RESTORED).pipe(untilDestroyed(this)).subscribe(id => this.refreshGrid());
    this.refreshGrid(this.route.snapshot.queryParams['version'], this.route.snapshot.queryParams['version2']);
  }

  parseDmsParams(data): DmsParams {
    return data && data.dmsItem ? {id: data.dmsItem.id, type: data.dmsItem.type, version: data.version} : null;
  }

  refreshGrid(version?: string, version2?: string) {
    let id = this.route.snapshot.params['id'];
    let _type = this.route.snapshot.queryParams['type'];

    this.dmsService.getVersions(id, _type).subscribe((item: DmsObject) => {
      this.versionItem = item;
      this.gridData = (item.versions || [])
        .map(v => Object.assign(v, {id: v.version, dmsItem: {id: item.id, type: item.typeName}}))
        .sort((a, b) => b.version - a.version);
      let rowIndex = version ? this.gridData.findIndex(d => '' + d.version === version) : -1;
      let rowIndex2 = version2 ? this.gridData.findIndex(d => '' + d.version === version2) : -1;
      this.updateGrid(this.gridData, rowIndex, rowIndex2);
    });
  }

  typeFilters(data) {
    let uniqueByType = SetFilterComponent.uniqByProperty(data, 'type');
    this.typeFilterFields = uniqueByType.map((t: any) => Object.assign({
      label: t.title,
      value: true,
      filter: (node) => node.data.type === t.type,
      id: t.type
    })).sort(Utils.sortValues('label'));
  }

  userFilters(data) {
    let uniqueByType = SetFilterComponent.uniqByProperty(data, 'user.name');
    this.userFilterFields = uniqueByType.map((t: any) => Object.assign({
      label: t.user.title,
      value: true,
      filter: (node) => node.data.user.name === t.user.name,
      id: t.user.name
    })).sort(Utils.sortValues('label'));
  }

  resetFilters() {
    this.typeFilters(this.gridData);
    this.userFilters(this.gridData);
  }

  updateGrid(data, rowIndex = -1, rowIndex2 = -1) {

    if (this.eoGrid && this.eoGrid.isReady) {
      this.eoGrid.updateRowData(data, ~rowIndex ? rowIndex : 0);
      return this.resetFilters();
    }

    this.resetFilters();

    let colDefs: ColDef[] = [
      {
        headerName: '',
        field: '__custom',
        cellRenderer: this.cellRenderer,
        filter: false
      }
    ];

    this.sortFields = [
      {field: 'user.title', headerName: this.translate.instant('eo.inbox.sort.title'), sort: ''},
      {field: 'time', headerName: this.translate.instant('eo.inbox.sort.date'), sort: ''}
    ];

    let sortColDefs = CustomSortComponent.sortColDefs(this.sortFields);

    this.gridOptions = {
      context: {
        count: data.length, rowIndex: ~rowIndex2 ? rowIndex2 : ~rowIndex ? rowIndex : 0,
        selection: ~rowIndex2 && ~rowIndex && rowIndex2 !== rowIndex ? [rowIndex, rowIndex2] : null
      },
      rowData: data,
      columnDefs: colDefs.concat(sortColDefs),
      rowBuffer: 20,
      rowHeight: 80
    };
  }

  cellRenderer(params) {
    let shortDate = params.context.cr.render('dateTime', params, {value: params.data.time, pattern: 'eoShortDate'});
    let shortTime = params.context.cr.render('dateTime', params, {value: params.data.time, pattern: 'eoShortTime'});

    return `<div class="list-item" unselectable>
              <div class="icon circle">${params.data.version}</div>
              <div class='content'>
                <div class='title'>${params.data.user.title || ''}</div>
                <div class='description'>
                  ${params.data.description || ''}
                </div>
              </div>
              <div class="meta">
                <div class="date">${shortDate}</div>
                <div class="date">${shortTime}</div>
              </div>
           </div>
          `;
  }

}
