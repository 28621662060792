<div class="eo-login-container" [style.background-image]="backgroundImage">
  <div class="eo-login">

    <div class="head" *ngIf="!cloudEnvironment">
      <eo-icon [iconSrc]="appLogo"></eo-icon>
    </div>

    <div class="invalid" *ngIf="invalid" translate>eo.state.login.msg.invalid</div>

    <form (ngSubmit)="login()" #loginForm="ngForm">

      <fieldset [ngClass]="{loading: loading}">

        <div class="form-item" *ngIf="native" [ngClass]="{empty: !profiles || profiles.length === 0}">
          <yvc-dropdown [options]="profiles" placeholder="host"
                      [required]="true"
                      [(ngModel)]="form.host" name="profile">
            <ng-template let-profile pTemplate="item">
              <div class="option-row">
                <div>{{profile.label}}</div>
                <eo-icon [iconSrc]="'assets/_default/svg/ic_clear.svg'"
                         (click)="removeProfile(profile.value)"></eo-icon>
              </div>
            </ng-template>
          </yvc-dropdown>
          <label translate>eo.state.login.host</label>
        </div>

        <!-- form login -->
        <ng-container *ngIf="!cloudEnvironment; else tplCloudLogin">
          <div class="form-item">
            <input type="text" class="form-control" id="username" placeholder="{{'eo.state.login.username'|translate}}"
                   [disabled]="loading" autofocus
                   [(ngModel)]="form.username" name="username" required tabindex="1">
            <label for="username" translate>eo.state.login.username</label>
          </div>
          <div class="form-item">
            <input type="password" class="form-control" id="password"
                   placeholder="{{'eo.state.login.password'|translate}}"
                   [disabled]="loading"
                   [(ngModel)]="form.password" name="password" required tabindex="2">
            <label for="password" translate>eo.state.login.password</label>
          </div>
        </ng-container>

        <!-- cloud login -->
        <ng-template #tplCloudLogin>
          <div class="form-item">
            <input type="text" class="form-control" id="tenant" placeholder="{{'eo.state.login.tenant'|translate}}"
                   [disabled]="loading" autofocus
                   [(ngModel)]="form.tenant" name="tenant" required tabindex="1">
            <label for="tenant" translate>eo.state.login.tenant</label>
          </div>
        </ng-template>

      </fieldset>
      <div class="actions">
        <button type="submit" class="primary" [disabled]="!loginForm.form.valid || loading"
                [ngClass]="{spinner: loading}"
                translate tabindex="3">eo.state.login.submit</button>
      </div>
    </form>
  </div>
</div>

<eo-dialog [title]="'eo.state.login.presence.dialog.title' | translate" [(visible)]="showAbsentDialog"
           [focusOnShow]="false" [minWidth]="400" [styleClass]="'login-presence__dialog'">

  <div>
    <span>{{'eo.state.login.presence.dialog.message' | translate}}</span>
  </div>

  <div class="action-buttons login-presence--action-buttons flex-row">
    <button type="button" class="button cancel" (click)="closeShowAbsentDialog()"
            translate>eo.state.login.presence.dialog.cancel</button>
    <button type="button" #setAsPresent (click)="closeShowAbsentDialog(true)" class="button primary"
            translate>eo.state.login.presence.dialog.setAsPresent</button>
  </div>
</eo-dialog>
