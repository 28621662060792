<yvc-split-view [layoutSettingsID]="'inbox.state'">
  <!-- list -->
  <ng-template yvcSplitArea [size]="30">
    <eo-list-container #eoList [applySelection]="{out: inboxSelectionId}">
      <div class="eo-header">

        <eo-icon class="eo-header-icon" [iconSrc]="'assets/_default/svg/ic_inbox.svg'"></eo-icon>
        <div class="eo-header-info">
          <div class="eo-header-title" translate>eo.inbox.list.title</div>
        </div>
        <div class="eo-header-actions">
          <eo-icon class="button refresh-button" [ngClass]="{inboxChanged: inboxChanged}"
            (click)="cFilter.resetFilter(); refreshGrid()" [iconSrc]="'assets/_default/svg/ic_refresh.svg'"
            [iconTitle]="('eo.list.refresh' | translate)"></eo-icon>

          <eo-overlay #oFilter class="overlay-filter" [iconSrc]="'assets/_default/svg/ic_filter.svg'" [iconTitle]="'eo.list.filter' | translate">
            <eo-custom-filter #cFilter (change)="cFilter.updateGrid(eoList.eoGridSubject, $event)"
              (active)="oFilter.onActiveChanged($event)">
              <eo-text-filter [title]="'eo.inbox.filter.title' | translate" [filterParams]="defaultFilterParams"
                [id]="'inbox.text'" [matchFields]="['title','description','id']" [enableSave]="true"></eo-text-filter>
              <eo-set-filter class="type-filters" [operator]="'OR'" [id]="'inbox.type'" [filterParams]="defaultFilterParams" [options]="typeFilterFields"></eo-set-filter>
              <eo-set-filter class="inbox-filters" [operator]="'AND'" [id]="'inbox.set'" [filterParams]="defaultFilterParams" [options]="inboxFilterFields"></eo-set-filter>
            </eo-custom-filter>
          </eo-overlay>

          <eo-overlay #oSort class="overlay-sort" [iconSrc]="'assets/_default/svg/ic_sort.svg'"
            [iconTitle]="'eo.list.sort' | translate">
            <eo-custom-sort #cSort [id]="'inbox.sort'" (change)="cSort.updateGrid(eoList.eoGrid, $event)"
              (active)="oSort.onActiveChanged($event)" [sortFields]="sortFields"></eo-custom-sort>
          </eo-overlay>

        </div>
      </div>
      <div class="eo-body">
        <eo-grid #eoGrid *ngIf="gridOptions?.rowData" [gridOptions]="gridOptions" [fullWidth]="true" [showHeader]="true"
          [showFooter]="true" [selectFirst]="selectFirst" [options]="{filterActive: oFilter.active}"
          (eoGridCountChanged)="eoList.onCountChanged($event);onCountChanged($event)"
          (eoGridSelectionChanged)="eoList.onSelectionChanged($event)"
          (eoGridFocusChanged)="eoList.onFocusChanged($event)">
          <div class="header">
          </div>

          <div class="footer">
            <eo-total-count [gridCount]="eoList.gridCount" [outsideGrid]="false" class="flex-row"></eo-total-count>
          </div>

          <div class="empty">
            <eo-error-message
              [emptyState]="{icon: 'ic_inbox.svg', text: oFilter.active ? 'eo.no.filter.result' : 'eo.inbox.list.empty'}"></eo-error-message>
            <eo-reset-filter [isFilterActive]="oFilter.active"
              (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"></eo-reset-filter>
          </div>

        </eo-grid>
      </div>
    </eo-list-container>
  </ng-template>
  <!-- inbox details -->
  <ng-template yvcSplitArea [size]="30">
    <eo-inbox-details [applySelection]="{in: inboxSelectionId, out: inboxDmsObjectSelectionId}"
      [emptyState]="emptyState">
      <eo-tab-plugin [type]="'inbox-details-tab.inbox'"></eo-tab-plugin>
    </eo-inbox-details>
  </ng-template>
  <!-- object details -->
  <ng-template yvcSplitArea [size]="40">
    <eo-object-details [applySelection]="{in: inboxDmsObjectSelectionId}" [cacheLayout]="'inbox.state.object-details'">
      <eo-tab-plugin [type]="'object-details-tab.inbox'"></eo-tab-plugin>
    </eo-object-details>
  </ng-template>
</yvc-split-view>