import {Component, Input, OnInit} from '@angular/core';
import {IWidgetComponent} from '@yuuvis/widget-grid';
import {
  UserService,
  EoUser, SearchService, SystemService
} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'eo-last-edited-and-created',
  templateUrl: './last-edited-and-created.component.html',
  styleUrls: ['./last-edited-and-created.component.scss']
})
export class LastEditedAndCreatedComponent implements IWidgetComponent, OnInit {

  user: EoUser;
  modifiedByMe = {
    size: 10,
    total: 0,
    lately: []
  };
  createdByMe = {
    size: 10,
    total: 0,
    lately: []
  };
  @Input()
  widgetConfig: any;

  constructor(public userService: UserService,
    private searchService: SearchService,
    private systemService: SystemService,
  ) {}

  ngOnInit(): void {
    this.userService.user$
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.user = user;
        this.getLastModified();
        this.getLastCreated();
      });
  }

  getLastModified() {
    const q = {
      filters: {
        modifier: {
          o: 'eq',
          v1: this.user.name
        }
      },
      fields: ['title', 'description', 'modified', 'type'],
      options: {
        sort: {
          modified: {
            order: 'desc'
          }
        }
      }
    };
    this.searchService
      .executeQuery(q, false, this.modifiedByMe.size)
      .subscribe(res => {
        this.modifiedByMe.total = res.hits.total;
        this.modifiedByMe.lately = res.hits.hits.map(item => {
          const {id, title, description, type, modified} = item._source;
          return {
            id,
            type: this.systemService.getObjectType(type),
            title,
            description,
            modified,
          };
        });
      });
  }
  getLastCreated() {
    const q = {
      filters: {
        creator: {
          o: 'eq',
          v1: this.user.name
        }
      },
      fields: ['title', 'description', 'created', 'type'],
      options: {
        sort: {
          created: {
            order: 'asc'
          }
        }
      }
    };
    this.searchService
      .executeQuery(q, false, this.createdByMe.size)
      .subscribe(res => {
        this.createdByMe.total = res.hits.total;
        this.createdByMe.lately = res.hits.hits.map(item => {
          const {id, title, description, type, created} = item._source;
          return {
            id,
            type: this.systemService.getObjectType(type),
            title,
            description,
            created
          };
        });
      });
  }
}
