<div class="process-details" *ngIf="item; else noItem">

  <div class="eo-head">
    <header class="eo-header">
      <eo-icon class="eo-header-icon" [ngClass]="{error: item.state === 'ERRORSUSPENDED'}" *ngIf="!item.iconid" [iconTitle]="item.modelname"
               [iconSrc]="item.iconurl"></eo-icon>
      <eo-icon class="eo-header-icon" *ngIf="item.iconid" [iconId]="item.iconid" [iconTitle]="item.modelname"></eo-icon>
      <div class="eo-header-info eo-header__content">
        <div class="eo-header__title">
          <h2 class="eo-header-title">{{item.localizedsubject}}</h2>
          <h3 class="eo-header-subtitle">{{item.modelname}}<span class="starter" *ngIf="organisation && organisation.name !== 'sysroot' && organisation.name !== 'system'">{{organisation.title}} ({{organisation.name}})</span></h3>
        </div>
      </div>
      <div class="eo-header-actions">
        <eo-loading-spinner *ngIf="loading" [size]="'small'"></eo-loading-spinner>
      </div>
    </header>
  </div>

  <div class="eo-body">

    <div class="load-error" *ngIf="loadError">
      <eo-icon [iconSrc]="'assets/_default/svg/ic_error.svg'"></eo-icon>
    </div>

    <!-- details for BPM items -->
    <yvc-tabs>
      <ng-template [yvcTab]="{id: 'history', label: 'eo.process.details.tab.progress' | translate}">
        <div class="tab-panel">
          <div class="panel process-history">
            <eo-process-history [history]="history"></eo-process-history>
          </div>
        </div>
      </ng-template>
      <ng-template [yvcTab]="{id: 'file', label: 'eo.process.details.tab.attachments' | translate}">
        <div class="tab-panel">
          <div class="panel process-files">
            <eo-process-file [processFile]="item?.file"
                             [isDisabled]="false"
                             [clipboard]="clipboard"
                             [editable]="true"
                             [selectedContentFileId]="selectedContentFileId"
                             (onRemoveWorkItemContent)="removeWorkItemContent($event)"
                             (onOpenWorkItemContentInContext)="openWorkItemContentInContext($event)"
                             (onOpenWorkItemContent)="openWorkItemContent($event)"
                             (onRemoveWorkItemContent)="removeWorkItemContent($event)"
                             (onPasteProcessFile)="addPastedProcessFile($event)">
            </eo-process-file>
          </div>
        </div>
      </ng-template>
      <ng-content></ng-content>
    </yvc-tabs>
  </div>
</div>

<ng-template #noItem>
  <eo-error-message class="process-details__empty" [emptyState]="{icon: emptyState.icon, text: emptyState.text, className: emptyState.className}">
    <ng-content select=".error"></ng-content>
  </eo-error-message>
</ng-template>
