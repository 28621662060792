import {Component, HostBinding, HostListener, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Location} from '@angular/common';
import {GlobalShortcutComponent} from '../shortcuts/global-shortcut-component.interface';
import {
  UploadRegistryService,
  Capabilities,
  CapabilitiesService,
  UploadTarget,
  UserService,
  AuthService,
  EoUser,
  NotificationsService,
  Config,
  TranslateService, EnvironmentEnaio
} from '@eo-sdk/core';
import { PendingChangesService } from '../../eo-framework-core/pending-changes/pending-changes.service';

@Component({
  selector: 'eo-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit {


  @HostBinding('class.dashboard') transparentBar = false;
  @HostBinding('class.view-no-bar') noBar = 0;
  @HostBinding('class.view-no-header') noHeader = 0;
  @HostBinding('class.object-state') isObjectState = true;

  initError: any;
  authenticated = false;
  dir = 'ltr'; // default direction - Left To Right
  isMobileEnv: boolean = EnvironmentEnaio.isMobileEnvironment();
  capabilities: Capabilities;
  docu: {link: string, label: string};

  // shortcut actions
  shortcuts: GlobalShortcutComponent = {
    id: 'eo.app',
    label: this.translate.instant('eo.shortcuts.general.title'),
    labelKey: 'eo.shortcuts.general.title',
    actions: [
      {
        name: this.translate.instant('eo.shortcuts.action.add'),
        nameKey: 'eo.shortcuts.action.add',
        shortcut: 'C',
        onExecuteAction: () => {
          this.router.navigate([{outlets: {modal: 'add'}}]);
        },
        isHidden: () => {
          return this.capabilities ? !this.capabilities.intray : true;
        }
      },
      {
        name: this.translate.instant('eo.shortcuts.action.search'),
        nameKey: 'eo.shortcuts.action.search',
        shortcut: 'F',
        onExecuteAction: () => {
          this.router.navigate([{outlets: {modal: 'search'}}]);
        }
      },
      {
        name: this.translate.instant('eo.shortcuts.action.logout'),
        nameKey: 'eo.shortcuts.action.logout',
        shortcut: 'L',
        onExecuteAction: () => {
          this.router.navigate([{outlets: {modal: null, primary: ['enter', {logout: true}]}}]);
        }
      },
      {
        name: this.translate.instant('eo.shortcuts.action.adjust.view'),
        nameKey: 'eo.shortcuts.action.adjust.view',
        shortcut: 'V',
        onExecuteAction: () => this.adjustView(),
        isHidden: () => {
          return this.transparentBar;
        }
      },
      {
        name: this.translate.instant('eo.sidebar.help.manual'),
        nameKey: 'eo.sidebar.help.manual',
        shortcut: 'D',
        onExecuteAction: () => {
          window.open(this.docu.link);
        },
      }
    ]
  };
  // navigation shortcuts
  navShortcuts: GlobalShortcutComponent = {
    id: 'eo.app.nav',
    label: this.translate.instant('eo.view.navigation.title'),
    labelKey: 'eo.view.navigation.title',
    actions: [
      {
        name: this.translate.instant('eo.shortcuts.action.dashboard'),
        nameKey: 'eo.shortcuts.action.dashboard',
        shortcut: '0',
        onExecuteAction: () => {
          this.router.navigateByUrl('/dashboard');
        }
      },
      {
        name: this.translate.instant('eo.view.navigation.inbox'),
        nameKey: 'eo.view.navigation.inbox',
        shortcut: '1',
        onExecuteAction: () => {
          this.router.navigateByUrl('/inbox');
        },
        isHidden: () => {
          return this.capabilities ? !this.capabilities.inbox : true;
        }
      },
      {
        name: this.translate.instant('eo.view.navigation.prepare'),
        nameKey: 'eo.view.navigation.prepare',
        shortcut: '2',
        onExecuteAction: () => {
          this.router.navigateByUrl('/prepare');
        },
        isHidden: () => {
          return this.capabilities ? !this.capabilities.intray : true;
        }
      },
      {
        name: this.translate.instant('eo.view.navigation.favorites'),
        nameKey: 'eo.view.navigation.favorites',
        shortcut: '3',
        onExecuteAction: () => {
          this.router.navigateByUrl('/favorites');
        },
        isHidden: () => {
          return this.capabilities ? !this.capabilities.favorites : true;
        }
      },
      {
        name: this.translate.instant('eo.bar.button.settings.title'),
        nameKey: 'eo.bar.button.settings.title',
        shortcut: '4',
        onExecuteAction: () => {
          this.router.navigateByUrl('/settings');
        }
      },
      {
        name: this.translate.instant('eo.view.navigation.process'),
        nameKey: 'eo.view.navigation.process',
        shortcut: '5',
        onExecuteAction: () => {
          this.router.navigateByUrl('/process');
        }
      },
      {
        name: this.translate.instant('eo.view.navigation.notifications'),
        nameKey: 'eo.view.navigation.notifications',
        shortcut: '6',
        onExecuteAction: () => {
          this.router.navigateByUrl('/notifications');
        }
      },
      {
        name: this.translate.instant('eo.view.navigation.storedqueries'),
        nameKey: 'eo.view.navigation.storedqueries',
        shortcut: '7',
        onExecuteAction: () => {
          this.router.navigateByUrl('/stored-queries');
        }
      },
      {
        name: this.translate.instant('eo.view.navigation.recyclebin'),
        nameKey: 'eo.view.navigation.recyclebin',
        shortcut: '8',
        onExecuteAction: () => {
          this.router.navigateByUrl('/recyclebin');
        }
      },
    ]
  };


  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    return !this.pendingChanges.check();
  }
  
  @HostBinding('attr.data-roles') roles = '';

  constructor(private notify: NotificationsService,
              private userService: UserService,
              private router: Router,
              private config: Config,
              private location: Location,
              public translate: TranslateService,
              private authService: AuthService,
              private pendingChanges: PendingChangesService,
              private capabilitiesService: CapabilitiesService,
              private uploadRegistry: UploadRegistryService) {

    // this.capabilities = this.capabilitiesService.getCapabilities();

    // Initialization of the core components is done, so we check its state
    // Any errors during global initialization?
    // if (this.init.getState().error !== null) {
    //   this.initError = {
    //     cause: this.init.getState().error
    //   }
    // }

    this.userService
      .user$
      .subscribe((user: EoUser) => {
        this.dir = user ? user.uiDirection : 'ltr';
        this.roles = user?.roles?.map(r => r.name).join(';') || '';
      });

    this.router
      .events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.setStyle();
        }
      });

    this.authService
      .authenticated$
      .subscribe(authenticated => setTimeout(() => {
        this.authenticated = authenticated;
        // fetch capabilities again once a user is authenticated
        if (authenticated) {
          this.capabilities = this.capabilitiesService.getCapabilities();
        }
      }));

    // add root upload target
    let rootTarget = new UploadTarget('root', UploadTarget.ROOT);
    rootTarget.onUploadSuccess = () => this.router.navigate(['/prepare']);
    this.uploadRegistry.register(rootTarget);

    this.setStyle();
  }

  getDocumentation() {
    this.userService
      .user$
      .subscribe(data => {
        const docu = this.config.getRaw('about.docu');
        const link = docu.link.replace('###userLang###', data.userSettings.clientlocale.substring(0, 2));
        this.docu = {...docu, link};
      })
  }

  ngOnInit() {
    this.getDocumentation();
  }

  private setStyle() {
    this.transparentBar = !!this.location.path().match(/\/dashboard|\/enter/);
    this.isObjectState = !!this.location.path().match(/\/object\//);
  }

  adjustView() {
    this.noBar = (this.noBar + 1) % 3;
    this.noHeader = this.noBar === 2 ? 1 : 0;
  }
}

