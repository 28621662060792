<ng-container *ngIf="storedQuery">
  <div class="eo-head">
    <header class="eo-header">
      <eo-icon class="eo-header-icon"
               [iconSrc]="'assets/_default/svg/ic_search.svg'"
               [iconTitle]="('eo.view.navigation.storedqueries' | translate)">
      </eo-icon>
      <div class="eo-header-info">
        <h2 class="eo-header-title">{{storedQuery.name}}</h2>
        <h3 class="eo-header-subtitle" translate>eo.search.title.storedqueries</h3>
      </div>
    </header>
  </div>

  <div class="eo-body">

    <div class="form">

      <ng-template #queryForm>
        <eo-stored-query [query]="storedQuery" [formOnly]="true" #storedQueryForm
                         (onQueryLoaded)="onStoredQueryLoaded()"
                         (onQueryExecute)="onStoredQueryExecute($event)"></eo-stored-query>
      </ng-template>

      <form class="edit-form" [formGroup]="editForm.form" *ngIf="editForm.form; else queryForm">

        <div class="context dark">

          <!-- title and fulltext term fields -->
          <eo-form-input class="title">
            <input type="text" name="storedQueryTitle" maxlength="64" onfocus="this.select()" [formControlName]="'storedQueryTitle'"/>
          </eo-form-input>

          <eo-query-scope-select [selection]="queryScope" (select)="setQueryScope($event)"></eo-query-scope-select>
          <div class="form-field">
            <eo-form-element [skipToggle]="true" [element]="editForm.fulltextFormControl" [situation]="'SEARCH'"></eo-form-element>
            <eo-dynamic-property-switch [parentFormControl]="editForm.fulltextFormControl"
                                        (onPropertyChanged)="parameterChanged($event)"></eo-dynamic-property-switch>
          </div>

          <!-- context type form controls -->
          <h3>{{storedQuery.contextFolderTypes[0]?.label}}</h3>
          <div class="form-field" *ngFor="let ctxElement of editForm.contextControls; trackBy: trackByQName">
            <eo-form-element [element]="ctxElement" [situation]="'SEARCH'"></eo-form-element>
            <eo-dynamic-property-switch [parentFormControl]="ctxElement" (onPropertyChanged)="parameterChanged($event)"></eo-dynamic-property-switch>
          </div>
        </div>

        <!-- object type form controls -->
        <div class="form-fields" [ngClass]="{empty: !editForm.controls?.length}">
          <div class="form-field" *ngFor="let element of editForm.controls; trackBy: trackByQName">
            <eo-form-element [element]="element" [situation]="'SEARCH'"></eo-form-element>
            <eo-dynamic-property-switch [parentFormControl]="element" (onPropertyChanged)="parameterChanged($event)"></eo-dynamic-property-switch>
          </div>
        </div>
      </form>
    </div>

    <aside class="aside">

      <!-- favorite and share -->
      <div class="meta-actions" *ngIf="!isNewQuery">

        <ng-container *ngIf="isOwnQuery; else sharedWithMe">
          <eo-icon class="button btn-share" [iconSrc]="'assets/_default/svg/ic_share.svg'" *ngIf="enableSharing"
                   [ngClass]="{active: storedQuery.shared || storedQuery.roles.length, editing: editingShare}"
                   (click)="toggleShareEdit()"
                   [iconTitle]="iconTitles.share"></eo-icon>

          <eo-icon class="button btn-fav" [iconSrc]="'assets/_default/svg/ic_favorite.svg'"
                   [ngClass]="{active: storedQuery.favorite}"
                   (click)="toggleFavoriteState()"
                   [iconTitle]="iconTitles.favor"></eo-icon>
        </ng-container>

        <ng-template #sharedWithMe>
          <eo-icon class="sharedWithMe" [iconSrc]="'assets/_default/svg/ic_share.svg'" [iconTitle]="iconTitles.shared"></eo-icon>
          <eo-icon class="sharedWithMe" [iconSrc]="'assets/_default/svg/ic_favorite.svg'" [iconTitle]="iconTitles.favored"></eo-icon>
        </ng-template>

      </div>

      <!-- roles that share this query -->
      <div class="meta-roles" *ngIf="!isNewQuery" [ngClass]="{dark: editingShare, empty: !storedQuery.roles?.length}">

        <div class="label" translate>eo.storedquery.share.sharedwith</div>
        <div class="form-field" *ngIf="rolesPicker?.codesystem?.entries">
          <eo-codesystem [pickerTitle]="rolesPicker.title"
                         [codesystem]="rolesPicker.codesystem"
                         [multiselect]="true"
                         [readonly]="!editingShare"
                         [inputStyleClass]="'stored-query__share'"
                         [(ngModel)]="rolesPicker.value"></eo-codesystem>
        </div>

        <div class="share-actions" *ngIf="editingShare">
          <button class="secondary" (click)="cancelShare()" translate>eo.storedquery.share.cancel</button>
          <button class="primary" (click)="share()" translate>eo.storedquery.share.ok</button>
        </div>
      </div>

      <!-- queries meta info like baseparam settings -->
      <div class="meta-data" *ngIf="restrictions?.length">
        <div class="restriction" *ngFor="let restriction of restrictions; trackBy: trackByIndex">
          <div class="label">{{restriction.label}}</div>
          <div class="items">
            <div *ngFor="let item of restriction.items; trackBy: trackByIndex">{{item}}</div>
          </div>
        </div>
      </div>

    </aside>

  </div>

  <div class="actions">
    <ng-container *ngIf="!editForm.form; else actionEdit">
      <button class="secondary btn-delete" (click)="delete()" [disabled]="!isOwnQuery" translate>eo.storedquery.action.delete</button>
      <button class="secondary btn-open" (click)="setQueryToAppSearch()" translate>eo.storedquery.load</button>
      <button class="primary grey btn-edit" (click)="edit()" [disabled]="!isOwnQuery" translate>eo.storedquery.action.edit</button>
      <button class="primary btn-search" [disabled]="storedQuery.state?.isEmpty" (click)="executeQuery()" translate>eo.storedquery.run</button>
    </ng-container>

    <ng-template #actionEdit>
      <!-- action while editing the query form -->
      <button class="secondary btn-cancel" (click)="cancelEdit()" translate>eo.storedquery.action.cancel</button>
      <button class="primary btn-save" (click)="updateStoredQuery()" [disabled]="editForm.form.invalid" translate>eo.storedquery.action.save</button>
    </ng-template>
  </div>

</ng-container>
