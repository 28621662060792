<div class="loader-overlay__mask" *ngIf="showLoader">
  <eo-loading-spinner class="object-detail__loader"></eo-loading-spinner>
</div>
<ng-container *ngIf="item && !hasError; else noItem">

  <div class="eo-head eo-head-tabs" [eoShortcuts]="shortcuts">
    <header class="eo-header">
      <eo-icon class="eo-header-icon" [objectType]="item.type" [iconTitle]="item.type.label"></eo-icon>
      <div class="eo-header-info">
        <h2 class="eo-header-title">{{item.title}}</h2>
        <h3 class="eo-header-subtitle">{{item.description}}</h3>
      </div>

      <div class="eo-header-actions-container">
        <div class="eo-header-actions">
          <ng-container *ngIf="!isReference; else tplHeadReference">
            <eo-icon class="button refresh-button" *ngIf="!recyclebinTabs"
              [iconSrc]="'assets/_default/svg/ic_refresh.svg'" [iconTitle]="('eo.list.refresh' | translate)"
              [ngClass]="{'disable-refresh': preventClickThrough}" (click)="refreshContent()"></eo-icon>
            <eo-icon class="button sync-tab-btn" *ngIf="enableCompare && !recyclebinTabs"
              [ngClass]="{'enabled': enableSync}" [iconSrc]="'assets/_default/svg/ic_link.svg'"
              [iconTitle]="'eo.versions.detail.glue.tooltip' | translate" (click)="enableSync = !enableSync"></eo-icon>
            <eo-favorite-icon [item]="item"
              *ngIf="!enableCompare && !recyclebinTabs && capabilities.favorites && !isJournalObject(item)">
            </eo-favorite-icon>
            <eo-icon class="button oc-button" *ngIf="!enableCompare && item.content" (click)="downloadOriginalContent()"
              [iconTitle]="('eo.action.download.dms.object.content.label' | translate)"
              [iconSrc]="'assets/_default/svg/ic_content-download.svg'"></eo-icon>
            <eo-edit-icon [item]="item"
              *ngIf="!enableCompare && !recyclebinTabs && item.content && item.rights.edit && (agentIsConnected$ | async) && !item.isFinalized">
            </eo-edit-icon>
            <eo-icon class="button am-button" *ngIf="!recyclebinTabs" (click)="showActions()"
              [iconTitle]="('eo.object.details.actions.title' | translate)"
              [iconSrc]="'assets/_default/svg/ic_more.svg'"></eo-icon>
          </ng-container>

          <ng-template #tplHeadReference>
            <a class="button reference primary"
              [title]="('eo.object.reference.tooltip' | translate:{title:referenceTitle})"
              [routerLink]="['/object', item.id]"
              [queryParams]="{type: item.typeName}">{{'eo.object.reference.open'|translate}}</a>
          </ng-template>

        </div>

        <div class="eo-header-status-icons">
          <a class="content-link" [title]="'eo.object.contentlink.tooltip' | translate" *ngIf="item.content?.linked"
            [routerLink]="'/object/' + item.content.id" [queryParams]="{type: item.content.type}">
            <span>{{'eo.object.contentlink' | translate}}</span>
          </a>
          <eo-icon class="subscription-icon" *ngIf="item.subscriptions.length"
            [iconSrc]="'assets/_default/svg/ic_subscription.svg'" [iconTitle]="subscriptionIconTooltip"></eo-icon>
          <eo-icon class="resubmission-icon" *ngIf="item.resubmissions.length"
            [iconSrc]="'assets/_default/svg/ic_resubmission.svg'"
            [iconTitle]="'eo.object.resubmission.tooltip' | translate: {due: item.resubmissions[0].due | localeDate, info: item.resubmissions[0].info}"></eo-icon>
          <eo-icon class="shared-icon" *ngIf="item.additionalvisibility.length && !recyclebinTabs"
            [iconSrc]="'assets/_default/svg/ic_share.svg'"
            [iconTitle]="'eo.object.shared.tooltip' | translate"></eo-icon>
          <eo-icon class="lock" [ngClass]="{'myLock': item.lock.by.me}" *ngIf="item.lock"
            [iconSrc]="'assets/_default/svg/ic_lock.svg'" [iconTitle]="lockTooltip"></eo-icon>
          <eo-icon class="finalized-icon" *ngIf="item.isFinalized" [iconSrc]="'assets/_default/svg/ic_finalized.svg'"
            [iconTitle]="'eo.object.finalized.tooltip' | translate"></eo-icon>
          <eo-icon class="signed-icon inprocess" *ngIf="item.data.yuvsigstatus === 'inprocess'"
            [iconSrc]="'assets/_default/svg/ic_signature.svg'"
            [iconTitle]="'eo.object.signature.tooltip.inprocess' | translate"></eo-icon>
          <eo-icon class="signed-icon signed" *ngIf="item.data.yuvsigstatus === 'signed'"
            [iconSrc]="'assets/_default/svg/ic_signature.svg'"
            [iconTitle]="'eo.object.signature.tooltip.signed' | translate"></eo-icon>
          <eo-icon class="signed-icon stopped"
            *ngIf="item.data.yuvsigstatus === 'canceled'
                    || item.data.yuvsigstatus === 'revised' || item.data.yuvsigstatus === 'error' || item.data.yuvsigstatus === 'expired'"
            [iconSrc]="'assets/_default/svg/ic_signature.svg'"
            [iconTitle]="'eo.object.signature.tooltip.stopped' | translate"></eo-icon>
        </div>
      </div>
    </header>
  </div>
  <div class="eo-body">

    <yvc-split-tabs [config]="splitTabConfig" [layoutSettingsID]="cacheLayout" *ngIf="!item2; else tplCompare">
      <!-- summary -->
      <ng-template [yvcTab]="{id: 'summary', label: 'eo.object.summary.title' | translate}">
        <eo-indexdata-summary [indexdata]="indexDataPreview" [baseparams]="baseparams" [dmsObject]="item"
          [enableVersions]="!enableCompare"></eo-indexdata-summary>
      </ng-template>
      <!-- metadata -->
      <ng-template [yvcTab]="{id: 'indexdata', label: 'eo.object.indexdata.title' | translate}">
        <eo-object-form-edit [dmsObject]="item" (indexDataSaved)="onIndexDataSaved($event, item)"
          [formDisabled]="enableCompare || isReference"></eo-object-form-edit>
      </ng-template>
      <!-- preview -->
      <ng-template [yvcTab]="{id: 'preview', label: 'eo.object.content.title' | translate}">
        <eo-media [dmsObject]="item" [useVersion]="item.id === item.content?.id" [searchTerm]="searchTerm"
          #viewer></eo-media>
      </ng-template>
      <!-- history -->
      <ng-template [yvcTab]="{id: 'history', label: 'eo.object.history.title' | translate}">
        <eo-object-history [params]="{id: item.id, type: item.type?.qname}">
        </eo-object-history>
      </ng-template>
      <!-- links -->
      <ng-template [yvcTab]="{id: 'links', label: 'eo.object.links.title' | translate}">
        <eo-object-links [dmsObject]="item"></eo-object-links>
      </ng-template>
      <!-- signature -->
      <ng-template [yvcTab]="{id: 'signature', label: 'eo.object.signature-tab.title' | translate}">
        <eo-signature-tab [dmsObject]="item"></eo-signature-tab>
      </ng-template>
    </yvc-split-tabs>

    <ng-template #tplCompare>
      <yvc-split-view>
        <ng-template yvcSplitArea [size]="50">

          <div class="cmp-wrapper">
            <div class="cmp-info">
              <div class="version">{{'eo.versions.details.label' | translate}}<span>{{item.version}}</span></div>
            </div>
            <ng-container
              *ngTemplateOutlet="tplTabs; context: {item: item, indexDataPreview, baseparams, enableCompare: false, searchTerm, isReference}"></ng-container>
          </div>

        </ng-template>
        <ng-template yvcSplitArea [size]="50">

          <div class="cmp-wrapper">
            <div class="cmp-info">
              <div class="version">{{'eo.versions.details.label' | translate}}<span>{{item2.version}}</span></div>
            </div>
            <ng-container
              *ngTemplateOutlet="tplTabs; context: {item: item, item2: item2, indexDataPreview: indexDataPreview, baseparams, indexDataPreview2: indexDataPreview2, baseparams2, enableCompare: false, searchTerm, isReference}"></ng-container>
          </div>
        </ng-template>

      </yvc-split-view>
    </ng-template>

    <ng-template #tplTabs 
    let-item="item" 
    let-item2="item2" 
    let-indexDataPreview="indexDataPreview" let-baseparams="baseparams"
    let-indexDataPreview2="indexDataPreview2" let-baseparams2="baseparams2"

      let-enableCompare="enableCompare" let-searchTerm="searchTerm" let-isReference="isReference">
      <yvc-tabs (tabChange)="onCompareTabChange($event)">
        <!-- summary -->
        <ng-template [yvcTab]="{id: 'summary', label: 'eo.object.summary.title' | translate}">
          <eo-indexdata-summary [indexdata]="indexDataPreview" [baseparams]="baseparams"
            [dmsObject]="item"></eo-indexdata-summary>
        </ng-template>
        <!-- preview -->
        <ng-template [yvcTab]="{id: 'preview', label: 'eo.object.content.title' | translate}">
          <eo-media [dmsObject]="item" [useVersion]="item.id === item.content?.id" [searchTerm]="searchTerm"
            #viewer></eo-media>
        </ng-template>
        <!-- changes -->
        <ng-template
          [yvcTab]="{id: 'changes', label: 'eo.object.changes.title' | translate}">
          <eo-indexdata-summary
            [diff]="{indexdata : indexDataPreview, indexdata2: indexDataPreview2, baseparams, baseparams2}"
            [dmsObject]="item2" [enableVersions]="!enableCompare"></eo-indexdata-summary>
        </ng-template>


      </yvc-tabs>
    </ng-template>

  </div>
</ng-container>

<ng-template #noItem>
  <span class="not-found" *ngIf="nodmsobject" translate>eo.bpm.nodmsobject</span>
  <eo-error-message *ngIf="!showLoader"
    [emptyState]="{icon: emptyState.icon, text: emptyState.text, className: emptyState.className}">
    <ng-content select=".error"></ng-content>
  </eo-error-message>
</ng-template>