import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {ColDef, GridOptions, Module} from '@ag-grid-community/core';
import {CsvExportModule} from '@ag-grid-community/csv-export';
import {Component, HostListener, Input, OnInit, ViewChild, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, Validator} from '@angular/forms';
import {LocalStorageService, SystemService, Utils} from '@eo-sdk/core';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CellRenderer, GridService} from '../../../../eo-framework-core/api/grid.service';
import {PendingChangesService} from './../../../../eo-framework-core/pending-changes/pending-changes.service';
import {EditRow, TableComponentParams} from './form-element.interface';
import {RowEditComponent} from './row-edit/row-edit.component';

@UntilDestroy()
@Component({
  selector: 'eo-table',
  templateUrl: './form-element-table.component.html',
  styleUrls: [
    './form-element-table.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormElementTableComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormElementTableComponent),
      multi: true,
    }
  ]
})
export class FormElementTableComponent implements ControlValueAccessor, Validator, OnInit {

  private _cachedColumns: any[];
  private _cachedColumnsOverlay: any[];

  public modules: Module[] = [ClientSideRowModelModule, CsvExportModule];

  BASE_CACHE_LAYOUT_KEY = 'table.state';
  cacheLayoutKey = this.BASE_CACHE_LAYOUT_KEY;

  @ViewChild('rowEdit') rowEdit: RowEditComponent;
  // @ViewChild('splitView') splitView: SplitComponent;

  @Input() limit: number;
  @Input() hasPreviewFile: boolean;
  @Input() minWidth = '97vw';
  @Input() height = '95vh';

  @Input()
  set params(p: TableComponentParams) {
    this.cacheLayoutKey = p ? `${this.BASE_CACHE_LAYOUT_KEY}.${p.element.name}` : this.BASE_CACHE_LAYOUT_KEY
    if (p) {
      this._params = p;
      this._cachedColumns = this.storageService.getItem(this.cacheLayoutKey + '.colDef') || [];
      this._cachedColumnsOverlay = this.storageService.getItem(this.cacheLayoutKey + '.colDefOverlay') || [];
      this.showPreview = this.storageService.getItem(this.cacheLayoutKey + '.showPreview');


      CellRenderer.situation = this._params.situation;
      if (this._params.situation === 'SEARCH') {
        this._params.size = 'supersmall';
      } else if (!this._params.size) {
        this._params.size = 'small';
      }
      this.gridReady = false;
      this._elements = p.element.elements;
      this.gridOptions.columnDefs = this.createColumnDefinition();
      if (this._params.situation === 'SEARCH') {
        this.gridOptions.columnDefs.push({headerName: '', colId: 'actions', width: 34, minWidth: 34, pinned: 'right', cellRenderer: this.actionsCellRenderer});
      } else {
        this.overlayGridOptions.columnDefs = this.createColumnDefinition(true);
      }

      this.gridReady = true;
    }
  }
  get params() {
    return this._params;
  }

  _params: TableComponentParams;
  private _elements: any[];
  gridReady = false;
  value: any[];
  innerValue: any[];
  gridOptions: GridOptions;
  overlayGridOptions: GridOptions;
  editingRow: EditRow;
  showDialog = false;
  showPreview = false;

  @HostListener('keydown.control.alt.shift.c', ['$event'])
  @HostListener('keydown.control.shift.c', ['$event'])
  @HostListener('keydown.control.alt.c', ['$event'])
  @HostListener('keydown.control.c', ['$event'])
  copyCellHandler(event: KeyboardEvent) {
    this.gridApi.copyToClipboard(event, this.gridOptions);
  }

  constructor(
    private storageService: LocalStorageService,
    private systemService: SystemService, private pendingChanges: PendingChangesService,
    public gridApi: GridService) {
    this.gridOptions = <GridOptions>{
      context: this.gridApi.getContext(),
      headerHeight: 30,
      rowHeight: 30,
      rowBuffer: 20,
      multiSortKey: 'ctrl',
      accentedSort: true,
      // suppressCellSelection: true,
      rowSelection: 'single',
      suppressMovableColumns: true,
      enableFilter: false,
      suppressNoRowsOverlay: true,
      suppressLoadingOverlay: true,
      suppressContextMenu: true
    };
    this.gridOptions.context.tableComponent = this;

    this.overlayGridOptions = <GridOptions>{
      context: this.gridApi.getContext(),
      headerHeight: 30,
      rowHeight: 30,
      rowBuffer: 20,
      multiSortKey: 'ctrl',
      accentedSort: true,
      // suppressCellSelection: true,
      rowSelection: 'single',
      suppressMovableColumns: true,
      enableFilter: false,
      suppressNoRowsOverlay: true,
      suppressLoadingOverlay: true,
      suppressContextMenu: true
    };
    this.overlayGridOptions.context.tableComponent = this;
    this.overlayGridOptions.rowClassRules = {
      'new-row': function (params) {
        if (params.data.isNewRow) {
          delete params.data.isNewRow;
          delete params.context.tableComponent.innerValue[params.context.tableComponent.innerValue.length - 1].isNewRow;
          return true;
        } else {
          return false;
        }
      }
    }

    this.pendingChanges.tasks$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(tasks => {
        setTimeout(() => {
          this.overlayGridOptions.suppressRowClickSelection = !!this.rowEdit && !!tasks.includes(this.rowEdit.pendingTaskId);
        }, 0);
      });
  }

  actionsCellRenderer(params) {
    let div = document.createElement('div');

    if (params.context.tableComponent.params.situation === 'SEARCH') {
      div.innerHTML = `<div class="action-icon" id="actionIcon-${params.rowIndex}">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
      </svg>
      </div>`;
      let clearIcon = div.querySelectorAll(`#actionIcon-${params.rowIndex}`)[0];
      clearIcon.addEventListener('click', () => params.context.tableComponent.clearRow(params.rowIndex));
    } else {
      div.innerHTML = `<div class="action-icon" id="actionIcon-${params.rowIndex}">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
      </svg>
      </div>`;
      let deleteIcon = div.querySelectorAll(`#actionIcon-${params.rowIndex}`)[0];
      deleteIcon.addEventListener('click', () => params.context.tableComponent.deleteRow(params.rowIndex));
    }

    return div;
  }

  propagateChange = (_: any) => {
  }

  writeValue(value: any[]): void {

    if (!value && this._params.situation === 'SEARCH') {
      // for search row data will always be one empty row
      // todo: re-enable again when search in tables is supported
      value = [{}];
    }
    this.value = value instanceof Array ? value : [];
    // create a clone of the actual value for internal usage
    this.innerValue = Utils.formDataParse(Utils.formDataStringify(this.value));

    if (this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.innerValue);
    } else {
      this.gridOptions.rowData = this.innerValue;
    }

    if (this.overlayGridOptions.api) {
      this.overlayGridOptions.api.setRowData(this.innerValue);
      setTimeout(() => this.selectEditRow(), 0);
    } else {
      this.overlayGridOptions.rowData = this.innerValue;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  /**
   * Create column definition from form element.
   * @returns column definition to be added to the gridOptions
   */
  private createColumnDefinition(overlay?: boolean): ColDef[] {
    return this._elements.map((el) => {
      let col: ColDef = this.gridApi.getColumnDefinition(el);
      Object.assign(col, {
        headerName: el.label,
        suppressMenu: true,
        filter: false,
        sortable: true,
        resizable: true,
        field: el.name,
        refData: {
          ...col.refData,
          _eoFormElement: el,
          _situation: this._params.situation
        }
      });
      let cachedColumns = this._cachedColumns;
      if (overlay) {
        cachedColumns = this._cachedColumnsOverlay;
      }
      let cachedColumn = cachedColumns.find(f => f.qname === el.qname);
      if (cachedColumn) {
        col.width = cachedColumn.width;
      }
      return col;
    });
  }

  addRow() {
    const rowData = {};
    const asyncFetches = [];
    this._elements
      .forEach(el => {
        if (el.defaultvalue !== undefined) {
          rowData[el.name] = el.defaultvalue;
        } else if (el.defaultvaluefunction) {
          rowData[el.name] = this.systemService.getDefaultValue(el.defaultvaluefunction);
          if (el.defaultvaluefunction === 'CURRENT_USER') {
            asyncFetches.push(this.resolveOrgDataMeta(el.name, rowData[el.name]))
          }

        }
      });
    if (asyncFetches.length > 0) {
      forkJoin(asyncFetches).subscribe((res: {key: string, value: any}[]) => {
        res.forEach(r => {
          rowData[r.key] = r.value;
        })
        this.setEditRow(-1, rowData);
      })
    } else {
      this.setEditRow(-1, rowData);
    }
  }

  private resolveOrgDataMeta(elementName: string, value: any) {
    return this.systemService.getOrganizationObject(value).pipe(
      map(res => ({
        key: `${elementName}_meta`,
        value: res
      }))
    )
  }

  editRow(event) {
    this.setEditRow(event.node.id, event.node.data);
  }

  onEditComplete(event) {
    //todo: is it obsolete?
  }

  private setEditRow(index: number, data: any) {
    // setup the row to be edited. Beside some properties, the
    // row contains a form model and form data. If the parent form model
    // provided a script, this will be added as well, so editing a row will
    // respect the form script.
    this.editingRow = {
      situation: this._params.situation,
      index: index,
      tableElement: this._params.element,
      form: {
        elements: this._elements,
        disabled: this._params.element.readonly,
        data
      }
    };
    this.showDialog = true;
    setTimeout(() => this.selectEditRow(), 0);
  }

  private selectEditRow() {
    if (this.overlayGridOptions.api) {
      this.overlayGridOptions.api.deselectAll();
      if (this.editingRow && this.editingRow.index !== -1) {
        let rowNode = this.overlayGridOptions.api.getRowNode('' + this.editingRow.index);
        rowNode.setSelected(true, true);
        this.overlayGridOptions.api.ensureNodeVisible(rowNode);
      }
    }
  }

  private updateTableValue() {
    this.gridOptions.api.setRowData(this.innerValue);
    if (this.overlayGridOptions.api) {
      this.overlayGridOptions.api.setRowData(this.innerValue);
    } else {
      this.overlayGridOptions.rowData = this.innerValue;
    }
    this.value = this.innerValue;
    if (Object.keys(this.value.includes('isNewRow'))) {
      delete this.value['isNewRow'];
    }
    this.propagateChange(this.value);
  }

  cancelRowEdit() {
    if (this.rowEdit) {
      this.rowEdit.finishPending();
    }
    this.editingRow = null;
    if (this._params.situation === 'SEARCH') {
      this.showDialog = false;
    }
  }

  onClose() {
    this.editingRow = null;
    this.showDialog = false;
  }

  updateRow(rowResult: any) {
    const isNewRow = rowResult.index === -1;
    if (isNewRow) {
      this.innerValue.push({...rowResult.rowData, ...{isNewRow: true}});
    } else {
      this._elements.forEach(el => {
        // this.innerValue[rowResult.index] = {...this.innerValue[rowResult.index], ...rowResult.rowData};
        this.innerValue[rowResult.index][el.name] = rowResult.rowData[el.name];
        if (this.innerValue[rowResult.index][el.name + '_meta']) {
          this.innerValue[rowResult.index][el.name + '_meta'] = rowResult.rowData[el.name + '_meta'];
        }
      });
    }
    this.updateTableValue();
    if (this._params.situation === 'SEARCH') {
      this.showDialog = false;
    } else if (isNewRow && !rowResult.createNewRow) {
      this.setEditRow(this.innerValue.length - 1, this.innerValue[this.innerValue.length - 1]);
    } else if (rowResult.createNewRow) {
      this.addRow();
      let rowNode = this.overlayGridOptions.api.getRowNode('' + (this.innerValue.length - 1));
      this.overlayGridOptions.api.ensureNodeVisible(rowNode);
    } else {
      this.setEditRow(rowResult.index, this.innerValue[rowResult.index]);
    }
  }

  copyRow(rowResult: any) {
    rowResult.index = -1;
    this.innerValue.push({...rowResult.rowData, ...{isNewRow: true}});
    this.updateTableValue();
    if (rowResult.createNewRow) {
      this.addRow();
    } else {
      this.setEditRow(this.innerValue.length - 1, JSON.parse(JSON.stringify(rowResult.rowData)));
    }
  }

  deleteRow(index: number) {
    if (index > -1) {
      this.innerValue.splice(index, 1);
      this.updateTableValue();
      if (this.innerValue.length) {
        const nextIndex = index == this.innerValue.length ? this.innerValue.length - 1 : index;
        this.setEditRow(nextIndex, this.innerValue[nextIndex]);
      } else {
        this.cancelRowEdit();
      }
    }
  }

  clearRow(index: number) {
    this.innerValue[index] = {};
    this.updateTableValue();
  }

  exportCSV() {
    this.gridOptions.api.exportDataAsCsv({
      ...this.gridApi.csvExportParams,
      fileName: this._params.element.label
    });
  }

  sizeToFit(overlay?: boolean) {
    if (overlay) {
      this.overlayGridOptions.api.sizeColumnsToFit();
    } else {
      this.gridOptions.api.sizeColumnsToFit();
    }
  }

  openDialog() {
    // TODO: Still necessary? this.overlayGridOptions.api.refreshView();
    this.showDialog = true;
  }

  private validateTableData(): boolean {
    // todo: implement
    return true;
  }

  validate(c: UntypedFormControl) {
    return this.validateTableData() ? null : {
      table: {
        valid: false
      }
    };
  }

  onMouseDown($event: any) {
    if (this.overlayGridOptions.suppressRowClickSelection) {
      if (!this.pendingChanges.checkForPendingTasks(this.rowEdit.pendingTaskId)) {
        this.overlayGridOptions.suppressRowClickSelection = false;
        this.rowEdit.finishPending();
        let rowIndex = this.gridApi.getRowIndex($event.target, 'ag-body');
        if (rowIndex !== null) {
          this.overlayGridOptions.api.getRowNode('' + rowIndex).setSelected(true, true);
          $event.target.click();
        }
      } else {
        $event.preventDefault();
        $event.stopImmediatePropagation();
      }
    }
  }

  onSortChanged() {
    let rowNode = this.overlayGridOptions.api.getRowNode('' + this.editingRow.index);
    this.overlayGridOptions.api.ensureNodeVisible(rowNode);
  }

  onCellClicked($event) {
    if ($event.rowIndex !== null) {
      if (!$event.node.group && $event.data) {
        if ($event.colDef.cellClass === 'router-link-cell') {
          this.gridApi.openRouterLink($event.event, 'ag-row');
        }
      }
    }
  }

  onColumnResized(column: any, overlay?: boolean) {
    let columnsDefinition = this.cacheLayoutKey + '.colDef';
    let cachedColumns = this._cachedColumns;
    if (overlay) {
      columnsDefinition = this.cacheLayoutKey + '.colDefOverlay';
      cachedColumns = this._cachedColumnsOverlay;
    }
    let col = cachedColumns.find(c => c.qname === column.colDef.refData.qname);
    if (col) {
      col.width = column.actualWidth;
    } else {
      cachedColumns.push({qname: column.colDef.refData.qname, width: column.actualWidth});
    }
    this.storageService.setItem(columnsDefinition, cachedColumns);
  }

  // private _updateSplitViewLayoutCache() {
  //   setTimeout(() => {
  //     this.splitView?.updateLayoutCache();
  //   });
  // }

  togglePreview() {
    this.showPreview = !this.showPreview;
    // make sure that the layout of the split view is updated after the preview panels
    // visibility changed
    // this._updateSplitViewLayoutCache();
    this.storageService.setItem(this.cacheLayoutKey + '.showPreview', this.showPreview);
  }

  // ngAfterViewInit(): void {
  //   this._updateSplitViewLayoutCache();
  // }

  ngOnInit() {
    this.hasPreviewFile = this.hasPreviewFile !== undefined ? this.hasPreviewFile : !!this.params.object?.content;
  }
}
