import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {
  BpmService,
  Capabilities,
  CapabilitiesService,
  ClipboardService,
  Config,
  EnaioEvent,
  EoUser,
  EventService,
  InboxService, InboxState,
  PrepareService, TranslateService,
  UserService
} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {UserAvatarComponent} from '../../ui/user-avatar/user-avatar.component';
@UntilDestroy()
@Component({
  selector: 'eo-app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: ['./app-bar.component.scss']
})
export class AppBarComponent implements OnInit {

  @Input() transparent = false;

  preparedItemsCount: number;
  inboxState: InboxState;
  user: EoUser;
  capabilities: Capabilities;
  exeActions: boolean;
  sidebarShow = false;
  options: any;
  iconTitles: any;
  logo: string;
  pageVisible = true;
  docu: {link: string, label: string};
  @ViewChildren(UserAvatarComponent) avatarComponents: QueryList<UserAvatarComponent>;

  constructor(private userService: UserService,
              private capabilityService: CapabilitiesService,
              private translate: TranslateService,
              private inboxService: InboxService,
              private prepareService: PrepareService,
              private clipboard: ClipboardService,
              private renderer: Renderer2,
              private router: Router,
              private bpmService: BpmService,
              private eventService: EventService,
              private config: Config,
              private elemRef: ElementRef,
              ) {
    this.capabilities = this.capabilityService.getCapabilities();
    this.logo = this.config.getNavigationBarImage();
    // listen for routing events to hide any active overlays
    // on changing route
    this.router
      .events.pipe(
      untilDestroyed(this))
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this.sidebarShow = false;
        }
      });

    this.userService
      .user$.pipe(
      untilDestroyed(this))
      .subscribe(user => this.user = user);

    if (this.capabilities.inbox) {
      this.inboxService
        .inboxState$.pipe(
        untilDestroyed(this))
        .subscribe((inboxState: InboxState) => {
          if (this.inboxState && this.inboxState.unreadmessages < inboxState.unreadmessages) {
            this.sendNotification();
          }
          this.inboxState = inboxState;
        });
      this.eventService.on(EnaioEvent.SYSTEM_STATUS_INBOX_CHANGED).subscribe(() => this.inboxService.refreshInboxState())
    }

    if (this.capabilities.intray) {
      this.prepareService
        .preparedItemCount$.pipe(
        untilDestroyed(this))
        .subscribe(count => {
          this.preparedItemsCount = count;
        });
    }

    this.translate
      .onLangChange
      .subscribe(() => this.setIconTitles());
  }

  openDashboard(openInNewWindow: boolean) {
    if (openInNewWindow) {
      window.open('/');
    } else {
      this.router.navigate(['/']);
    }
  }

  getDocumentation() {
    this.userService
      .user$
      .subscribe(data => {
        const docu = this.config.getRaw('about.docu');
        const link = docu.link.replace('###userLang###', data.userSettings.clientlocale.substring(0, 2));
        this.docu = {...docu, link};
      })
  }

  updateOnSchemaChange() {
    this.eventService
      .on(EnaioEvent.SCHEMA_LOCALE_CHANGED).pipe(
      untilDestroyed(this))
      .subscribe(() => {
        this.bpmService
          .getExecutableProcesses(null, null, null, null, true)
          .subscribe(res => {
            // this.exeActions = !!res.length;
            this.exeActions = this.bpmService.hasMainExecutableProcesses;
          });
      });
  }

  onToggleProfileOverlay(){
   setTimeout(() => {
    this.elemRef.nativeElement.querySelector('#settings').focus();
   });
  }

  setIconTitles() {
    this.iconTitles = {
      search: this.translate.instant('eo.bar.button.search.tooltip'),
      file: this.translate.instant('eo.bar.button.file.tooltip'),
      exeactions: this.translate.instant('eo.bar.button.execute.actions.tooltip'),
      favorites: this.translate.instant('eo.bar.button.favorites.tooltip'),
      prepare: this.translate.instant('eo.bar.button.prepare.tooltip'),
      index: this.translate.instant('eo.bar.button.inbox.tooltip'),
      profile: this.translate.instant('eo.bar.button.user.profile.title'),
      about: this.translate.instant('eo.sidebar.help.manual.tooltip')
    };
  }


  private sendNotification() {
    if (Notification.permission === 'granted') {
      this.initNotification();
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(() => {
        this.initNotification();
      });
    }
  }

  private initNotification() {
    const title = this.translate.instant('eo.inbox.notification.title');
    const body = this.translate.instant('eo.inbox.notification.body');
    const icon = 'assets/_default/img/yuuvis-agent.png';

    const notification = new Notification(title, {
      body: body,
      icon: icon
    });

    this.initNotificationClickListener(notification);
  }

  // This is listening for the navigation click event. When the client tab is active, it just navigates to the inbox route.
  // If not, it opens a new tab and shows the inbox there.
  private initNotificationClickListener(notification: Notification) {
    notification.addEventListener('click', () => {
      if (this.pageVisible) {
        this.router.navigateByUrl('/inbox');
      } else {
        const fullPath = window.location.href;
        const currentRoute = this.router.url;
        const pathToNavigate = fullPath.replace(currentRoute, '') + '/inbox';
        window.open(pathToNavigate, '_blank');
      }
    });
  }

  // This listener is needed to check, if the client tab is active or not
  private initVisibilityChangeListener() {
    document.addEventListener('visibilitychange', () => this.pageVisible = !this.pageVisible);
  }

  ngOnInit() {
    this.updateOnSchemaChange();
    this.setIconTitles();
    this.getDocumentation();
    this.exeActions = this.bpmService.hasMainExecutableProcesses;
    // this.bpmService
    //   .getExecutableProcesses(null, true)
    //   .subscribe(res => {
    //     this.exeActions = !!res.length;
    //   });
    this.initVisibilityChangeListener();
  }
}
