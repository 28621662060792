import {TreeNode} from '../../../../eo-framework/tree/tree.component.interface';
import {Component, OnInit, ViewChild, HostListener, Input} from '@angular/core';
import {Router, NavigationExtras} from '@angular/router';
import {PageTitleService} from '../../../../eo-framework-core/title/page-title.service';
import {QuickSearchComponent} from '../../../../eo-framework/quick-search/quick-search/quick-search.component';
import {QuickSearchResult} from '../../../../eo-framework/quick-search/quick-search/quick-search-result.interface';
import {UserService, Capabilities, StoredQuery, EoUser, Config, ObjectType, CapabilitiesService, StoredQueriesService, Utils} from '@eo-sdk/core';
import {IWidgetComponent} from '@yuuvis/widget-grid';
import {listAnimation} from '../../../../eo-framework/animations/animations';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'eo-search-widget',
  templateUrl: './search-widget.component.html',
  styleUrls: ['./search-widget.component.scss'],
  animations: [
    listAnimation
  ]
})
export class SearchWidgetComponent implements OnInit, IWidgetComponent {

  capabilities: Capabilities;
  storedQueries: StoredQuery[] = [];
  selectedQuery: StoredQuery;
  quickSearchResult: QuickSearchResult;
  selectedQuickSearchResultItem = {
    index: -1,
    name: null
  };
  tree = {};
  user: EoUser;
  backgroundImage: string;

  @ViewChild('quickSearch') quickSearchEl: QuickSearchComponent;
  @Input()
  widgetConfig: any;

  @HostListener('window:keyup', ['$event'])
  onKey(event) {
    if (event.which === 27) {
      this.selectStoredQuery(null);
    }
  }

  constructor(public userService: UserService,
    private titleService: PageTitleService,
    private router: Router,
    private config: Config,
    private capabilityService: CapabilitiesService,
    private storedQueriesService: StoredQueriesService) {
    this.titleService.setDefaultTitle();
    this.backgroundImage = `url(${this.config.getDashboardBackgroundImage()})`;
  }
  selectStoredQuery(q: StoredQuery) {
    this.selectedQuery = q;
  }

  // user changed input of quickSearch component
  onQuickSearchInputChange(term) {
    this.quickSearchResult = null;
  }

  // quickSearch component is returning results
  onQuickSearchResult(result: QuickSearchResult) {
    this.quickSearchResult = result;
    Object.keys(this.quickSearchResult.groups).map(key => (this.tree[key] = this.getTreeNodes(key)));
  }

  onStoredQueryLoaded() {
    this.selectStoredQuery(null);
    this.router.navigate([{outlets: {modal: 'search'}}]);
  }

  onStoredQueryExecute(evt) {
    this.selectStoredQuery(null);
    const uriParam = encodeURIComponent(JSON.stringify(evt.queryJson));
    const uriParamQuery: NavigationExtras = {queryParams: {'query': uriParam}};
    this.router.navigate(['/result'], uriParamQuery);
  }

  executeSearch(objectType?: ObjectType) {
    this.quickSearchEl.executeSearch(objectType);
  }

  getTreeNodes(key): TreeNode[] {
    return this.quickSearchResult.groups[key].map(group => ({
      id: group.data.id,
      name: group.label,
      selectable: true,
      data: group.data,
      badges: [{value: group.count}]
    }));
  }

  trackByFn(item) {
    return item.id;
  }

  ngOnInit() {
    this.userService.user$
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.user = user;
      });
    this.capabilities = this.capabilityService.getCapabilities();
    if (this.capabilities.storedqueries) {
      this.storedQueriesService.getStoredQueries$()
        .pipe(untilDestroyed(this))
        .subscribe(queriesSubscription => this.storedQueries = queriesSubscription.queries.filter(q => q.favorite).sort(Utils.sortValues('name')));
    }
  }
}


