<div class="db-content" [ngClass]="{centerFocus: (quickSearchResult && quickSearchResult.total > 0) || selectedQuery}">
  <div class="db-center">
    <div class="top">
      <div class="quick-search" [ngClass]="{disabled: selectedQuery}">

        <div class="count" *ngIf="quickSearchResult && quickSearchResult.total !== -1" (click)="executeSearch()">
          <div *ngIf="quickSearchResult.total">
            <span class="count-total">{{quickSearchResult.total | localeDecimal}}</span>
            <span class="label" translate>eo.quicksearch.hits</span>
          </div>
          <span class="label" *ngIf="!quickSearchResult.total" translate>eo.quicksearch.reults.empty</span>
        </div>

        <div class="input">
          <eo-quick-search (onResult)="onQuickSearchResult($event)" (onInputChange)="onQuickSearchInputChange($event)"
            #quickSearch></eo-quick-search>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="quick-search-results" *ngIf="quickSearchResult && quickSearchResult.total > 0; else storedqueries">

        <div class="group" *ngFor="let key of quickSearchResult.groups | keys; index as i">
          <h2 *ngIf="key !== '0'; else maingroup">{{key}}</h2>
          <ng-template #maingroup>
            <h2 translate>eo.quicksearch.result.group.global</h2>
          </ng-template>

          <eo-tree [tree]="tree[key]" [(ngModel)]="selectedQuickSearchResultItem"
            (onSelected)="executeSearch($event.data)" (expandedNodesChanged)="executeSearch($event.data)"
            [selectionKey]="'Enter'">
          </eo-tree>
        </div>
      </div>

      <ng-template #storedqueries>

        <div class="stored-queries" *ngIf="capabilities.storedqueries && storedQueries.length">

          <div class="query" *ngIf="selectedQuery; else querylist">
            <div>
              <eo-icon class="button" [iconSrc]="'assets/_default/svg/ic_clear.svg'"
                (click)="selectStoredQuery(null)"></eo-icon>
            </div>
            <eo-stored-query [query]="selectedQuery" (onQueryLoaded)="onStoredQueryLoaded()"
              (onQueryExecute)="onStoredQueryExecute($event)"></eo-stored-query>
          </div>

          <ng-template #querylist>
            <div class="queries" [@listAnimation]="storedQueries.length">
              <a class="sq lead" [routerLink]="['/stored-queries']" translate>eo.view.navigation.storedqueries</a>

              <div class="sq" *ngFor="let q of storedQueries; trackBy: trackByFn" (click)="selectStoredQuery(q)"
                (keyup.enter)="selectStoredQuery(q)" tabindex="0">
                <eo-icon [iconSrc]="'assets/_default/svg/ic_stored_query.svg'"></eo-icon>
                <span>{{q.name}}</span>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </div>
  </div>
</div>
