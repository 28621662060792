<yvc-split-view [layoutSettingsID]="'notification.state'">
  <ng-template yvcSplitArea [size]="25"><eo-list-container #eoList [applySelection]="{}"
      [parseDmsParams]="parseDmsParams">
      <div class="eo-header">
        <eo-icon class="eo-header-icon" [iconSrc]="'assets/_default/svg/ic_inbox.svg'"></eo-icon>
        <div class="eo-header-info">
          <div class="eo-header-title" translate>eo.notifications.list.title</div>
          <div class="header-sub-title" translate>eo.notifications.list.subtitle</div>
        </div>
        <div class="eo-header-actions">
          <eo-icon class="button refresh-button" [iconSrc]="'assets/_default/svg/ic_refresh.svg'"
            (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"
            [iconTitle]="('eo.list.refresh' | translate)"></eo-icon>

          <eo-overlay #oFilter [iconSrc]="'assets/_default/svg/ic_filter.svg'"
            class="overlay-filter" [iconTitle]="'eo.list.filter' | translate">
            <eo-custom-filter #cFilter (change)="cFilter.updateGrid(eoList.eoGrid, $event)"
              (active)="oFilter.onActiveChanged($event)">
              <eo-text-filter [title]="'eo.notifications.filter.title' | translate" [matchFields]="['info']"
                [filterParams]="textFilterParams" [id]="'notifications.text'"></eo-text-filter>

              <eo-set-filter [operator]="'OR'" [title]="'eo.notifications.filter.section.title' | translate"
                [options]="objectTypeFilterFields" [id]="'notifications.object.type'"></eo-set-filter>

              <eo-set-filter [operator]="'OR'" [title]="'eo.notifications.filter.section.objecttype' | translate"
                [options]="typeFilterFields" [id]="'notifications.type'"></eo-set-filter>
            </eo-custom-filter>
          </eo-overlay>

          <eo-overlay #oSort [iconSrc]="'assets/_default/svg/ic_sort.svg'" class="overlay-sort"
            [iconTitle]="'eo.list.sort' | translate">
            <eo-custom-sort #cSort [id]="'notifications.sort'" (change)="cSort.updateGrid(eoList.eoGrid, $event)"
              (active)="oSort.onActiveChanged($event)" [sortFields]="sortFields"></eo-custom-sort>
          </eo-overlay>

          <eo-icon class="button actions-button" (click)="eoList.showActions()"
            [iconTitle]="'eo.object.actions.title' | translate" [iconSrc]="'assets/_default/svg/ic_more.svg'"></eo-icon>
        </div>
      </div>

      <div class="eo-body">
        <eo-grid #eoGrid [gridOptions]="gridOptions" *ngIf="gridOptions" [fullWidth]="true" [showHeader]="true"
          [showFooter]="true" (eoGridCountChanged)="eoList.onCountChanged($event)"
          (eoGridContextMenuClick)="eoList.onContextMenu($event)"
          (eoGridSelectionChanged)="eoList.onSelectionChanged($event)"
          (eoGridDoubleClick)="eoList.onDoubleClick($event)" (eoGridFocusChanged)="eoList.onFocusChanged($event)">

          <div class="header">
          </div>

          <div class="footer">
            <eo-total-count [gridCount]="eoList.gridCount" [outsideGrid]="false" class="flex-row"></eo-total-count>
          </div>

          <div class="empty">
            <eo-error-message
              [emptyState]="{icon: 'ic_inbox.svg', text: oFilter.active ? 'eo.no.filter.result' : 'eo.notifications.list.empty'}"></eo-error-message>
            <eo-reset-filter [isFilterActive]="oFilter.active"
              (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"></eo-reset-filter>
          </div>

        </eo-grid>
      </div>
    </eo-list-container>
  </ng-template>

  <ng-template yvcSplitArea [size]="75">
    <eo-object-details [applySelection]="{}" [parseDmsParams]="parseDmsParams"
      [cacheLayout]="'notification.state.object-details'" [emptyState]="emptyState">
      <eo-tab-plugin [type]="'object-details-tab.notifications'"></eo-tab-plugin>
      <div class="error" *ngIf="gridData?.length">
        <button class="button secondary" (click)="remove()" translate>eo.notifications.details.item.gone.remove</button>
        <button class="button secondary" (click)="removeSubscriptionAndResubmissions()"
          translate>eo.notifications.details.item.gone.remove.subscriptions</button>
      </div>
    </eo-object-details>
  </ng-template>

</yvc-split-view>
